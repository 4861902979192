import React, { ReactNode } from 'react';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';

type AMITableProps = {
  children: ReactNode;
  height?: number | string;
  sticky?: boolean;
  size?: 'small' | 'medium';
};

const AMITable = ({
  children,
  height,
  sticky = true,
  size = 'medium',
}: AMITableProps) => {
  return (
    <TableContainer
      style={{
        minHeight: 400,
        height: height ? height : 'calc(100% - 52px)',
        maxHeight: height ? height : 'calc(100% - 52px)',
        overflow: 'auto',
      }}>
      <Table stickyHeader={sticky} size={size}>
        {children}
      </Table>
    </TableContainer>
  );
};

export default AMITable;
