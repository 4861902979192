import { Box } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import React from 'react';

export const NewsContainer = styled(Box)(({ theme }) => ({
    // [theme.breakpoints.down('md')]: {
    //     textAlign: 'center',
    // },
    // [theme.breakpoints.up('md')]: {
    //     textAlign: 'left',
    // },
    paddingLeft: '300px'
}));

export const NewsTabContainer = styled(Box)(({ theme }) => ({
    backgroundColor: '#F4F6F9',
    width: '100vw',
    boxSizing: 'border-box'
}));

export const NewsListContainer = styled(Box)(({ theme }) => ({
    overflowY: 'auto',
    height: 'calc(100vh - 196px)',
    width: '100%',
    padding: '8px 32px',
    boxSizing: 'border-box'
}));

export const NewsListContent = styled(Box)(({ theme }) => ({
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flex: 1,
    overflow: 'auto'
}));

export const NewsListRow = styled(Box)(({ theme }) => ({
    overflowY: 'auto',
    boxSizing: 'border-box',
    width: '100%',
    paddingTop: '15px',
    paddingBottom: '7px',
    borderBottom: '1px solid #E5E5E5',
    position: 'relative',
    '&::-webkit-scrollbar': {
        display: 'none'
    }
}));

export const NewsListLoading = styled(Box)(({ theme }) => ({
    height: 'calc(100vh - 196px)',
    width: '100%',
    padding: '8px 32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    fontSize: 24,
    fontWeight: 'bold',
    color: '#A2A2A2',
    backgroundColor: '#FFF'
}));

export const NewsListRowImageContainer = styled(Box)(({ theme }) => ({
    height: 48,
    width: 48,
    minWidth: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

export const ClampedBox = styled(Box)(({ theme }) => ({
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 1,
    paddingRight: 1,

    "& > span": {
        width: 120,
        fontSize: '15px',
        fontWeight: 400,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    }
}));


interface ClampedTextProps {
    text: string;
}
export const ClampedText = ({ text }: ClampedTextProps) => {
    return (
        <ClampedBox>
            <span>{text}</span>
        </ClampedBox>
    );
};