import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { styled } from '@material-ui/core/styles';
import { appColors } from './constants';

export const AppTabList = styled(Tabs)({
  backgroundColor: appColors.bluishWhite,
  paddingLeft: 32,

  '& .MuiTabs-flexContainer': {
    gap: 16,
  },

  '& .MuiTabs-indicator': {
    height: 5,
    backgroundColor: appColors.primary,
  },

  '&.tab-thin .MuiTabs-indicator': {
    height: 4,
  },
});

export const AppTab = styled(Tab)({
  textTransform: 'capitalize',
  fontSize: 16,
  color: appColors.mediumGray,
  width: 'max-content',
  minWidth: 'unset',
  padding: '8px 0',
  marginRight: 32,

  '&.Mui-selected': {
    color: appColors.dark,
    fontWeight: 600,
  },

  '&.tab-thin': {
    fontSize: 14,

    '&.Mui-selected': {
      fontWeight: 500,
    },
  },
});
