import * as React from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { Vendor } from '../../../../store/usermanagement/types';
import { setUserManagementState } from '../../../../store/usermanagement/actions';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../store';

interface ActionProps {
    setVendorStatusUpdate: (status: string, vendorId: string, type: string, vendors?: Array<Partial<Vendor>>) => void;
}

export default function VendorActionDropdown(props: ActionProps) {
    const { setVendorStatusUpdate } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleStatus = (status: string) => {
        setVendorStatusUpdate(status, '', 'multiple')
        handleClose()
    }

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                size="large"
                style={{ background: '#B57936', color: '#fff', marginLeft: '5px', textTransform: 'capitalize' }}
                onClick={handleClick}
            >
                Actions
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => handleStatus("Approved")}>Approve</MenuItem>
                <MenuItem onClick={() => handleStatus("Rejected")}>Reject</MenuItem>
            </Menu>
        </div>
    );
}
