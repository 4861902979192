import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { appColors } from '../../lib/constants';
import { monthOptions, yearOptions } from './util/chart-utils';

interface ReportFilterSelectProps {
    label: string;
    value: string;
    onChange: (newValue: string) => void;
    type?: 'month' | 'year';
    currentStart?: string;
    currentEnd?: string;
}

const ReportFilterSelect = (props: ReportFilterSelectProps) => {
    const { label, value, type, onChange, currentStart, currentEnd } = props;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const startIndex = currentStart ? monthOptions.indexOf(currentStart) : 0;
    const endIndex = currentEnd
        ? monthOptions.indexOf(currentEnd) + 1
        : monthOptions.length;

    const truncatedMonthOptions = monthOptions.slice(startIndex, endIndex);

    const options = type === 'month' ? truncatedMonthOptions : yearOptions;

    return (
        <>
            <Box display="flex" alignItems="center" mt={2}>
                <Typography
                    id="report-filter-label"
                    style={{
                        fontSize: 13,
                        color: appColors.primary,
                    }}>
                    {label}
                </Typography>
                <Button
                    aria-labelledby="report-filter-label"
                    aria-controls="report-filter-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    endIcon={
                        Boolean(anchorEl) ? (
                            <ExpandLessIcon />
                        ) : (
                            <ExpandMoreIcon />
                        )
                    }
                    style={{
                        textTransform: 'capitalize',
                        fontSize: 14,
                        fontWeight: 600,
                        color: appColors.primary,
                        marginLeft: 6,
                    }}>
                    {value}
                </Button>
            </Box>
            <Menu
                id="report-filter-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                onClick={handleClose}>
                {options.map((option) => (
                    <MenuItem
                        key={option}
                        style={{
                            minWidth: 120,
                            color:
                                option === value
                                    ? appColors.primary
                                    : 'inherit',
                        }}
                        onClick={() => onChange(option)}>
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default ReportFilterSelect;
