import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { SvgIconComponent } from '@material-ui/icons';

import AMITableCell from './AMITableCell';
import AMITableSortLabel from './AMITableSortLabel';

import type { TableColumn, SortColumn } from './table-types';

interface AMITableHeadingProps<T> {
    columns: TableColumn<T>[];
    sortColumn: SortColumn;
    onSort: (sortColumn: SortColumn) => void;
    className?: string;
    SortIcon?: SvgIconComponent;
    rotateIcon?: boolean;
}

const AMITableHeading = <T,>({
    columns,
    sortColumn,
    onSort,
    className,
    SortIcon = ArrowDownwardIcon,
    rotateIcon = true,
}: AMITableHeadingProps<T>) => {
    // create column head cell key
    function createColumnKey(column: TableColumn<T>) {
        return `col-${column.id}`;
    }

    function handleSort(sortColumnProperty: string) {
        if (!sortColumnProperty) return;

        const isAsc =
            sortColumn.path === sortColumnProperty &&
            sortColumn.order === 'asc';
        const newSortColumn: SortColumn = {
            path: sortColumnProperty,
            order: isAsc ? 'desc' : 'asc',
        };

        onSort(newSortColumn);
    }

    return (
        <TableHead>
            <TableRow>
                {columns.map((column) => (
                    <React.Fragment key={createColumnKey(column)}>
                        {column.sortable ? (
                            <AMITableCell
                                className={className}
                                align={column.align ? column.align : 'center'}
                                sortDirection={
                                    sortColumn.path === column.property
                                        ? sortColumn.order
                                        : false
                                }
                                style={{
                                    width: column.width ? column.width : 'auto',
                                }}>
                                <AMITableSortLabel
                                    active={sortColumn.path === column.id}
                                    direction={
                                        !rotateIcon
                                            ? 'desc'
                                            : sortColumn.path === column.id
                                            ? sortColumn.order
                                            : 'asc'
                                    }
                                    onClick={(e) => handleSort(column.id)}
                                    className={className}
                                    IconComponent={SortIcon}>
                                    {column.label}
                                </AMITableSortLabel>
                            </AMITableCell>
                        ) : (
                            <AMITableCell
                                className={className}
                                width={column.width ? column.width : 'auto'}
                                align={column.align ? column.align : 'center'}>
                                {column.label}
                            </AMITableCell>
                        )}
                    </React.Fragment>
                ))}
            </TableRow>
        </TableHead>
    );
};

export default AMITableHeading;
