import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface SymphonyLayout {
    children: string | JSX.Element | JSX.Element[];
}

const useStyles = makeStyles({
    toast: { 
        borderRadius: 3,
        padding: '10px 15px'
    }
});
export default (props: SymphonyLayout) => {
    const classes = useStyles();

    return (
        <Box
            style={{ 
                height: 'calc(100vh - 120px)',
                display:"flex",
                boxSizing:"border-box",
                flexDirection:"column",
                overflow: 'hidden'
            }}
            width="100vw"
            minWidth="1265px"
            paddingLeft="300px"
        >  
            {props.children}
            <ToastContainer toastClassName={classes.toast} />
        </Box>
    )
}