import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Vendor } from '../../../../store/usermanagement/types';
import { MediaInputType, SortOrder } from '../../../../store/system/types';
import {
    UserManagementListContainer,
    UserManagementListRowsContainer,
    UserManagementListRowContainer,
    UserManagementListGrid,
    UserManagementListImageContainer,
    UserManagementButtonGrid,
    ApproveButton,
    DisapproveButton
} from './UserManagementComponents';

import {
    SymphonySortableHeaderGridContainer,
    SymphonySortableHeaderGrid,
    SymphonyContentLoadingContainer
} from '../../../symphony/SymphonyCommonComponents';
import SymphonySortableHeader from '../../../symphony/SymphonySortableHeader';
import SymphonyContentLoading from '../../../symphony/SymphonyContentLoading';

// material
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

// util
import map from 'lodash/map';

// default svg
import { ReactComponent as DefaultPic } from '../../../../assets/images/logos/logo.svg';
import SymphonyImage from '../../../symphony/SymphonyImage';
import { AppState } from '../../../../store';
import { connect, useDispatch, useSelector } from 'react-redux';
import { setUserManagementState } from '../../../../store/usermanagement/actions';

interface VendorCardListProps {
    vendors: Array<Vendor>;
    tab: String;
    setVendorStatusUpdate: (status: string, vendorId: string, type: string, vendors?: Array<Partial<Vendor>>) => void;
    activeSort: string;
    activeSortOrder: SortOrder;
    onSortClick: (sort: string, order: SortOrder) => void;
    loading: boolean;
    setUserManagementState: typeof setUserManagementState;
}

const VendorCardList = (props: VendorCardListProps) => {
    const dispatch = useDispatch();
    const { uncheckAll, selectedVendors, removeCheckedValue } = useSelector((state: AppState) => state.usermanagement);
    const { vendors, tab, activeSort, activeSortOrder, onSortClick, loading, setVendorStatusUpdate, setUserManagementState } = props;
    const [array, setArray] = React.useState<Array<Partial<{ id: string, companyName: string }>>>(selectedVendors!)

    React.useEffect(() => {
        // here sets the previous value
        // need to somehow fix that behavior
        // and only add array to selectedVendors
        // when
        if (!removeCheckedValue) {
            dispatch(setUserManagementState({ selectedVendors: array }));
        } else {
            setArray(selectedVendors!)
        }
    }, [array, dispatch, setUserManagementState, removeCheckedValue])


    const handleUncheckAll = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        // setSelected(e.target.checked)
        setUserManagementState({ uncheckAll: e.target.checked })
        if (e.target.checked) {
            map(vendors, (v) => {
                const chkbx = (document.getElementById(`selected-${v.id}`) as HTMLInputElement)
                if (chkbx) chkbx.checked = true
                return v
            })
            setArray([...vendors as Array<{ id: string, companyName: string }>])
        } else {
            map(vendors, (v) => {
                if (v) (document.getElementById(`selected-${v.id}`) as HTMLInputElement)!.checked = false
            })
            setArray([])
        }
    }, [array])

    const removeItem = (arr, index) => {
        if (index > -1) {
            arr.splice(index, 1);
        }
        return arr;
    }

    const handleVendorCheckboxChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, vendor: Vendor) => {
        const index = array.findIndex(x => x.id === vendor.id);
        if (e.target.checked) {
            // add vendor
            if (!(index > -1)) {
                setUserManagementState({ removeCheckedValue: false })
                setArray(a => [...a, vendor])
            }
        } else {
            // remove vendor
            setUserManagementState({ uncheckAll: e.target.checked })
            setArray(a => [...removeItem(a, index)])
        }
    }, [array])

    return (
        <UserManagementListContainer>
            <SymphonySortableHeaderGridContainer container={true}>
                {tab === "Pending" && <input type='checkbox'
                    checked={uncheckAll}
                    style={{ marginRight: '15px' }}
                    onChange={handleUncheckAll} />}
                <SymphonySortableHeaderGrid item={true} xs={tab !== 'Pending' ? 3 : 3}>
                    <SymphonySortableHeader
                        headerTitle="Name"
                        headerValue="name"
                        activeSort={activeSort}
                        activeSortOrder={activeSortOrder}
                        onSortClick={() => onSortClick('name', !activeSortOrder || activeSort !== 'name' ? 'ASC' : activeSortOrder === 'ASC' ? 'DESC' : '')}
                    />
                </SymphonySortableHeaderGrid>
                <SymphonySortableHeaderGrid item={true} xs={tab !== 'Pending' ? 3 : 3}>
                    <SymphonySortableHeader
                        headerTitle="Email"
                        headerValue="email"
                        activeSort={activeSort}
                        activeSortOrder={activeSortOrder}
                        onSortClick={() => onSortClick('email', !activeSortOrder || activeSort !== 'email' ? 'ASC' : activeSortOrder === 'ASC' ? 'DESC' : '')}
                    />
                </SymphonySortableHeaderGrid>
                <SymphonySortableHeaderGrid item={true} xs={tab !== 'Pending' ? 3 : 3}>
                    <SymphonySortableHeader
                        headerTitle="Mobile Number"
                        headerValue="mobileNumber"
                        activeSort={activeSort}
                        activeSortOrder={activeSortOrder}
                        onSortClick={() => onSortClick('mobileNumber', !activeSortOrder || activeSort !== 'mobileNumber' ? 'ASC' : activeSortOrder === 'ASC' ? 'DESC' : '')}
                    />
                </SymphonySortableHeaderGrid>
                {tab === 'Pending' ?
                    <SymphonySortableHeaderGrid item={true} xs={3} />
                    :
                    <SymphonySortableHeaderGrid item={true} xs={3}>
                        <SymphonySortableHeader
                            headerTitle="Updated By"
                            headerValue="dateUpdated"
                            activeSort={activeSort}
                            activeSortOrder={activeSortOrder}
                            onSortClick={() => onSortClick('dateUpdated', !activeSortOrder || activeSort !== 'dateUpdated' ? 'ASC' : activeSortOrder === 'ASC' ? 'DESC' : '')}
                        />
                    </SymphonySortableHeaderGrid>
                }

            </SymphonySortableHeaderGridContainer>
            {/* Cards here */}
            {loading ? <SymphonyContentLoading overrideHeight="calc(100vh - 314px)!important" /> :
                <>
                    {vendors.length === 0 &&
                        <SymphonyContentLoadingContainer height="calc(100vh - 314px)!important">
                            No Vendor Found
                        </SymphonyContentLoadingContainer>
                    }
                    <UserManagementListRowsContainer>
                        {map(vendors, (v) => {
                            const image = (v.avatar as unknown) as MediaInputType; // type cast
                            const imageUndefined = typeof image === 'undefined';
                            return (
                                <div style={{ display: 'flex', alignItems: 'center' }} key={v.id}>
                                    {tab === "Pending" && <input
                                        type='checkbox'
                                        id={`selected-${v.id}`}
                                        style={{ marginLeft: '12px' }}
                                        onChange={(e) => handleVendorCheckboxChange(e, v)} />}
                                    <Link
                                        id={`vendor-view-${v.id}`}
                                        key={v.id}
                                        to={`/market/vendor/${v.id}`}
                                        style={{ display: 'flex', textDecoration: 'none', color: 'unset', width: '100%' }}
                                    >
                                        <UserManagementListRowContainer>
                                            <Grid container={true} style={{ padding: '0 6px' }}>
                                                <UserManagementListGrid item={true} xs={tab !== 'Pending' ? 3 : 3}>
                                                    <Box display="flex" alignItems="center">
                                                        <UserManagementListImageContainer style={{ marginRight: 8 }}>
                                                            {!imageUndefined ?
                                                                <SymphonyImage
                                                                    src={typeof image !== 'undefined' ? image.path : ''}
                                                                    style={{ width: 48, height: 'auto', maxHeight: 48 }}
                                                                />
                                                                :
                                                                <DefaultPic style={{ width: 32, height: 32, marginLeft: 4, }} />
                                                            }
                                                        </UserManagementListImageContainer>
                                                        {v.companyName || 'Unknown Vendor Name'}
                                                    </Box>
                                                </UserManagementListGrid>
                                                <UserManagementListGrid item={true} xs={tab !== 'Pending' ? 3 : 3}>
                                                    <Box display="inline" color="#A2A2A2">{v.email}</Box>
                                                </UserManagementListGrid>
                                                <UserManagementListGrid item={true} xs={tab !== 'Pending' ? 3 : 3}>
                                                    <Box display="inline" color="#A2A2A2">{v.mobileNumber}</Box>
                                                </UserManagementListGrid>
                                                {tab !== 'Pending' &&
                                                    <UserManagementListGrid item={true} xs={3}>
                                                        <Box display="inline" color="#A2A2A2">{v.updatedBy}</Box>
                                                    </UserManagementListGrid>
                                                }
                                                {
                                                    tab === "Pending" &&
                                                    <>
                                                        <UserManagementButtonGrid item={true} xs={3} style={{ display: 'flex', alignItems: 'flex-end' }}>
                                                            <ApproveButton
                                                                style={{ width: 128, marginRight: 8 }}
                                                                id={`vendor-approve-button-${v.id}`}
                                                                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                    setVendorStatusUpdate("Approved", v.id, "single")
                                                                }}
                                                            >
                                                                Approve
                                                            </ApproveButton>
                                                            <DisapproveButton
                                                                style={{ width: 128 }}
                                                                id={`vendor-disapprove-button-${v.id}`}
                                                                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                    setVendorStatusUpdate("Rejected", v.id, "single")
                                                                }}
                                                            >
                                                                Reject
                                                            </DisapproveButton>
                                                        </UserManagementButtonGrid>
                                                    </>
                                                }
                                            </Grid>
                                        </UserManagementListRowContainer>
                                    </Link>
                                </div>
                            )
                        })}
                    </UserManagementListRowsContainer>
                </>
            }
        </UserManagementListContainer>
    )
}


const mapStateToProps = (state: AppState) => ({
    usermanagement: state.usermanagement,
    system: state.system
});

export default connect(mapStateToProps, {
    setUserManagementState,
})(VendorCardList);
