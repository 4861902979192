import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import { SystemState } from '../../store/system/types';
import { setSystemState } from '../../store/system/actions';
import { LoginState } from '../../store/login/types';
import { resetLoginState } from '../../store/login/actions';
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import Divider from '@material-ui/core/Divider';
import { styled } from '@material-ui/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Avatar, List, ListItem, ListItemIcon, ListItemProps, ListItemText, Typography } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { SYMPHONY_PRIMARY_COLOR } from './Colors';
import { useHistory } from 'react-router-dom';
import { setUserManagementState } from '../../store/usermanagement/actions';
import { ActiveUser } from '../../store/usermanagement/types';

interface SymphonyHeaderProps {
    setSystemState: typeof setSystemState;
    resetLoginState: typeof resetLoginState;
    setUserManagementState: typeof setUserManagementState;
    login: LoginState;
    system: SystemState;
}

const Menu = styled(Popover)(({ theme }) => ({
    '& .MuiPaper-root': {
        boxShadow: '0 3px 25px rgba(0,0,0,0.1)',
        marginTop: '8px',
    }
}));

const ListItemLabel = styled(ListItemText)(({ theme }) => ({
    '& .MuiTypography-root': {
        fontSize: '12px'
    }
}));

const Item = styled(ListItem)(({ theme }) => ({
    '&:hover': {
        color: SYMPHONY_PRIMARY_COLOR,
        background: '#fff',
        '& .MuiListItemIcon-root': {
            color: SYMPHONY_PRIMARY_COLOR
        }
    }
}));

const TruncateText = styled(Typography)(({ theme }) => ({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '170px',
}));

function ListItemLink(props: ListItemProps<'a', { button?: true }>) {
    return <ListItem button component="a" {...props} />;
}

class SymphonyHeader extends React.Component<SymphonyHeaderProps> {

    HeaderPopup = () => {
        const history = useHistory();
        const [anchorEl, setAnchorEl] = React.useState(null);
        const [statusColor, setStatusColor] = React.useState('#71DD37');
        const open = Boolean(anchorEl);
        const id = open ? 'header-popover' : undefined;
        const { user } = this.props.login;
        const defaultImage = "https://www.computerrepairatlantastore.com/Images/users/user-john-doe-437x437.jpg"

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const handleChangeRoute = () => {
            history.push('/user/account-settings')
            setAnchorEl(null);
        }

        const ProfileImage = ({ src }: { src: string }) => (
            <Box style={{ position: 'relative' }}>
                <Avatar alt="Profile Image" src={src}
                    style={{ width: 40, height: 40 }} />
                <Box style={{
                    backgroundColor: statusColor, border: '2px solid white', borderRadius: '50%',
                    width: 8, height: 8, position: 'absolute', bottom: 1, right: 0
                }}></Box>
            </Box>
        )

        return (
            <>
                <Box onClick={handleClick} style={{
                    cursor: 'pointer', borderRadius: '50%', width: 40, height: 40,
                    display: 'flex', justifyContent: 'center', alignItems: 'center'
                }}>
                    <ProfileImage src={user.image || defaultImage} />
                </Box>
                <Menu
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Box style={{ width: 270, display: 'flex', flexDirection: 'column' }}>
                        <Box style={{ display: 'flex', padding: '15px', }}>
                            <Box style={{ position: 'relative' }}>
                                <Avatar alt="John Doe" src={user.image || defaultImage}
                                    style={{ width: 55, height: 55 }} />
                                <Box style={{
                                    backgroundColor: statusColor, border: '2px solid white', borderRadius: '50%',
                                    width: 12, height: 12, position: 'absolute', bottom: 1, right: 0
                                }}></Box>
                            </Box>
                            <Box style={{ paddingLeft: 15 }}>
                                <TruncateText style={{ fontWeight: 600, fontSize: 14 }}>{`${user.firstName} ${user.lastName}`}</TruncateText>
                                <TruncateText style={{ color: '#959595', fontSize: 11 }}>{user.email}</TruncateText>
                                <TruncateText style={{ fontWeight: 500, fontSize: 11, textTransform: 'capitalize' }}>{user.hasOwnProperty('activity') ? user.activity.replace('-', ' ') : 'Active'}</TruncateText>
                            </Box>
                        </Box>
                        <Divider variant='middle' />
                        <List component="nav" style={{ padding: '0px', cursor: 'pointer' }}>
                            <Item button onClick={handleChangeRoute}>
                                <ListItemIcon style={{ minWidth: '25px' }}>
                                    <SettingsIcon fontSize='small' />
                                </ListItemIcon>
                                <ListItemLabel primary="Account Settings" />
                            </Item>
                        </List>
                        <Divider variant='middle' />
                        <List component="nav" style={{ paddingTop: '0px', cursor: 'pointer' }}>
                            <Item button onClick={() => this.props.resetLoginState()}>
                                <ListItemIcon style={{ minWidth: '25px' }}>
                                    <PowerSettingsNewIcon fontSize='small' />
                                </ListItemIcon>
                                <ListItemLabel primary="Sign out" />
                            </Item>
                        </List>
                    </Box>
                </Menu>
            </>
        )
    }

    render() {
        const { header, headerText, headerEndButton } = this.props.system;
        return (
            <Box style={{ backgroundColor: '#F4F6F9' }} display="flex" flexDirection="column" gridRowGap={22}
                paddingTop="10px" paddingLeft="332px" paddingRight="32px" position="relative" paddingBottom="15px"
                minWidth="900px">
                <Box display="flex" justifyContent="end" alignItems="center" >
                    {/* Auxilliary */}
                    <this.HeaderPopup />
                </Box>
                <Box display="flex" flex="1" justifyContent="space-between" alignItems="flex-end" width="100%">
                    <Box fontSize="36px" width="100%" fontWeight="bold">{header ? header : headerText?.main}</Box>
                    <Box>{headerEndButton}</Box>
                </Box>
            </Box>
        )
    }
}

const mapStateToProps = (state: AppState) => ({
    login: state.login,
    system: state.system
});

export default connect(mapStateToProps, {
    setSystemState,
    resetLoginState,
    setUserManagementState
})(SymphonyHeader);