import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router';
import { useDispatch } from 'react-redux';

import {
  createCustomerLead,
  deleteCustomerLead,
  getCustomerLeadById,
  getCustomerLeads,
  getLeadsModuleFields,
  updateCustomerLead,
} from './leads-service';

import { toastError, toastSuccess } from '../../../modules/Toast';
import { setSystemState } from '../../../store/system/actions';
import { Field } from '../../../store/fields/types';

interface ApiError {
  status: number;
  message: string;
}

interface MutationArgs {
  fields: Field[];
  customerLead: any;
}

export function useGetLeadsQuery() {
  const { search } = useLocation();

  const isActive =
    new URLSearchParams(search).get('active') === 'false' ? false : true;

  return useQuery(`leads-${isActive.toString()}`, () =>
    getCustomerLeads({ isActive }),
  );
}

export const useGetLeadsById = ({ enabled }: { enabled: boolean }) => {
  const { id } = useParams<{ id: string }>();
  return useQuery(`leads-${id}`, () => getCustomerLeadById(id), { enabled });
};

export function useLeadsModuleFields() {
  return useQuery('leads-module-fields', () => getLeadsModuleFields());
}

export function useCreateLead() {
  const dispatch = useDispatch();

  const navigate = useHistory();

  const queryClient = useQueryClient();

  return useMutation(
    ({ fields, customerLead }: MutationArgs) =>
      createCustomerLead(fields, customerLead),
    {
      onMutate(variables) {
        dispatch(
          setSystemState({
            systemDialogOpen: false,
          }),
        );
      },
      async onSuccess(data, variables, context) {
        await queryClient.invalidateQueries();
        navigate.replace('/sales/leads');
        toastSuccess('Leads successfully saved!');
      },
      onError(err, variables, context) {
        const errorData = (err as AxiosError<{ error: ApiError }>).response
          ?.data.error;

        console.log(errorData);

        if (err && errorData) {
          console.log(errorData.message);
          toastError(errorData.message.split(': ').slice(1).join(' '));
        }
      },
    },
  );
}

export function useUpdateLead() {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const navigate = useHistory();

  const queryClient = useQueryClient();

  return useMutation(
    ({ fields, customerLead }: MutationArgs) =>
      updateCustomerLead(fields, id, customerLead),
    {
      onMutate(variables) {
        dispatch(
          setSystemState({
            systemDialogOpen: false,
          }),
        );
      },
      async onSuccess(data, variables, context) {
        await queryClient.invalidateQueries();
        navigate.replace('/sales/leads');
        toastSuccess('Leads successfully updated!');
      },
      onError(err, variables, context) {
        const errorData = (err as AxiosError<{ error: ApiError }>).response
          ?.data.error;

        if (err && errorData) {
          console.log(errorData);
          const errMsg = errorData.message.split(': ').slice(1).join(' ');
          toastError(errMsg);
        }
      },
    },
  );
}

export const useDeleteLead = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const navigate = useHistory();

  const queryClient = useQueryClient();

  return useMutation(() => deleteCustomerLead(id), {
    onMutate(variables) {
      dispatch(
        setSystemState({
          systemDialogOpen: false,
        }),
      );
    },
    async onSuccess(data, variables, context) {
      await queryClient.invalidateQueries();
      navigate.replace('/sales/leads');
      toastSuccess('Leads successfully deleted');
    },
    onError(err, variables, context) {
      const errorData = (err as AxiosError<{ error: ApiError }>).response?.data
        .error;

      if (err && errorData) {
        toastError(errorData.message.split(': ').slice(1).join(' '));
      }
    },
  });
};
