import TableSortLabel from '@material-ui/core/TableSortLabel';
import { styled } from '@material-ui/core/styles';
import { appColors } from '../constants';

const AMITableSortLabel = styled(TableSortLabel)(({ theme }) => ({
    '&.MuiTableSortLabel-root': {
        marginLeft: 20,
        color: appColors.dark,
    },
}));

export default AMITableSortLabel;
