import React from "react";
import RemoveIcon from '@material-ui/icons/Remove';
import { OrderManagementFilter } from "../../../../store/ordermanagement/types";
import { SymphonyFilterPriceRangeContainer, SymphonyInputGridContainer, SymphonyInputLabelGridContainer, SymphonyTextField } from "../../../symphony/SymphonyCommonComponents";

interface IRange {
    filters: Partial<OrderManagementFilter>;
    onFilterInput: (key: string, value: string | Array<string> | number | Array<number>) => void;
}

const PriceRange = ({ filters, onFilterInput }: IRange) => {
    const { minPrice, maxPrice } = filters;

    return (
        <SymphonyInputGridContainer container={true} style={{ marginBottom: 20 }}>
            <SymphonyInputLabelGridContainer className="symphony-input-label" item={true} xs={12}>
                Price Range
            </SymphonyInputLabelGridContainer>
            <SymphonyFilterPriceRangeContainer>
                <SymphonyTextField
                    id='min-price'
                    key='min-price'
                    type="number"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onFilterInput('minPrice', e.target.value ? Number(e.target.value) : '')}
                    fullWidth={true}
                    placeholder='Minimum Price'
                    value={minPrice}
                    autoComplete="none"
                />
                <RemoveIcon htmlColor="#A1A1A1" />
                <SymphonyTextField
                    id='max-price'
                    key='max-price'
                    type="number"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onFilterInput('maxPrice', e.target.value ? Number(e.target.value) : '')}
                    fullWidth
                    placeholder='Maximum Price'
                    value={maxPrice}
                    autoComplete="none"
                />
            </SymphonyFilterPriceRangeContainer>
        </SymphonyInputGridContainer>
    )
}

export default PriceRange;