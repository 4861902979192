import React from 'react';

import Typography from '@material-ui/core/Typography';

import { formatDate } from '../../../lib/helpers';
import { TableColumn } from '../../../lib/table/table-types';
import ReturnRefundActions from './ReturnRefundActions';
import { ReturnRefundItem } from './types';
import { Link } from 'react-router-dom';
import { extractStatusText } from './constants';

export const returnColumns: TableColumn<ReturnRefundItem>[] = [
  {
    id: 'orderNumber',
    label: 'Order Number',
    property: 'id',
    sortable: true,
    content: (item) => (
      <Link
        to={`/market/approval/return-and-refund/${item.id}?type=return`}
        style={{ textDecoration: 'none', fontWeight: 'bold', color: '#222' }}>
        #{item.id}
      </Link>
    ),
  },
  {
    id: 'paymentType',
    label: 'Type of Payment',
    property: 'paymentMethod',
    sortable: true,
  },
  {
    id: 'requestDate',
    label: 'Request Date',
    property: 'requestDate',
    sortable: true,
    content: (item) => formatDate(item.requestDate),
  },
  {
    id: 'status',
    label: 'Status',
    property: 'status',
    sortable: true,
    content: (item) => (
      <Typography style={{ textTransform: 'capitalize' }}>
        {extractStatusText(item.status)}
      </Typography>
    ),
  },
  {
    id: 'solution',
    label: 'Solution',
    property: 'solution',
    sortable: true,
  },
  {
    id: 'return-actions',
    label: 'Actions',
    property: 'id',
    content: (row) => (
      <ReturnRefundActions id={row.id} currentStatus={row.status} isReturn />
    ),
  },
];
