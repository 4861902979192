import * as XLSX from 'xlsx';
import moment from 'moment';
import { capitalize } from 'lodash';

import { Order } from '../../../../store/ordermanagement/types';

const orderPropertyMap = {
  'Order Number': 'orderNumber',
  'Order Date': 'orderDate',
  Payment: 'paymentMethod',
  Price: 'price',
  'Last Updated': 'lastUpdated',
  'Order Status': 'status',
};

export const exportToExcel = (
  orders: Order[],
  filename: string,
  workSheetName: string,
  extension: 'xlsx' | 'csv' = 'xlsx',
) => {
  const ordersData = [...orders].map((o) => ({
    orderNumber: `#${o.id}`,
    orderDate: formatDate(o.dateCreated),
    paymentMethod: o.paymentMethod,
    price: o.currencySign + formatCurrency(o.totalAmount),
    lastUpdated: formatDate(o.dateUpdated),
    status: capitalize(o.status.split('_').join(' ')),
  }));

  const headings = [Object.keys(orderPropertyMap)];
  const columnHeaders = [...Object.values(orderPropertyMap)];

  const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

  XLSX.utils.sheet_add_aoa(worksheet, headings);
  XLSX.utils.sheet_add_json(worksheet, ordersData, {
    origin: 'A2',
    skipHeader: true,
    header: columnHeaders,
  });

  const currentDate = moment().format('MM_DD_YYYY');
  const exportedFilename = `${filename}_${currentDate}`;

  XLSX.utils.book_append_sheet(workbook, worksheet, workSheetName);

  const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
    wch: i === 0 ? 30 : 20,
  }));

  worksheet['!cols'] = wscols;

  XLSX.writeFile(workbook, `${exportedFilename}.${extension}`);
};

export const formatCurrency = (price: number | string, currency: string = '') =>
  Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'PHP',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
    .format(typeof price === 'string' ? Number(price) : price)
    .replace('₱', currency + ' ');

export const formatDate = (date: string | Date) => {
  return moment(date).format('ll');
};
