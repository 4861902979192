import React, { useState } from 'react';
import { AppModuleContainer } from '../../lib/AppModuleContainer';
import { AppTab, AppTabList } from '../../lib/AppTab';
import RenderIf from '../../lib/RenderIf';
import OrderStatusView from './OrderStatusView';
import SummaryReportView from './SummaryReportView';

const ReportModule = () => {
    const [activeTab, setActiveTab] = useState(0);

    return (
        <AppModuleContainer>
            <AppTabList
                value={activeTab}
                onChange={(e, tabIndex) => setActiveTab(tabIndex)}
                aria-label="reports module tabs">
                <AppTab label="Summary Report" />
                <AppTab label="Order Status" />
            </AppTabList>
            <RenderIf condition={activeTab === 0}>
                <SummaryReportView />
            </RenderIf>
            <RenderIf condition={activeTab === 1}>
                <OrderStatusView />
            </RenderIf>
        </AppModuleContainer>
    );
};

export default ReportModule;
