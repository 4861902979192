import axios from 'axios';
import { Field } from '../../../store/fields/types';
import { mediaFieldUploader } from '../../../utils/fields';
import { CustomerLead, CustomerLeadFields } from './types';

const API_URL = process.env.REACT_APP_API_URL;
const leadsEndpoint = API_URL + '/leads';
const leadsFieldsEndpoint = API_URL + '/basic-module-fields/leads';
const uploadEndpoint = API_URL + '/media/basic/upload/customer-logo';

type GetOptions = {
  classification?: string;
  isActive?: boolean;
};

export const getCustomerLeads = async (options?: GetOptions) => {
  const isActive = options?.isActive !== undefined ? options.isActive : true;

  const url = options?.classification
    ? `${leadsEndpoint}?active=${isActive}&classification=${options.classification}`
    : `${leadsEndpoint}?active=${isActive}`;

  const response = await axios.get<CustomerLead[]>(url);

  return response.data;
};

export const getCustomerLeadById = async (id: string) => {
  const customerLead = await axios.get<CustomerLead>(`${leadsEndpoint}/${id}`);
  return customerLead.data;
};

export const getLeadsModuleFields = async () => {
  const response = await axios.get<CustomerLeadFields>(leadsFieldsEndpoint);

  return response.data;
};

export const createCustomerLead = async (
  fields: Field[],
  customerLead: any,
) => {
  let customerLeadData = {
    ...customerLead,
  };

  for (let field of fields) {
    if (field.type === 'Image' || field.type === 'Multimedia') {
      const mediaFields = await mediaFieldUploader(
        uploadEndpoint,
        field,
        customerLead,
        {},
      );

      customerLeadData = {
        ...customerLeadData,
        ...mediaFields,
      };
    }
  }

  const response = await axios.post(leadsEndpoint, customerLeadData);
  return response.data;
};

export const updateCustomerLead = async (
  fields: Field[],
  id: string,
  customerLead: any,
) => {
  let customerLeadData = {
    ...customerLead,
  };

  for (let field of fields) {
    if (field.type === 'Image' || field.type === 'Multimedia') {
      const mediaFields = await mediaFieldUploader(
        uploadEndpoint,
        field,
        customerLead,
        {},
      );

      customerLeadData = {
        ...customerLeadData,
        ...mediaFields,
      };
    }
  }

  const response = await axios.put(`${leadsEndpoint}/${id}`, customerLeadData);
  return response.data;
};

export const deleteCustomerLead = async (id: string) => {
  const response = await axios.delete(`${leadsEndpoint}/${id}`);
  return response.data;
};
