import React from 'react';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

import { styled } from '@material-ui/core/styles';
import { appColors } from './constants';

const SearchInput = styled(TextField)({
    backgroundColor: 'white',
    width: 280,

    '& .MuiInputBase-root.Mui-focused fieldset': {
        borderColor: appColors.primary,
    },

    '& svg': {
        color: appColors.primary,
    },
});

interface SearchFieldProps {
    keyword: string;
    onKeywordChange: (keyword: string) => void;
}

function SearchField({ keyword, onKeywordChange }: SearchFieldProps) {
    return (
        <SearchInput
            value={keyword}
            variant="outlined"
            size="small"
            placeholder="Search"
            onChange={(e) => onKeywordChange(e.target.value)}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
        />
    );
}

export default SearchField;
