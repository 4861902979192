import { loadVendorList, loadVendor } from "../../../store/usermanagement/actions";
import { UserManagementState, Vendor } from "../../../store/usermanagement/types";
import { SymphonyField } from "../../symphony/SymphonyCommonComponents";
import { fetchRatingPerVendor, onChangeSearchRating, setProductRatingState } from "../../../store/review/actions";
import { Box, InputAdornment, Typography } from "@material-ui/core";
import {
    ReviewCompanyHeader,
    ReviewContainer, ReviewEmptyMessage,
    ReviewProductContainer
} from "./fragments/ReviewComponents";
import CircularProgress from '@material-ui/core/CircularProgress';
import { SYMPHONY_PRIMARY_COLOR } from "../../symphony/Colors";
import { setSystemState } from "../../../store/system/actions";
import { LoginState } from "../../../store/login/types";
import VendorDropdown from "./fragments/VendorDropdown";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import Search from "@material-ui/icons/Search";
import { AppState } from "../../../store";
import ProductList from "./fragments/ProductList";
import { ProductLoadingContainer } from "../product/fragments/ProductComponents";
import Sort from "./fragments/Sort";
import _ from 'lodash'

interface IReview {
    setSystemState: typeof setSystemState;
    loadVendorList: typeof loadVendorList;
    loadVendor: typeof loadVendor;
    fetchRatingPerVendor: typeof fetchRatingPerVendor;
    onChangeSearchRating: typeof onChangeSearchRating;
    setProductRatingState: typeof setProductRatingState;
    userManagement: UserManagementState;
    login: LoginState;
}

const VendorReview = (props: IReview) => {
    const { setProductRatingState, loadVendorList, fetchRatingPerVendor, setSystemState, login } = props;
    let { vendors, vendorDetails } = useSelector((state: AppState) => state.usermanagement)
    const [selectedVendor, setSelectedVendor] = useState<Partial<{ label: string; value: string }> | null>(null)
    const isAdmin = login.user.userType == 'ADMIN'

    useEffect(() => {
        setSystemState({
            header: 'Product Review',
            headerEndButton: () => (
                <div>
                    <Box display="flex" justifyContent="center">
                        <SymphonyField
                            id="promotion-search-fld"
                            style={{ marginBottom: 8, width: 300 }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">
                                    <Search htmlColor={SYMPHONY_PRIMARY_COLOR} />
                                </InputAdornment>
                            }}
                            placeholder="Search"
                            onChange={(e) => search(e.target.value)}
                        />
                    </Box>
                </div>
            ),
            shallRedirect: false,
            redirectTo: ''
        });

        if (!isAdmin) {
            fetchRatingPerVendor(login.user.id)
            setSelectedVendor(login.user)
        }
        else loadVendorList()
        
    }, [isAdmin])

    const search = _.debounce((e) => {
        fetchRatingPerVendor(!isAdmin ? login.user.id : null, e)
    }, 300, { leading: false })

    const handleVendorClick = (vendor: Partial<{ label: string; value: string }> | null) => {
        if (vendor) {
            fetchRatingPerVendor(vendor.value!)
            setSelectedVendor(vendor)
            setProductRatingState({ selectedVendor: vendor.value })
        }
    }

    return (
        <ReviewContainer>
            { isAdmin && <VendorDropdown value={vendors} onSelectedChange={(vendor) => handleVendorClick(vendor)} /> }
            {
                !selectedVendor ?
                    <ReviewEmptyMessage>
                        <Typography style={{ fontSize: 18, color: 'rgb(150,150,150)', marginBottom: '7px' }}>
                            Select a vendor to show information
                        </Typography>
                    </ReviewEmptyMessage>
                    : <Products vendor={selectedVendor.label!} vendors={vendors} />
            }
        </ReviewContainer>
    )
}

interface IProducts {
    vendor: string;
    vendors: Array<Vendor>
}

const Products = (props: IProducts) => {
    const { loading, productRating } = useSelector((state: AppState) => state.review)
    return (
        <ReviewProductContainer>
            <ReviewCompanyHeader>
                <Typography variant="h5">
                    {props.vendor}
                </Typography>
                <Sort module="Vendor"/>
            </ReviewCompanyHeader>
            {
                loading ? <ProductLoadingContainer style={{ height: '40vh' }}>
                    <CircularProgress style={{ color: SYMPHONY_PRIMARY_COLOR }} />
                </ProductLoadingContainer>
                    : <ProductList />
            }
        </ReviewProductContainer>
    )
}


const mapStateToProps = (state: AppState) => ({
    userManagement: state.usermanagement,
    login: state.login,
});

export default connect(mapStateToProps, {
    setSystemState,
    loadVendorList,
    loadVendor,
    fetchRatingPerVendor,
    onChangeSearchRating,
    setProductRatingState
})(VendorReview);