import React from 'react';
import { Link } from 'react-router-dom';
import { BasicProduct, BasicProductMedia } from '../../../../store/basicproduct/types';

import {
    SymphonySortableHeaderGridContainer,
    SymphonySortableHeaderGrid
} from '../../../symphony/SymphonyCommonComponents';
import SymphonySortableHeader from '../../../symphony/SymphonySortableHeader';

import {
    ProductListContainer,
    ProductRowContainer,
    ProductGrid,
    ProductImageContainer,
    ProductRowsContainer
} from './ProductComponents';

// material
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

// util
import map from 'lodash/map';

// default svg
import { ReactComponent as DefaultPic } from '../../../Basic/Common/BasicDefaultSvg.svg';
import { AccessibilityCheckbox } from '../../../symphony/accessibility/fragments/AccessibilityComponents';
import { AppState } from '../../../../store';
import { connect, useDispatch, useSelector } from 'react-redux';
import { setBasicProductState } from '../../../../store/basicproduct/actions';

interface ProductCardListProps {
    products: Array<BasicProduct>;
    setBasicProductState: typeof setBasicProductState;
}

const ProductCardList = (props: ProductCardListProps) => {
    const dispatch = useDispatch();
    const { selectedBasicProduct, removeCheckedValue, uncheckAll } = useSelector((state: AppState) => state.basicproduct);
    const { products, setBasicProductState } = props;
    const [array, setArray] = React.useState<Array<Partial<{ id: string }>>>(selectedBasicProduct!)

    React.useEffect(() => {
        if (!removeCheckedValue) {
            dispatch(setBasicProductState({ selectedBasicProduct: array }));
        } else {
            setArray(selectedBasicProduct!)
        }
    }, [array, dispatch, selectedBasicProduct, removeCheckedValue])


    const handleUncheckAll = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        // setSelected(e.target.checked)
        setBasicProductState({ uncheckAll: e.target.checked })
        if (e.target.checked) {
            map(products, (v) => {
                const chkbx = (document.getElementById(`selected-${v.id}`) as HTMLInputElement)
                if (chkbx) chkbx.checked = true
                return v
            })
            setArray([...products as Array<{ id: string }>])
        } else {
            map(products, (v) => {
                if (v) (document.getElementById(`selected-${v.id}`) as HTMLInputElement)!.checked = false
            })
            setArray([])
        }
    }, [array])

    const removeItem = (arr, index) => {
        if (index > -1) {
            arr.splice(index, 1);
        }
        return arr;
    }

    const handleVendorCheckboxChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>, vendor: BasicProduct) => {
        const index = array.findIndex(x => x.id === vendor.id);
        if (e.target.checked) {
            // add vendor
            if (!(index > -1)) {
                setBasicProductState({ removeCheckedValue: false })
                setArray(a => [...a, vendor])
            }
        } else {
            // remove vendor
            setBasicProductState({ uncheckAll: e.target.checked })
            setArray(a => [...removeItem(a, index)])
        }
    }, [array])

    return (
        <ProductListContainer>
            {/* Header here */}
            <SymphonySortableHeaderGridContainer container={true}>
                <SymphonySortableHeaderGrid item={true} xs={5}>
                    <input type='checkbox'
                        checked={uncheckAll}
                        style={{ marginRight: '15px', marginLeft: 0, cursor: 'pointer' }}
                        onChange={handleUncheckAll} />
                    <SymphonySortableHeader
                        headerTitle="Product Name"
                        headerValue="name"
                    />
                </SymphonySortableHeaderGrid>
                <SymphonySortableHeaderGrid item={true} xs={3}>
                    <SymphonySortableHeader
                        headerTitle="Code"
                        headerValue="displayId"
                    />
                </SymphonySortableHeaderGrid>
                <SymphonySortableHeaderGrid item={true} xs={4}>
                    <SymphonySortableHeader
                        headerTitle="Hierarchy"
                        headerValue=""
                    />
                </SymphonySortableHeaderGrid>
            </SymphonySortableHeaderGridContainer>
            {/* Cards here */}
            <ProductRowsContainer>
                {map(products, (product) => {
                    const image = (product.featuredSkuImage as unknown) as BasicProductMedia; // type cast
                    const imageUndefined = typeof image === 'undefined';
                    return (
                        <div style={{ display: 'flex', alignItems: 'center' }} key={product.id}>
                            <input
                                type='checkbox'
                                id={`selected-${product.id}`}
                                style={{ marginLeft: '12px', cursor: 'pointer' }}
                                onChange={(e) => handleVendorCheckboxChange(e, product)} />
                            <Link
                                id={`product-view-${product.id}`}
                                key={product.id}
                                to={`/sales/product/${product.id}`}
                                style={{ display: 'flex', textDecoration: 'none', color: 'unset', width: '100%' }}
                            >
                                <ProductRowContainer>
                                    <Grid container={true} >
                                        <ProductGrid item={true} xs={5}>
                                            <Box display="inline-flex" alignItems="center">
                                                <ProductImageContainer style={{ backgroundColor: imageUndefined ? '#F3F3F3' : 'transparent', marginRight: 8 }}>
                                                    {!imageUndefined ?
                                                        <img
                                                            src={typeof image !== 'undefined' ? image.path : ''}
                                                            alt="" style={{ width: 50, height: 'auto', maxHeight: 60 }}
                                                        />
                                                        :
                                                        <DefaultPic style={{ width: 32, height: 32, marginLeft: 4, }} />
                                                    }
                                                </ProductImageContainer>
                                                <Box>
                                                    {product.name}
                                                    <Box color="#A2A2A2">
                                                        {product.varietiesCount || '1'} {product.varietiesCount && product.varietiesCount > 1 ? 'Varieties' : 'Variety'}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </ProductGrid>
                                        <ProductGrid item={true} xs={3} color="#A2A2A2">
                                            {product.displayId || ''}
                                        </ProductGrid>
                                        <ProductGrid item={true} xs={4}>
                                            <Box display="inline" color="#A2A2A2">{`${product.h1}/${product.h2}/${product.h3}`}</Box>
                                        </ProductGrid>
                                    </Grid>
                                </ProductRowContainer>
                            </Link>
                        </div>
                    )
                })}
            </ProductRowsContainer>

        </ProductListContainer>
    )
}

export default connect(() => ({}), {
    setBasicProductState,
})(ProductCardList);
