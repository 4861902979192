import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { dataColors } from './util/chart-utils';
import { formatNumber } from '../../lib/helpers';

const OrderHistoryTotal = ({ total }: { total: number }) => {
    return (
        <Box
            textAlign="center"
            position="absolute"
            top="50%"
            left="50%"
            style={{ transform: 'translate(-50%, -50%)' }}>
            <Typography style={{ color: dataColors.textColor, fontSize: 18 }}>
                Total Order
            </Typography>
            <Typography style={{ fontWeight: 600, fontSize: 24 }}>
                {formatNumber(total)}
            </Typography>
        </Box>
    );
};

export default OrderHistoryTotal;
