import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Material UI
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// Utils
import moment from 'moment';
import { withStyles } from '@material-ui/styles';

// store
import { Promotion } from '../../../../store/promotion/types';
import { useDispatch } from 'react-redux';
import { setPromotionState } from '../../../../store/promotion/actions';

const BannerCardContainer = withStyles(
    () => ({
        root: {
            width: '95%',
            height: '100%',
            borderRadius: 5,
            borderWidth: 0.5,
            borderColor: 'rgba(158, 150, 150, .5)',
            borderStyle: 'solid'
        }
    })
)(Box)

interface PromoProps {
    promotion: Promotion
}

export default ({ promotion }: PromoProps) => {
    const [bannerType, setBannerType] = useState<'Active' | 'On Going' | 'Coming Soon' | 'Inactive'>('Active');
    const dispatch = useDispatch();

    useEffect(() => {
        let endDate = moment(promotion && promotion.endDate, "DD/MM/YYYY")
        let startDate = moment(promotion && promotion.startDate, "DD/MM/YYYY")
        let currentDate = moment(moment.now()).format("YYYY/MM/DD")
        let differenceForEndDate = endDate.diff(currentDate, 'days');
        let differenceForStartDate = startDate.diff(currentDate, 'days');

        if (differenceForStartDate <= 0 && differenceForEndDate >= 0 && promotion.isActive) {
            setBannerType('On Going')
        } else if (differenceForStartDate > 0 && promotion.isActive) {
            setBannerType('Coming Soon')
        } else {
            setBannerType('Inactive')
        }

    }, [promotion.startDate, promotion.endDate])

    const isExpired = () => {
        let endDate = moment.utc(moment(promotion && promotion.endDate, "DD/MM/YYYY").format())
        let startDate = moment.utc(moment(promotion && promotion.startDate, "DD/MM/YYYY").format())
        let currentDate = moment().utc();
        let differenceForEndDate = currentDate.diff(endDate, 'days');
        let differenceForStartDate = currentDate.diff(startDate, 'days');

        if (differenceForStartDate >= 0 && differenceForEndDate <= 0 && promotion.isActive) {
            return "On Going"
        } else if (differenceForStartDate < 0 && promotion.isActive) {
            return "Coming Soon"
        } else {
            return "Inactive"
        }
    }

    return (
        <Link id="nav-home" to={`/sales/promotions?id=${promotion.id}`} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', width: '100%', height: '100%', textDecoration: 'none', color: '#000' }}
            onClick={() => dispatch(setPromotionState(promotion))}>
            <BannerCardContainer
                id={`promobanner-bannercard-${promotion.id}`}
            >
                <Box style={{ height: '50%', width: '100%' }}>
                    {
                        promotion.images.length > 0 && <img src={promotion.images[0].path} alt={promotion.title} style={{ height: '100%', width: '100%', objectFit: 'cover', filter: 'brightness(75%)', borderTopRightRadius: 4, borderTopLeftRadius: 4 }} />
                    }
                </Box>
                <Box style={{ height: '50%', width: '100%' }}>
                    <Box style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', justifyContent: 'space-evenly' }}>
                        <Box style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'space-between', paddingLeft: 20, paddingRight: 20, paddingTop: 10 }}>
                            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', flexWrap: 'nowrap' }}>
                                <Box style={{ display: 'flex', flexDirection: 'column', width: '70%' }}>
                                    <Typography style={{ fontSize: 14, fontWeight: 600 }} noWrap>{promotion.title}</Typography>
                                    <Typography style={{ fontSize: 12, opacity: 0.5 }} noWrap>{promotion.subTitle}</Typography>
                                </Box>
                                <Box
                                    style={{
                                        backgroundColor: bannerType === 'On Going' ? '#C1F7DF' : bannerType === 'Coming Soon' ? '#DEE9FF' : '#f58c8c',
                                        height: 25,
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                        borderRadius: 12,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                    <Typography style={{ fontSize: 9, color: bannerType === 'On Going' ? '#00AD4C' : bannerType === 'Coming Soon' ? '#4C89F5' : '#f52f2f', fontWeight: 'bold' }}>{bannerType.toUpperCase()}</Typography>
                                </Box>
                            </Box>
                            {
                                isExpired() === 'On Going' ?
                                    <Box style={{ display: 'flex', flex: .5, flexDirection: 'row', width: '100%', flexWrap: 'nowrap' }}>
                                        <Typography style={{ fontSize: 12, opacity: 0.5 }}>{moment(promotion.startDate, 'DD/MM/YYYY').format("MMM. DD YYYY")}</Typography>
                                        <Typography style={{ fontSize: 12, opacity: 0.5, marginLeft: 3, marginRight: 3 }}>-</Typography>
                                        <Typography style={{ fontSize: 12, opacity: 0.5 }}>{moment(promotion.endDate, 'DD/MM/YYYY').format("MMM. DD YYYY")}</Typography>
                                    </Box>
                                    : isExpired() === 'Inactive' ?
                                        <Box style={{ display: 'flex', flex: .5, flexDirection: 'row', width: '100%', flexWrap: 'nowrap' }}>
                                            <Typography style={{ fontSize: 12, opacity: 0.5 }}>Inactive</Typography>
                                        </Box> : <Box style={{ display: 'flex', flex: .5, flexDirection: 'row', width: '100%', flexWrap: 'nowrap' }}>
                                            <Typography style={{ fontSize: 12, opacity: 0.5 }}>{moment(promotion.startDate, 'DD/MM/YYYY').format("MMM. DD YYYY")}</Typography>
                                            <Typography style={{ fontSize: 12, opacity: 0.5, marginLeft: 3, marginRight: 3 }}>-</Typography>
                                            <Typography style={{ fontSize: 12, opacity: 0.5 }}>{moment(promotion.endDate, 'DD/MM/YYYY').format("MMM. DD YYYY")}</Typography>
                                        </Box>
                            }
                        </Box>
                    </Box>
                </Box>
            </BannerCardContainer>
        </Link>
    )
}