import React, { useMemo, useState, CSSProperties } from 'react';
import { useHistory } from 'react-router';
import { matchSorter } from 'match-sorter';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import useTableUtils from '../../lib/table/useTableUtils';
import AMITable from '../../lib/table/AMITable';
import AMITableHeading from '../../lib/table/AMITableHeading';
import AMITableBody from '../../lib/table/AMITableBody';
import SearchField from '../../lib/SearchField';

import { customerLeadsColumns } from './leads-columns';
import { useGetLeadsQuery } from './leads-hooks';
import { appColors } from '../../lib/constants';
import LeadsFilter from './LeadsFilter';

const buttonStyles: CSSProperties = {
  backgroundColor: appColors.primary,
  textTransform: 'capitalize',
  color: 'white',
  fontWeight: 600,
  marginBottom: 12,
  padding: 11,
};

function LeadsTable() {
  const {
    page,
    paginate,
    sort,
    sortColumn,
    sortHandler,
    handleChangePage,
    handleChangeRowsPerPage,
    rowsPerPage,
  } = useTableUtils({ initialSortByOrder: 'desc' });

  const [search, setSearch] = useState('');
  const navigate = useHistory();

  const columns = useMemo(() => customerLeadsColumns, []);

  const customerLeads = useGetLeadsQuery();

  const data = customerLeads.data ? customerLeads.data : [];

  const filteredData = matchSorter(data, search, {
    keys: [
      'businessName',
      'contactPersonName',
      'email',
      'contactNumber',
      'classification',
      'source',
    ],
  });

  const sortedData = sort(filteredData, sortColumn.path, sortColumn.order);
  const paginatedData = paginate(sortedData, page, rowsPerPage);

  const goToLeadsForm = () => navigate.push('/sales/leads/new');

  const goToLeadsEditForm = (id: string) => navigate.push(`/sales/leads/${id}`);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pb={3}>
        <LeadsFilter />
        {customerLeads.data && !customerLeads.isLoading && (
          <Typography style={{ fontSize: 12, color: appColors.mediumGray }}>
            Showing {filteredData.length} result
            {filteredData.length > 1 ? 's' : ''}
          </Typography>
        )}
      </Box>
      <Box
        position="absolute"
        top={68}
        right={27}
        display="flex"
        flexDirection="column"
        alignItems="flex-end">
        <Button
          onClick={goToLeadsForm}
          disableElevation
          startIcon={<AddIcon />}
          size="large"
          style={buttonStyles}>
          Add New
        </Button>
        <SearchField keyword={search} onKeywordChange={setSearch} />
      </Box>
      <Paper elevation={0}>
        <AMITable sticky height="calc(100vh - 350px)">
          <AMITableHeading
            columns={columns}
            sortColumn={sortColumn}
            onSort={sortHandler}
          />
          <AMITableBody
            loading={customerLeads.isLoading}
            data={paginatedData}
            columns={columns}
            emptyText="No data to display"
            onRowClick={(leads) => goToLeadsEditForm(leads.id)}
            withHover
          />
        </AMITable>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={sortedData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}

export default LeadsTable;
