import React from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio, { RadioProps } from '@material-ui/core/Radio';

const useStyles = makeStyles({
    root: {
        fontSize: 12,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 12,
        height: 12,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 12,
            height: 12,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },
    label: {
        fontSize: 12,
        fontWeight: 500,
        color: '#959595'
    },
    radioLabel: {
        fontSize: 12,
        fontWeight: 500,
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'row',
    }
});

const RadioLabel = withStyles({
    root: {
        fontSize: 12,
        paddingTop: 5,
        paddingBottom: 15
    },
    label: {
        fontSize: 12
    }
})(FormControlLabel);

// Inspired by blueprintjs
function StyledRadio(props: RadioProps) {
    const classes = useStyles();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}

interface IRadioProps {
    label: string;
    options: Array<{ label: string, value: string }>;
    defaultValue: string;
    onRadioChange?: (event, value) => void;
}

export default function CustomizedRadios({ label, options, defaultValue, onRadioChange }: IRadioProps) {
    const classes = useStyles();
    return (
        <FormControl component="fieldset" style={{ marginTop: 10 }}>
            <FormLabel component="legend" style={{ color: '#959595 !important', fontSize: 12 }}>{label}</FormLabel>
            <RadioGroup defaultValue={defaultValue} name="customized-radios" className={classes.radioGroup}
                onChange={onRadioChange}>
                {
                    options.map((option, index) => (
                        <RadioLabel key={index} value={option.value} control={<StyledRadio />} label={option.label} />
                    ))
                }
            </RadioGroup>
        </FormControl>
    );
}
