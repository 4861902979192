import { Grid } from "@material-ui/core";
import { map } from "lodash";
import React from "react";
import { BannerSelectedCategoryType } from "../../../../store/promobanner/types";
import SelectedCategoryRow from "./SelectedCategoryRow";


interface PromoBannerCategoryInputProps {
    selectedCategory: Array<BannerSelectedCategoryType>;
}

const SelectedCategory = (props: PromoBannerCategoryInputProps) => {
    const { selectedCategory } = props;
    return (
        <Grid container style={{ overflow: 'auto', height: '57vh' }}>
            <Grid item>
                {map(selectedCategory, (selectedCat) => {
                    return (
                        <SelectedCategoryRow
                            key={`promobanner-selectedcategoryrow-${selectedCat.h1}${selectedCat.h2 && `-${selectedCat.h2}`}${selectedCat.h3 && `-${selectedCat.h3}`}`}
                            h1={selectedCat.h1}
                            h2={selectedCat.h2}
                            h3={selectedCat.h3}
                        />
                    )
                })}
            </Grid>
        </Grid>
    )
}

export default SelectedCategory;