import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/CheckCircle';
import CircleIcon from '@material-ui/icons/FiberManualRecord';
import FailedIcon from '@material-ui/icons/Cancel';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import { History } from './types';
import { appColors } from '../../../lib/constants';
import { getJourneyData } from './constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: 24,
      fontWeight: 600,
      marginBottom: 24,
    },

    historyItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    track: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: 40,
      backgroundColor: appColors.bluishWhite,
      borderRadius: 100,
    },
    status: {
      fontSize: 15,
      letterSpacing: '0.5px',
      textTransform: 'capitalize',
      margin: '12px 0',
    },
    checkIcon: {
      color: appColors.indigo,
      zIndex: 3,
      marginTop: 3,
      marginLeft: 4,
      marginRight: 4,
    },
    failedIcon: {
      color: appColors.error,
      zIndex: 3,
      marginTop: 3,
      marginLeft: 4,
      marginRight: 4,
    },
    circleIcon: {
      color: appColors.mediumGray,
      zIndex: 3,
      marginTop: 3,
      marginLeft: 4,
      marginRight: 4,
      transform: 'scale(1.2)',
    },
    date: {
      color: appColors.mediumGray,
      fontSize: 14,
      textAlign: 'center',
    },
  }),
);

// TODO: make sure that history is sorted by date

function OrderStatus({
  historyData,
  isReturn,
  status,
}: {
  historyData: History[];
  isReturn: boolean;
  status: string;
}) {
  const classes = useStyles();

  const journeyToDisplay = getJourneyData(historyData, isReturn);

  console.log(journeyToDisplay);

  return (
    <>
      <Typography className={classes.title}>Order Status</Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        position="relative"
        zIndex={2}
        mb={4}>
        <Box className={classes.track} />
        {journeyToDisplay.map((history, index) => (
          <Box key={index} className={classes.historyItem}>
            {history.isFailed ? (
              <FailedIcon fontSize="large" className={classes.failedIcon} />
            ) : history.isFilled ? (
              <CheckIcon
                fontSize="large"
                className={classes.checkIcon}
                style={{
                  alignSelf:
                    index === 0
                      ? 'flex-start'
                      : index === journeyToDisplay.length - 1
                      ? 'flex-end'
                      : 'center',
                }}
              />
            ) : (
              <CircleIcon
                fontSize="large"
                className={classes.circleIcon}
                style={{
                  alignSelf:
                    index === 0
                      ? 'flex-start'
                      : index === journeyToDisplay.length - 1
                      ? 'flex-end'
                      : 'center',
                }}
              />
            )}
            <Typography className={classes.status}>{history.status}</Typography>
            <Typography className={classes.date}>{history.date}</Typography>
            <Typography className={classes.date}>{history.time}</Typography>
          </Box>
        ))}
      </Box>
    </>
  );
}

export default OrderStatus;
