import React from 'react'
import { countBy } from 'lodash';
import { useDispatch,useSelector } from 'react-redux'
import { getTopRatedReport, selectTRPData, selectTRPState, setTRProductFilter } from '../../../store/marketreport/toprated/reducer';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import useAtBreakpoint from '../../symphony/hooks/useAtBreakpoint';
import ChartContainer from './ChartContainer';
import { dataColors } from './util/chart-utils';  
import SingleFilterGroup from './SingleFilterGroup';
import { AppState } from '../../../store';
import { Box, Typography } from '@material-ui/core';
import ChartDataLabels from 'chartjs-plugin-datalabels'
const { blue, red, brown, teal, orange, green, lightGray } = dataColors;
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const TopProductRatingsReport = () => {
  const isLargeScreen = useAtBreakpoint('xl');
  const dispatch = useDispatch();
  const {filter,error,stateStatus} =  useSelector(selectTRPState);
  const products = useSelector(selectTRPData)
  React.useEffect(()=>{
    dispatch(getTopRatedReport(filter))
  },[dispatch,filter])
  
  const handleFilterChange = (key: keyof typeof filter, value: string) => {
    dispatch(
      setTRProductFilter({
            ...filter,
            [key]: value,
        }),
    );
}
React.useEffect(()=>{
  const canv = document.getElementsByTagName('canvas')
  console.log(canv[2])
  console.log(window)
},[])


const firstItem = countBy(products[0]?.reviews)
const secondItem = countBy(products[1]?.reviews)
const thirdItem = countBy(products[2]?.reviews)
const fourthItem = countBy(products[3]?.reviews)
const fifthItem = countBy(products[4]?.reviews)

const manageTopRatingChartOptions =():ChartOptions<'bar'> => ({
  layout:{
    padding:{
      bottom:40
    }
  },
  scales:{
    x:{
      stacked:false,
      ticks:{
        padding:20,
        font:{
          size:12
        }
      },
      
    }
  },
  responsive:true,
  datasets:{
    bar: {
      barThickness: 15,
      borderRadius(ctx, options) {
          return {
              topLeft: 6,
              topRight: 6,
              bottomLeft: 0,
              bottomRight: 0,
          };
      },
      
    },
  },
  plugins:{
    legend:{
      display:false
    },
    datalabels:{

      color:'yellow',

      anchor:'start',

      align:'bottom',

      formatter:function(value,x){

        return x.chart.data.datasets[x.dataIndex]

      },
      
      font:{

        family:"'FontAwesome','Helvetica Neue','Helvetica',sans-serif",

       

      },



    },
  }
  
})


const dataToDisplay : ChartData ={
  labels: products.map(i => i.product_name),
  datasets:[
    {
    label:'5 Star',
    data:[firstItem[5],secondItem[5],thirdItem[5],fourthItem[5],fifthItem[5],],
    backgroundColor:blue,
    stack: 'Stack 0',
    borderWidth:3,
    datalabels:{
      anchor:"start",
      align:"start",
      font:{
        family: '"Font Awesome 5 Free"',
        size:10,
        weight:900,
      },
      color:"rgb(246,190,0)",
      formatter(value,context){
        return 5 + "\uf005"
      }
    }
    
  },
  {
    label:'4 Star',
    data:[firstItem[4],secondItem[4],thirdItem[4],fourthItem[4],fifthItem[4],],
    backgroundColor:red,
    stack: 'Stack 1',
    borderWidth:3,
    datalabels:{
      anchor:"start",
      align:"start",
      font:{
        family: '"Font Awesome 5 Free"',
        size:10,
        weight:900,
      },
      color:"rgb(246,190,0)",
      formatter(value,context){
        return 4 + "\uf005"
      }
    }
    
  },
  {
    label:'3 Star',
    data:[firstItem[3],secondItem[3],thirdItem[3],fourthItem[3],fifthItem[3],],
    backgroundColor:brown,
    stack: 'Stack 2',
    borderWidth:3,
    datalabels:{
      anchor:"start",
      align:"start",
      font:{
        family: '"Font Awesome 5 Free"',
        size:10,
        weight:900,
      },
      color:"rgb(246,190,0)",
      formatter(value,context){
        return 3 + "\uf005"
      }
    }
    
  },
  {
    label:'2 Star',
    data:[firstItem[2],secondItem[2],thirdItem[2],fourthItem[2],fifthItem[2],],
    backgroundColor:teal,
    stack: 'Stack 3',
    borderWidth:3,
    datalabels:{
      anchor:"start",
      align:"start",
      font:{
        family: '"Font Awesome 5 Free"',
        size:10,
        weight:900,
      },
      color:"rgb(246,190,0)",
      formatter(value,context){
        return 2 + "\uf005"
      }
    }
    
  },
  {
    label:'1 Star',
    data:[firstItem[1],secondItem[1],thirdItem[1],fourthItem[1],fifthItem[1],],
    backgroundColor:orange,
    stack: 'Stack 4',
    borderWidth:3,
    datalabels:{
      anchor:"start",
      align:"start",
      font:{
        family: '"Font Awesome 5 Free"',
        size:10,
        weight:900,
      },
      color:"rgb(246,190,0)",
      formatter(value,context){
        return 1 + "\uf005"
      }
    }
   
  },
]

}

  return (
    <Box mt={2} mb={2} style={{height:'100%',minHeight:'100%'}} id='top-rated'>
      <ChartContainer
      title='Top 5 Rating Products'
      loading={stateStatus === 'loading'}
      error={error}
      noData={products.length === 0}
      controls={
        <SingleFilterGroup
        filter={filter}
        onFilterChange={handleFilterChange}
         />

      }
      >
      <Bar 
      id='top-rated-chart'
      height={isLargeScreen? 80 : 70}
      type="bar"
      data={dataToDisplay}
      plugins={[ChartDataLabels]}
      options={manageTopRatingChartOptions()}
      />
      </ChartContainer>
    </Box>
  )
}

export default TopProductRatingsReport;
