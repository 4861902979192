import { Button, ButtonProps } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import React from 'react';
import { SYMPHONY_PRIMARY_COLOR } from '../symphony/Colors';

const SymphonyCTA = styled(Button)(({ theme }) => ({
    minWidth: 120,
    width: 'max-content',
    textTransform: 'none',
    color: '#FFF',
    border: '1px solid #E5E5E5',
    height: 45,
    borderRadius: 5,
    fontWeight: 500,
    margin: '0 5px',
    padding: '7px 15px',

    '&.primary': {
        backgroundColor: SYMPHONY_PRIMARY_COLOR
    },

    '&.primary:hover': {
        backgroundColor: 'transparent',
        color: SYMPHONY_PRIMARY_COLOR,
        borderColor: SYMPHONY_PRIMARY_COLOR
    },

    '&.outlined': {
        backgroundColor: '#fff',
        color: SYMPHONY_PRIMARY_COLOR,
        border: `1px solid ${SYMPHONY_PRIMARY_COLOR}`
    },

    '&.outlined:hover': {
        backgroundColor: SYMPHONY_PRIMARY_COLOR,
        color: '#fff',
    },

    '&.secondary': {
        backgroundColor: '#fff',
        color: '#000',
        border: `1px solid #E5E5E5`
    },

    '&.secondary:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        color: '#000',
    },
}));

interface BrandProps {
    brand?: 'primary' | 'outlined' | 'secondary';
}

const CTAButton = (props: ButtonProps & BrandProps) => {
    const { brand } = props;

    return (
        <SymphonyCTA {...props} className={brand ? brand : 'primary'}></SymphonyCTA>
    )
}

export default CTAButton;