
import { ProductCell, ProductRow, ReviewProductListContainer } from "./ReviewComponents";
import SymphonyImage from "../../../symphony/SymphonyImage";
import { AppState } from "../../../../store";
import { useSelector } from "react-redux";
import React from "react";
import { Box, Typography } from "@material-ui/core";
import StarRating from "./StarRating";

const ProductList = () => {
    const { productRating, loading } = useSelector((state: AppState) => state.review)
    const path = window.location.pathname

    return (
        <ReviewProductListContainer>
            {
                !!productRating.length ?
                    productRating.map(product => (
                        <ProductRow key={product.id}
                            to={{pathname: `${path}/${product.id}`}}
                            style={{ display: 'flex', textDecoration: 'none', padding: '15px 0', 
                            borderBottom: '1px solid #ededed', color: 'black' }}>
                            <ProductCell>
                                <SymphonyImage
                                    src={product && !!product.media.length ? product.media[0].path : ''}
                                    style={{ width: 50, height: 'auto', maxHeight: 60, marginRight: '40px' }}
                                />
                                <Typography style={{ color: 'black', fontSize: 13 }}>{product.name}</Typography>
                            </ProductCell>
                            <ProductCell>
                                <strong style={{ fontSize: 13, marginRight: '7px' }}>Code: </strong>
                                <Typography style={{ color: '#959595', fontSize: 13 }}>
                                    {product.displayId}
                                </Typography>
                            </ProductCell>
                            <ProductCell style={{ justifyContent: 'end' }}>
                                <strong style={{ fontSize: 13, marginRight: '7px' }}>Average Rating: </strong>
                                <StarRating rating={product.averageRating}/>
                            </ProductCell>
                            <ProductCell style={{ justifyContent: 'center' }}>
                                <Typography style={{ color: 'black', fontSize: 16, fontWeight: 600 }}>
                                    {!!product.averageRating ? product.averageRating.toFixed(1) : 0}
                                </Typography>
                            </ProductCell>
                        </ProductRow>
                    ))
                    : <Typography style={{ fontSize: 18, color: 'rgb(150,150,150)', textAlign: 'center', marginBottom: '7px' }}>
                        No products to display
                    </Typography>
            }
        </ReviewProductListContainer>
    )
};

export default ProductList;