import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import { appColors } from '../../../lib/constants';
import { History } from './types';
import { getJourneyData } from './constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: 24,
      fontWeight: 600,
      marginBottom: 8,
    },
    label: {
      color: appColors.mediumGray,
      fontSize: 15,
    },
    listItem: {
      padding: '10px 0',
      borderBottom: '1px solid #eee',
      alignItems: 'center',
    },
    itemText: {
      fontSize: 15,
    },
    button: {
      textTransform: 'capitalize',
      color: appColors.primary,
    },
  }),
);

function OrderUpdates({
  historyData,
  isReturn,
}: {
  historyData: History[];
  isReturn: boolean;
}) {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => setExpanded((state) => !state);

  const journeyToDisplay = getJourneyData(historyData, isReturn);

  return (
    <Box mb={4}>
      <Typography className={classes.title}>Updates</Typography>
      <Box
        display="grid"
        gridTemplateColumns="400px 1fr auto"
        className={classes.listItem}>
        <Typography className={classes.label}>Date & Time</Typography>
        <Typography className={classes.label}>Description</Typography>
        <Button onClick={toggleExpanded} className={classes.button}>
          {expanded ? 'See Less Details' : 'See More Details'}
        </Button>
      </Box>
      <Collapse in={expanded} collapsedHeight="44px">
        {journeyToDisplay.map((history, index) => (
          <Box
            key={index}
            display="grid"
            gridTemplateColumns="400px 1fr"
            className={classes.listItem}>
            <Typography className={classes.itemText}>
              {history.date} - {history.time}
            </Typography>
            <Typography className={classes.itemText}>
              {history.description ? history.description : '--'}
            </Typography>
          </Box>
        ))}
      </Collapse>
    </Box>
  );
}

export default OrderUpdates;
