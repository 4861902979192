import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../store';
import { PromotionState, Promotion as PromotionType, DynamicBasicPromotionType, BasicPromotionMedia } from '../../../store/promotion/types';
import {
    setPromotionState,
    loadPromotions,
    uploadPromotionMedia,
    savePromotion,
    deletePromotion,
    fetchTierOneCategories,
    fetchTierTwoCategories,
    fetchTierThreeCategories
} from '../../../store/promotion/actions';
import { setSystemState, resetSystemDialog } from '../../../store/system/actions';
import { MediaInputType } from '../../../store/system/types';
import BasicLayout from '../Common/BasicLayout';
import PromotionList from './fragments/PromotionList';
import PromotionView from './fragments/PromotionView';
import BasicMediaPreview from '../Common/BasicMediaPreview';
import Grid from '@material-ui/core/Grid';
import find from 'lodash/find';
import debounce from 'lodash/debounce';
import { v4 } from 'uuid'
import moment, { MomentInput } from 'moment';
import { toastWarning } from '../../../modules/Toast';
import { SymphonyContentContainer, SymphonyField, SymphonyHeaderButton, SymphonyTab, SymphonyTabs, SymphonyTabsContainer } from '../../symphony/SymphonyCommonComponents';
import AddIcon from '@material-ui/icons/Add';
import { SYMPHONY_PRIMARY_COLOR } from '../../symphony/Colors';
import { InputAdornment } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import SymphonyContentLoading from '../../symphony/SymphonyContentLoading';

interface PromotionProps {
    setPromotionState: typeof setPromotionState;
    loadPromotions: typeof loadPromotions;
    uploadPromotionMedia: typeof uploadPromotionMedia;
    savePromotion: typeof savePromotion;
    deletePromotion: typeof deletePromotion;
    setSystemState: typeof setSystemState;
    resetSystemDialog: typeof resetSystemDialog;
    fetchTierOneCategories: typeof fetchTierOneCategories;
    fetchTierTwoCategories: typeof fetchTierTwoCategories;
    fetchTierThreeCategories: typeof fetchTierThreeCategories
    promotion: PromotionState
}

class Promotion extends React.Component<PromotionProps> {
    search = debounce((input: string) => {
        this.props.loadPromotions({ search: input, status: this.props.promotion.activeTab });
    }, 300, { leading: false })

    componentDidMount = () => {
        this._setHeader()
        this.props.loadPromotions({ status: this.props.promotion.activeTab });
        this.props.setPromotionState({ activePromotion: undefined, activePromotionId: '' });
        this.props.fetchTierOneCategories();
    }

    componentWillUnmount = () => {
        this.props.setSystemState({ header: undefined, headerEndButton: undefined });
        this.props.setPromotionState({ activePromotion: undefined, activePromotionId: '' });
    }

    _onPromotionTextInput = (field: string, e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.setPromotionState({ [field]: e.target.value });
    }

    _onPromotionInput = (field: string, value: DynamicBasicPromotionType) => {
        this.props.setPromotionState({ [field]: value });
    }

    _onPromotionClick = (id: string) => {
        const promotion = find(this.props.promotion.promotions, { id })

        this.props.setPromotionState({
            activePromotionId: id,
            activePromotion: { ...promotion, products: promotion!.products, subtitle: promotion!.subTitle } as typeof promotion,
            selectedTierOneCategory: '',
            selectedTierTwoCategory: '',
            selectedTierThreeCategory: ''
        });
    }

    _onCancelClick = () => {
        this._onPromotionInput('activePromotionId', '');
        this._onPromotionInput('activePromotion', undefined);
        this._setHeader()
    }

    _onActivePromotionInput = (field: string, value: DynamicBasicPromotionType) => {
        if (field === 'media') {
            this._onMediaInput(value as React.ChangeEvent<HTMLInputElement>)
        }
        else {
            const newPromotion = { ...this.props.promotion.activePromotion, [field]: value } as PromotionType;
            this.props.setPromotionState({ activePromotion: newPromotion });
        }
    }

    _onPromotionSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.search(e.target.value)
    }

    _onMediaInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            this.props.uploadPromotionMedia(e.target.files[0])
        }
    }

    _onAddPromotionClick = () => {
        this.props.setPromotionState({
            activePromotion: {
                id: '',
                title: '',
                subTitle: '',
                category: '',
                description: '',
                products: [],
                startDate: moment().format("DD/MM/YYYY"),
                endDate: moment().format("DD/MM/YYYY"),
                isActive: true,
                pdf: [] as Array<BasicPromotionMedia>,
                images: [] as Array<BasicPromotionMedia>,
                videos: [] as Array<BasicPromotionMedia>,
                brochures: [] as Array<BasicPromotionMedia>
            } as PromotionType,
            activePromotionId: v4()
        });
    }

    _onPromotionSave = () => {
        if (this._validatePromotion()) {
            const { activePromotion } = this.props.promotion;
            if (activePromotion) {
                let mediaUploading = false;
                for (const image of activePromotion!.images) {
                    if (image.loading) mediaUploading = true;
                }
                for (const video of activePromotion!.videos) {
                    if (video.loading) mediaUploading = true;
                }
                for (const pdf of activePromotion!.pdf) {
                    if (pdf.loading) mediaUploading = true;
                }

                if (!mediaUploading) {
                    this.props.setSystemState({
                        systemDialogOpen: true,
                        systemDialogMaxWidth: 'xs',
                        systemDialogTitle: 'Confirm Save',
                        systemDialogContent: 'Please note that any changes are permanent. To continue, please click the save button.',
                        systemDialogSimple: true,
                        systemDialogConfirm: false,
                        systemConfirmOnly: false,
                        systemDialogConfirmAction: () => {
                            this.props.savePromotion()
                            this.props.resetSystemDialog();
                            this._setHeader();
                        }
                    });
                }
                else {
                    toastWarning("Cannot save when a media is still uploading")
                }
            }
        }
    }

    _onPromotionDelete = (newsId: string) => {
        this.props.setSystemState({
            systemDialogOpen: true,
            systemDialogMaxWidth: 'xs',
            systemDialogTitle: 'Confirm Delete',
            systemDialogContent: 'This will delete the promotion. Please click the delete button to proceed',
            systemDialogSimple: true,
            systemDialogConfirm: true,
            systemOverrideTitle: 'Delete',
            systemDialogConfirmAction: () => {
                // do delete
                this.props.deletePromotion(newsId);
                this.props.resetSystemDialog();
                this._setHeader();
            }
        });
    }

    _validatePromotion = (): boolean => {
        const { activePromotion } = this.props.promotion;
        if (activePromotion) {
            if (!activePromotion.title) { toastWarning("Missing promotion title"); return false; }
            if (!activePromotion.subTitle) { toastWarning("Missing promotion subtitle"); return false; }
            if (!activePromotion.description) { toastWarning("Missing promotion description"); return false; }
            if (!Boolean(activePromotion!.products.length)) { toastWarning("Please select atleast 1 product to promote"); return false; }
            if (!Boolean(activePromotion!.brochures.length ||
                activePromotion!.images.length ||
                activePromotion!.pdf.length)) { toastWarning("Please upload atleast 1 media"); return false; }
            const existing = find(this.props.promotion.promotions, { title: activePromotion.title });
            if (existing && activePromotion.id !== existing.id) {
                toastWarning("Promotion already exist");
                return false;
            }
            if (activePromotion!.isActive) {
                if (moment(moment.utc(moment(activePromotion!.endDate, "DD/MM/YYYY").format()))
                    .diff(moment.utc(moment(activePromotion!.startDate, "DD/MM/YYYY").format()), 'days') < 0) {
                    toastWarning("End date should not be before launch date")
                    return false
                }
            }
        }
        return true;
    }

    _onMediaPreviewClick = (media: MediaInputType) => {
        this.props.setPromotionState({
            mediaToPreview: media as BasicPromotionMedia,
            mediaPreviewVisible: true
        })
    }

    _onChangeTab = debounce((tab: string) => {
        this.props.setPromotionState({ activeTab: tab })

        this.props.loadPromotions({ status: tab });
        this.props.setPromotionState({ activePromotion: undefined, activePromotionId: '' });
        this.props.fetchTierOneCategories();
    }, 500, { leading: false })

    _setHeader = () => {
        this.props.setSystemState({
            header: "Product Promotion",
            headerEndButton: () => (
                <div>
                    <SymphonyHeaderButton
                        id="add-promotion-btn"
                        startIcon={<AddIcon />}
                        onClick={this._onAddPromotionClick.bind(this)}
                    >
                        Add New
                    </SymphonyHeaderButton>
                </div>
            ),
            shallRedirect: false,
            redirectTo: ''
        });
    }

    render() {
        const { activePromotionId, promotions, activePromotion, activePromotionLoading, promotionListLoading, activeTab } = this.props.promotion;
        return (
            <BasicLayout>
                {
                    !activePromotionId ? <SymphonyTabsContainer>
                        <SymphonyTabs
                            value={activeTab}
                            TabIndicatorProps={{ style: { height: 4, backgroundColor: SYMPHONY_PRIMARY_COLOR } }}
                        >
                            <SymphonyTab
                                label="All Active"
                                value="active"
                                onClick={() => this._onChangeTab('active')}
                            />
                            <SymphonyTab
                                label="On Going"
                                value="on-going"
                                onClick={() => this._onChangeTab('on-going')}
                            />
                            <SymphonyTab
                                label="Coming Soon"
                                value="coming-soon"
                                onClick={() => this._onChangeTab('coming-soon')}
                            />
                            <SymphonyTab
                                label="Inactive"
                                value="inactive"
                                onClick={() => this._onChangeTab('inactive')}
                            />
                        </SymphonyTabs>
                        <SymphonyField
                            id="promotion-search-fld"
                            style={{ marginBottom: 8, width: 300 }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">
                                    <Search htmlColor={SYMPHONY_PRIMARY_COLOR} />
                                </InputAdornment>
                            }}
                            onChange={this._onPromotionSearch.bind(this)}
                            placeholder="Search"
                        />
                    </SymphonyTabsContainer> : <></>
                }
                {
                    !activePromotionId ? <SymphonyContentContainer>
                        {
                            promotionListLoading ? <SymphonyContentLoading /> : <PromotionList
                                promotions={promotions}
                                activePromotionId={activePromotionId}
                                loading={promotionListLoading}
                                promotionSearch={this.props.promotion.promotionSearch}
                                onPromotionSearch={this._onPromotionSearch}
                                onPromotionClick={this._onPromotionClick.bind(this)}
                                onAddPromotionClick={this._onAddPromotionClick.bind(this)}
                                onDeletePromotionClick={this._onPromotionDelete.bind(this)}
                            />
                        }
                    </SymphonyContentContainer> : <></>
                }
                <Grid item>
                    <PromotionView
                        promotion={activePromotion}
                        loading={activePromotionLoading}
                        onPromotionInput={this._onActivePromotionInput.bind(this)}
                        promotionSaving={this.props.promotion.activePromotionLoading}
                        onCancelClick={this._onCancelClick}
                        onSaveClick={this._onPromotionSave.bind(this)}
                        onMediaClick={this._onMediaPreviewClick.bind(this)}
                        onDeletePromotionClick={this._onPromotionDelete.bind(this)}
                        fetchTierTwoCategories={this.props.fetchTierTwoCategories.bind(this)}
                        fetchTierThreeCategories={this.props.fetchTierThreeCategories.bind(this)}
                        tierOneCategories={this.props.promotion.tierOneCategories}
                        tierTwoCategories={this.props.promotion.tierTwoCategories}
                        tierThreeCategories={this.props.promotion.tierThreeCategories}
                        promotionInputState={this.props.setPromotionState.bind(this)}
                        selectedTierOne={this.props.promotion.selectedTierOneCategory}
                        selectedTierTwo={this.props.promotion.selectedTierTwoCategory}
                        selectedTierThree={this.props.promotion.selectedTierThreeCategory}
                    />
                    <BasicMediaPreview
                        visible={this.props.promotion.mediaPreviewVisible}
                        media={this.props.promotion.mediaToPreview}
                        handleClose={() => this.props.setPromotionState({ mediaPreviewVisible: false })}
                    />
                </Grid>
            </BasicLayout>
        )
    }
}

const mapStateToProps = (state: AppState) => ({
    promotion: state.promotion
});

export default connect(mapStateToProps, {
    setPromotionState,
    loadPromotions,
    uploadPromotionMedia,
    savePromotion,
    deletePromotion,
    setSystemState,
    resetSystemDialog,
    fetchTierOneCategories,
    fetchTierTwoCategories,
    fetchTierThreeCategories
})(Promotion);