import React from 'react';
import ReportContainer from './ReportContainer';
import TopSellingReport from './TopSellingReport';
import TotalSalesReport from './TotalSalesReport';
import TopProductRatingsReport from './TopRatedProductReports';
const SummaryReportView = () => {
    return <ReportContainer  style={{
        display: 'flex',
        flexDirection:'column',
    }}>
        <TotalSalesReport />
        <TopSellingReport />
        <TopProductRatingsReport/>
    </ReportContainer>;
};

export default SummaryReportView;
