import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import AMITableRow from './AMITableRow';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import Typography from '@material-ui/core/Typography';
import { AMIDataCell } from './AMITableCell';
import type { TableColumn } from './table-types';
import useTableContainerWidth from './useTableContainerWidth';

interface AMITableBodyProps<T> {
  data: T[];
  columns: TableColumn<T>[];
  emptyText?: string;
  loading?: boolean;
  onRowClick?: (rowItem: T) => void;
  withHover?: boolean;
}

const AMITableBody = <T extends { id: string }>({
  data,
  columns,
  emptyText = 'No available data.',
  loading,
  onRowClick,
  withHover,
}: AMITableBodyProps<T>) => {
  const width = useTableContainerWidth('ami-table');
  // create row cell key
  function createRowCellKey(rowItem: any, column: any) {
    return `${rowItem.id}-${column.id}`;
  }

  // cell content renderer
  function renderCellContent(rowItem: any, rowIndex: number, column: any) {
    if (column.content) return column.content(rowItem, rowIndex);
    return rowItem[column.property];
  }

  return (
    <TableBody style={{ position: 'relative' }} id="ami-table">
      {loading && (
        <AMITableRow>
          <TableCell style={{ border: 'none', position: 'relative' }}>
            <Backdrop
              open={Boolean(loading)}
              style={{
                backgroundColor: 'rgba(255,255,255,0.1)',
                width,
                height: 300,
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: 1200,
              }}>
              <CircularProgress color="primary" />
            </Backdrop>
          </TableCell>
        </AMITableRow>
      )}
      {data.length === 0 && !loading && (
        <AMITableRow>
          <TableCell
            colSpan={columns.length}
            style={{
              backgroundColor: 'white',
              padding: '20px 10px',
              textAlign: 'center',
            }}>
            <Typography style={{ color: 'GrayText', fontSize: 18 }}>
              {emptyText}
            </Typography>
          </TableCell>
        </AMITableRow>
      )}
      {data &&
        !loading &&
        data.map((item, index) => (
          <AMITableRow
            key={item.id}
            className={withHover ? 'with-hover' : ''}
            onClick={() => {
              if (onRowClick) {
                onRowClick(item);
              }
            }}>
            {columns.map((column) => (
              <AMIDataCell
                align={column.align ? column.align : 'center'}
                key={createRowCellKey(item, column)}>
                {renderCellContent(item, index, column)}
              </AMIDataCell>
            ))}
          </AMITableRow>
        ))}
    </TableBody>
  );
};

export default AMITableBody;
