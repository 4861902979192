import TableRow from '@material-ui/core/TableRow';
import { styled } from '@material-ui/core/styles';

const AMITableRow = styled(TableRow)(({ theme }) => ({
  '&': {
    backgroundColor: theme.palette.common.white,

    '&.with-hover:hover': {
      backgroundColor: '#F4F6F9',
      cursor: 'pointer',
    },
  },
  // hide last border
  // '&:last-child td, &:last-child th': {
  //   border: 0
  // }
}));

export default AMITableRow;
