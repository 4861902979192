import React, { useEffect } from 'react';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartData,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';

import { dataColors, manageOrderChartOptions } from './util/chart-utils';
import { useDispatch, useSelector } from 'react-redux';
import {
    getManageOrderReport,
    selectManageOrderReportData,
    selectManageOrderState,
    setManageOrderFilter,
} from '../../../store/marketreport/manageOrderReducer';
import ChartContainer from './ChartContainer';
import ReportFilterGroup from './ReportFilterGroup';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
);

const { blue, red, brown, teal, orange, green, lightGray } = dataColors;

const xLabels = [
    'Unpaid',
    'Paid',
    'Ready to Ship',
    'Pending',
    'Shipped',
    'Delivered',
];

const ManageOrderReport = () => {
    const { filter, error, stateStatus } = useSelector(selectManageOrderState);
    const reportData = useSelector(selectManageOrderReportData);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getManageOrderReport(filter));
    }, [dispatch, filter]);

    const dataToDisplay: ChartData = {
        labels: xLabels,
        datasets: [
            {
                label: 'Manage Orders Report',
                data: reportData.chartData,
                backgroundColor: [blue, red, brown, teal, orange, green],
            },
            {
                label: 'Total Orders',
                data: Array(7).fill(reportData.total), // temporary
                backgroundColor: lightGray,
                xAxisID: 'total-x-axis',
            },
        ],
    };

    const handleFilterChange = (key: keyof typeof filter, value: string) => {
        dispatch(
            setManageOrderFilter({
                ...filter,
                [key]: value,
            }),
        );
    };

    return (
        <ChartContainer
            title="Manage Order"
            loading={stateStatus === 'loading'}
            error={error}
            noData={reportData.total === 0}
            controls={
                <ReportFilterGroup
                    filter={filter}
                    onFilterChange={handleFilterChange}
                />
            }>
            <Bar
                type="bar"
                options={manageOrderChartOptions(reportData.chartData)}
                data={dataToDisplay}
            />
        </ChartContainer>
    );
};

export default ManageOrderReport;
