import map from "lodash/map";
import React, { useEffect, useState } from "react";
import { News } from '../../../../store/news/types';
import { Link } from 'react-router-dom';
import { connect, useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../store";
import { ClampedText, NewsListContainer, NewsListRow, NewsListRowImageContainer } from "./NewsComponents";
import { Avatar, Box, Chip, Grid, Typography } from "@material-ui/core";

// default svg
import { ReactComponent as DefaultPic } from '../../../Basic/Common/BasicDefaultSvg.svg';
import moment from "moment";
import { setSystemState } from "../../../../store/system/actions";
import { SymphonyHeaderButton } from "../../../symphony/SymphonyCommonComponents";

interface PromtionListProps {
    news: Array<News>;
    loading: boolean;
    activeNewsId: string;
    onNewsClick: (id: string) => void;
    onNewsSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onAddNewsClick: () => void;
    onDeleteNewsClick: (newsId: string) => void;
}

const NewsList = (props: PromtionListProps) => {
    const { news, activeTab } = useSelector((state: AppState) => state.news);
    const [chipStyle, setChip] = useState({ background: '', color: '' })

    useEffect(() => {
        switch (activeTab) {
            case 'active':
                setChip({ background: '#C1F7D7', color: '#00AD4C' })
                break;
            case 'scheduled':
                setChip({ background: '#DEE9FF', color: '#4C89F5' })
                break
            default:
                break;
        }
    }, [activeTab])

    return (
        <NewsListContainer>
            {
                map(news, row => {
                    return (
                        <Link
                            id={`news-${row.id}`}
                            key={row.id}
                            to={`/sales/news`}
                            style={{ display: 'flex', textDecoration: 'none', color: 'unset' }}
                            onClick={() => props.onNewsClick(row.id)}
                        >

                            <NewsListRow>
                                <Grid container={true} justify='center' alignItems="center">
                                    <Grid item={true} xs={6} >
                                        <Box display="inline-flex" alignItems="center">
                                            <NewsListRowImageContainer>
                                                {!!row.images?.length ?
                                                    <Avatar variant="square" src={row.images[0].path} style={{ height: 45, width: 45, }}>
                                                        N
                                                    </Avatar>
                                                    :
                                                    <DefaultPic style={{ width: 45, height: 45, marginLeft: 4, }} />
                                                }
                                            </NewsListRowImageContainer>
                                            <Box marginLeft="8px">
                                                <ClampedText text={row.title} />
                                                <Box color="#A2A2A2" fontSize={13}>
                                                    {row.startDate}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item={true} xs={2} color="#A2A2A2">
                                        <Chip label={activeTab === 'active'  ? 'Published' : activeTab}
                                            style={{
                                                textTransform: 'uppercase', fontSize: '10px', padding: '0px 10px', fontWeight: 'bold',
                                                ...chipStyle
                                            }} size='small' />
                                    </Grid>
                                    <Grid item={true} xs={4}>
                                        <div style={{ display: 'inline', paddingRight: '5px', fontSize: '13px' }}>Date Created:</div>
                                        <Typography style={{ color: "#A2A2A2", display: 'inline', fontSize: '13px' }}>{moment(row.dateCreated).format("MMM-DD-YY")}</Typography>
                                    </Grid>
                                </Grid>
                            </NewsListRow>
                        </Link>
                    )
                })
            }
        </NewsListContainer>
    )
}

export default NewsList;