import { useMutation, useQuery } from 'react-query';
import { toastSuccess } from '../../../../modules/Toast';

import { useLoggedInUser } from '../../../../utils/hooks';
import {
  getReturnRefundData,
  updateReturnRefundStatus,
} from './return-refund-service';

export function useReturnOrdersQuery() {
  const user = useLoggedInUser();

  const key = user.id + '-return';

  return useQuery(key, () => getReturnRefundData(user.id, 'RETURN'));
}

export function useRefundOrdersQuery() {
  const user = useLoggedInUser();

  const key = user.id + '-refund';

  return useQuery(key, () => getReturnRefundData(user.id, 'REFUND'));
}

type UpdateArgs = {
  orderId: string;
  status: string;
};

export function useUpdateOrderStatus() {
  return useMutation(
    ({ orderId, status }: UpdateArgs) =>
      updateReturnRefundStatus(orderId, status),
    {
      onSuccess(data, variables, context) {
        toastSuccess('Status updated!');
      },
    },
  );
}
