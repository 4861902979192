import React from 'react';
import { Link } from 'react-router-dom';
import { GenericMedia, SortOrder } from '../../../../store/system/types';
import { MarketProduct } from '../../../../store/marketproduct/types';
import { 
    ProductListContainer,
    ProductRowContainer,
    ProductGrid,
    ProductImageContainer,
    ProductHeaderItem,
    ProductHeaderGridContainer,
    ProductHeaderGrid
} from './ProductComponents';
import SymphonyImage from '../../../symphony/SymphonyImage';

// material
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

// util
import map from 'lodash/map';
import SymphonyContentLoading from '../../../symphony/SymphonyContentLoading';
import { SymphonyContentLoadingContainer } from '../../../symphony/SymphonyCommonComponents';
import { connect, useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../store';
import { setMarketProductState } from '../../../../store/marketproduct/actions';

interface ProductCardListProps {
    products: Array<MarketProduct>;
    activeSort: string;
    activeSortOrder: SortOrder;
    onSortClick: (sort: string, order: SortOrder) => void;
    loading: boolean;
    setMarketProductState: typeof setMarketProductState;
}

const ProductCardList = ({ products, activeSort, activeSortOrder, onSortClick, loading, setMarketProductState }: ProductCardListProps) => {
    const dispatch = useDispatch();
    const { selectedMarketProduct, removeCheckedValue, uncheckAll } = useSelector((state: AppState) => state.marketproduct);
    const [array, setArray] = React.useState<Array<Partial<{ id: string }>>>(selectedMarketProduct!)
    
    const RenderSortHeader = ({ headerTitle, headerValue }: { headerTitle: string, headerValue: string }) => {
        return (
            <ProductHeaderItem onClick={() => onSortClick(headerValue, !activeSortOrder ||  headerValue !== activeSort ? 'ASC' : activeSortOrder === 'ASC'? 'DESC' : '')  }>
                {headerTitle}
                {!!headerValue &&
                    <>
                        {activeSort ?
                            <>
                                {(activeSort === headerValue) &&
                                    <>
                                        {activeSortOrder === 'ASC' ? 
                                            <KeyboardArrowDownIcon />
                                        :
                                            <KeyboardArrowUpIcon />
                                        }
                                    </>
                                }
                            </>
                        :
                            <KeyboardArrowDownIcon />
                        }
                    </>
                }
            </ProductHeaderItem>
        )
    }

    const RenderHeader = ({ headerTitle, headerValue }: { headerTitle: string, headerValue: string }) => {
        return (
            <ProductHeaderItem>
                {headerTitle}
            </ProductHeaderItem>
        )
    }

    React.useEffect(() => {
        if (!removeCheckedValue) {
            dispatch(setMarketProductState({ selectedMarketProduct: array }));
        } else {
            setArray(selectedMarketProduct!)
        }
    }, [array, dispatch, selectedMarketProduct, removeCheckedValue])


    const handleUncheckAll = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setMarketProductState({ uncheckAll: e.target.checked })
        if (e.target.checked) {
            map(products, (v) => {
                const chkbx = (document.getElementById(`selected-${v.id}`) as HTMLInputElement)
                if (chkbx) chkbx.checked = true
                return v
            })
            setArray([...products as Array<{ id: string }>])
        } else {
            map(products, (v) => {
                if (v) (document.getElementById(`selected-${v.id}`) as HTMLInputElement)!.checked = false
            })
            setArray([])
        }
        console.log(array)
    }, [array])

    const removeItem = (arr, index) => {
        if (index > -1) {
            arr.splice(index, 1);
        }
        return arr;
    }

    const handleVendorCheckboxChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>, product: MarketProduct) => {
        const index = array.findIndex(x => x.id === product.id);
        if (e.target.checked) {
            if (!(index > -1)) {
                setMarketProductState({ removeCheckedValue: false })
                setArray(a => [...a, product])
            }
        } else {
            setMarketProductState({ uncheckAll: e.target.checked })
            setArray(a => [...removeItem(a, index)])
        }
    }, [array])

    return (
        <ProductListContainer>
            <ProductHeaderGridContainer container={true}>
                <ProductHeaderGrid item={true} xs={6}>
                <input type='checkbox'
                        checked={uncheckAll}
                        style={{ marginRight: '15px', marginLeft: 0, cursor: 'pointer' }}
                        onChange={handleUncheckAll} />
                    <RenderSortHeader
                        headerTitle="Product Name"
                        headerValue="name"
                    />
                </ProductHeaderGrid>
                <ProductHeaderGrid item={true} xs={3}>
                    <RenderSortHeader
                        headerTitle="Code"
                        headerValue="displayId"
                    />
                </ProductHeaderGrid>
                <ProductHeaderGrid item={true} xs={3}>
                    <RenderHeader
                        headerTitle="Hierarchy"
                        headerValue=""
                    />
                </ProductHeaderGrid>
            </ProductHeaderGridContainer>
            {loading ? <SymphonyContentLoading overrideHeight="calc(100vh - 314px)!important" /> :
                <>
                    {products.length === 0 &&
                        <SymphonyContentLoadingContainer height="calc(100vh - 314px)!important">
                            No Product Found
                        </SymphonyContentLoadingContainer>
                    }
                    {map(products, (product, index) => {
                        const im: GenericMedia = product.image as GenericMedia;
                        const imageUndefined = typeof im === 'undefined';
                        return (
                            <div style={{ display: 'flex', alignItems: 'center' }} key={product.id}>
                            <input
                                type='checkbox'
                                id={`selected-${product.id}`}
                                style={{ marginLeft: '12px', cursor: 'pointer' }}
                                onChange={(e) => handleVendorCheckboxChange(e, product)} />
                            <Link 
                                id={`product-view-${product.id}`}
                                key={product.id} 
                                to={`/market/product/${product.id}`} 
                                style={{ display: 'flex', textDecoration: 'none', color: 'unset', width: '100%' }}
                            > 
                                <ProductRowContainer>
                                    <Grid container={true}>
                                        <ProductGrid item={true} xs={6}>
                                            <Box display="flex" alignItems="center">
                                                <ProductImageContainer>
                                                    <SymphonyImage 
                                                        src={imageUndefined ? '' : im.path} 
                                                        style={{ width: 50, height: 'auto', maxHeight: 60 }}
                                                    />
                                                </ProductImageContainer>
                                                <Box display="flex" flexDirection="column" marginLeft="4px">
                                                    {product.name}
                                                    <Box color="#A2A2A2">
                                                        {product.varietiesCount || '1'} {product.varietiesCount && product.varietiesCount > 1 ? 'Varieties' : 'Variety'}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </ProductGrid>
                                        <ProductGrid item={true} xs={3} color="#A2A2A2">
                                            <Box display="inline">
                                                <Box color="#A2A2A2" display="inline">
                                                    {product.displayId || ''}
                                                </Box>
                                            </Box>
                                        </ProductGrid>
                                        <ProductGrid item={true} xs={3}>
                                            <Box display="inline">
                                                <Box className="word-break-all" display="inline" color="#A2A2A2">{`${product.h1}${product.h2 ? `/${product.h2}` : ''}${product.h3 ? `/${product.h3}` : ''}`}</Box>
                                            </Box>
                                        </ProductGrid>
                                    </Grid>
                                </ProductRowContainer>
                            </Link>
                            </div>
                        )
                    })}
                </>
            }

            {/* Cards here */}
            
        </ProductListContainer>
    )
}

const mapStateToProps = (state: AppState) => ({});

export default connect(mapStateToProps, {
    setMarketProductState
})(ProductCardList);