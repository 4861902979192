
export type Status = 'idle' | 'loading' | 'success' | 'error';

export interface MarketTotalSalesReportItem<T> {
    // id: string;
    // status: T;
    totalSales:number,
    difference:number,
    differencePercentage:number,
    monthlySales:MonthSales[],
}

export interface MarketTotalSalesReportState<S> {
    stateStatus: Status;
    error?: string | null;
    data: MarketTotalSalesReportItem<S>;
    filter: {
        startMonth: string;
        endMonth: string;
        year: string;
    };
}

export interface MonthSales {
    month:string,
    total:number
}

export interface TotalSalesData  {
    totalSales:number,
    difference:number,
    differencePercentage:number,
    monthlySales:MonthSales[],


}

export type TotalSalesReportState = MarketTotalSalesReportState<Status>;

//Manage Total Sales
export const LOAD_TOTAL_SALES_REPORT = 'total-sales/data/load';
export const SET_TOTAL_SALES_STATUS = 'total-sales/status/set';
export const SET_TOTAL_SALES_FILTER = 'total-sales/filter/load';
export const SET_TOTAL_SALES_ERROR = 'total-sales/error/set';

interface LoadTotalSalesAction {
    type: typeof LOAD_TOTAL_SALES_REPORT;
    payload: TotalSalesReportState['data'];
};

interface SetTotalSalesStatusAction {
    type: typeof SET_TOTAL_SALES_STATUS;
    payload: TotalSalesReportState['stateStatus'];
};

interface SetTotalSalesFilterAction {
    type: typeof SET_TOTAL_SALES_FILTER;
    payload: TotalSalesReportState['filter'];
};

interface SetTotalSalesErrorAction {
    type: typeof SET_TOTAL_SALES_ERROR;
    payload: TotalSalesReportState['error'];
};

export type TotalSalesAction = 
    | LoadTotalSalesAction
    | SetTotalSalesStatusAction
    | SetTotalSalesFilterAction
    | SetTotalSalesErrorAction;