import axios, { AxiosError } from 'axios';
import moment from 'moment';
import { AppState, AppThunk } from '../index';
import {
    OrderHistoryAction,
    OrderHistoryReportState,
    OrderHistoryStatus,
    LOAD_ORDER_HISTORY_REPORT,
    SET_ORDER_HISTORY_ERROR,
    SET_ORDER_HISTORY_FILTER,
    SET_ORDER_HISTORY_STATUS,
    Status,
} from './types';

const API_URL = process.env.REACT_APP_API_URL;

const orderHistoryInitState: OrderHistoryReportState = {
    stateStatus: 'idle',
    error: null,
    data: [],
    filter: {
        startMonth: moment().format('MMM'),
        endMonth: moment().format('MMM'),
        year: moment().format('YYYY'),
    },
};

export function orderHistoryReportReducer(
    state = orderHistoryInitState,
    action: OrderHistoryAction,
): OrderHistoryReportState {
    switch (action.type) {
        case SET_ORDER_HISTORY_STATUS:
            return {
                ...state,
                stateStatus: action.payload,
            };
        case LOAD_ORDER_HISTORY_REPORT:
            return {
                ...state,
                data: action.payload,
                error: null,
                stateStatus: 'idle',
            };
        case SET_ORDER_HISTORY_FILTER:
            return {
                ...state,
                filter: action.payload,
            };
        case SET_ORDER_HISTORY_ERROR:
            return {
                ...state,
                error: action.payload,
                stateStatus: 'idle',
            };
        default:
            return state;
    }
}

// Sync Action
export const setOrderHistoryStatus = (status: Status): OrderHistoryAction => {
    return {
        type: SET_ORDER_HISTORY_STATUS,
        payload: status,
    };
};

export const setOrderHistoryError = (
    error: string | null,
): OrderHistoryAction => {
    return {
        type: SET_ORDER_HISTORY_ERROR,
        payload: error,
    };
};

export const loadOrderHistoryData = (
    reportData: OrderHistoryReportState['data'],
): OrderHistoryAction => {
    return {
        type: LOAD_ORDER_HISTORY_REPORT,
        payload: reportData,
    };
};

export const setOrderHistoryFilter = (
    reportFilter: OrderHistoryReportState['filter'],
): OrderHistoryAction => {
    return {
        type: SET_ORDER_HISTORY_FILTER,
        payload: reportFilter,
    };
};

// Async Actions
export const getOrderHistoryReport =
    (
        filter: OrderHistoryReportState['filter'],
        callback?: () => void,
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(setOrderHistoryStatus('loading'));

            const { login } = getState();

            const { id: vendorId, countryCode } = login.user;

            const startMonth = moment().month(filter.startMonth).format('MM');
            const endMonth = moment().month(filter.endMonth).format('MM');
            const year = filter.year;

            const response = await axios.get<OrderHistoryReportState['data']>(
                `${API_URL}/v1/reports/market/order-history?vendorId=${vendorId}&countryCode=${countryCode}&startMonth=${startMonth}&endMonth=${endMonth}&year=${year}`,
            );

            dispatch(loadOrderHistoryData(response.data));

            dispatch(setOrderHistoryStatus('success'));

            if (callback) {
                callback();
            }
        } catch (error) {
            dispatch(setOrderHistoryStatus('error'));

            let err = 'An unknown error has occured';

            if (error instanceof Error) {
                err = error.message;
            }

            if ((error as any)?.response?.data) {
                err = (error as AxiosError).response?.data?.error?.message;
            }

            dispatch(setOrderHistoryError(err));
        }
    };

// Selectors
export const selectOrderHistoryFilter = (state: AppState) =>
    state.orderHistoryReport.filter;

export const selectOrderHistoryState = (state: AppState) =>
    state.orderHistoryReport;

export const selectOrderHistoryReportData = (state: AppState) => {
    const reportData = state.orderHistoryReport.data;

    const dataToDisplay: Record<OrderHistoryStatus | 'TOTAL', number> = {
        TOTAL: reportData.length,
        RECEIVED: reportData.filter((d) => d.status === 'RECEIVED').length,
        CANCELLED: reportData.filter((d) => d.status === 'CANCELLED').length,
        FAILED_DELIVERY: reportData.filter(
            (d) => d.status === 'FAILED_DELIVERY',
        ).length,
    };

    const { TOTAL, RECEIVED, CANCELLED, FAILED_DELIVERY } = dataToDisplay;

    return {
        total: TOTAL,
        chartData: [RECEIVED, CANCELLED, FAILED_DELIVERY],
        received: RECEIVED,
        cancelled: CANCELLED,
        failed: FAILED_DELIVERY,
    };
};
