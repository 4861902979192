import { Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import BrandButton from "../../../Common/BrandButton";
import { ReviewsCard, ReviewsCardHeader, ReviewsContainer } from "./ReviewComponents";
import ReviewModal from "./ReviewModal";
import StarRating from "./StarRating";

interface IReview {
    id: string;
    rating: number;
    orderNumber: string;
    orderDate: string;
    customerName: string;
    message: string;
    images: Array<{ path: string; }>
}

interface IProps {
    reviews: Array<IReview>;
}

const Reviews = ({ reviews }: IProps) => {
    const ref = useRef<HTMLElement>(null)

    const [show, setShow] = useState(false)
    const [review, setReview] = useState<IReview | null>(null)
    const handleModal = (review: IReview) => {
        setShow(true)
        setReview(review)
    }


    return (
        <ReviewsContainer innerRef={ref}>
            {
                reviews.map(review => (
                    <ReviewsCard key={review.id} onClick={() => handleModal(review)}>
                        <ReviewsCardHeader>
                            <StarRating size='small' rating={review.rating} />
                            <Typography style={{ fontSize: '0.8rem', fontWeight: 600 }}>
                                #{
                                    review.orderNumber
                                }
                            </Typography>
                        </ReviewsCardHeader>
                        <Typography color='textSecondary' style={{ fontSize: '0.8rem' }}>
                            {
                                review.customerName
                            },&nbsp;
                            {
                                review.orderDate
                            }
                        </Typography>
                        <Typography id={review.id} style={{
                            fontSize: '0.8rem', paddingTop: '5px', maxWidth: '100%',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 4,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>
                            {
                                review.message
                            }
                        </Typography>
                    </ReviewsCard>
                ))
            }

            <ReviewModal show={show} toggle={setShow} data={review}/>
        </ReviewsContainer>
    )
}

export default Reviews;