import React from 'react';
import { ToastContainer } from 'react-toastify';

import Box from '@material-ui/core/Box';

import { AppModuleContainer } from '../../lib/AppModuleContainer';
import { AppTab, AppTabList } from '../../lib/AppTab';

import { useHistory, useLocation } from 'react-router';
import LeadsTable from './LeadsTable';

function LeadsListing() {
  const history = useHistory();
  const { search } = useLocation();

  const activeTab =
    new URLSearchParams(search).get('active') === 'false' ? 1 : 0;

  return (
    <AppModuleContainer>
      <AppTabList
        className="tab-thin"
        aria-label="leads management module tabs"
        value={activeTab}>
        <AppTab
          className="tab-thin"
          label="Active"
          onClick={() => history.push('/sales/leads?active=true')}
        />
        <AppTab
          className="tab-thin"
          label="Inactive"
          onClick={() => history.push('/sales/leads?active=false')}
        />
      </AppTabList>
      <Box p={3}>
        <LeadsTable />
      </Box>
      <ToastContainer toastStyle={{ borderRadius: 3, padding: '10px 15px' }} />
    </AppModuleContainer>
  );
}

export default LeadsListing;
