import React from 'react';
import { ToastContainer } from 'react-toastify';

import Box from '@material-ui/core/Box';

import { AppModuleContainer } from '../../lib/AppModuleContainer';
import { AppTab, AppTabList } from '../../lib/AppTab';
import RenderIf from '../../lib/RenderIf';

import RefundView from './ReturnAndRefund/RefundView';
import ReturnView from './ReturnAndRefund/ReturnView';
import { useHistory, useLocation } from 'react-router';

function ApprovalModule() {
  const history = useHistory();
  const { search } = useLocation();

  const activeTab = new URLSearchParams(search).get('tab') === 'refund' ? 1 : 0;

  return (
    <AppModuleContainer>
      <AppTabList aria-label="reports module tabs" value={activeTab}>
        <AppTab
          label="Return"
          onClick={() =>
            history.push('/market/approval/return-and-refund?tab=return')
          }
        />
        <AppTab
          label="Refund"
          onClick={() =>
            history.push('/market/approval/return-and-refund?tab=refund')
          }
        />
      </AppTabList>
      <Box p={3}>
        <RenderIf condition={activeTab === 0}>
          <ReturnView />
        </RenderIf>
        <RenderIf condition={activeTab === 1}>
          <RefundView />
        </RenderIf>
      </Box>
      <ToastContainer toastStyle={{ borderRadius: 3, padding: '10px 15px' }} />
    </AppModuleContainer>
  );
}

export default ApprovalModule;
