import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import { appColors } from '../../../lib/constants';
import { ReturnRefundItem } from './types';
import { formatHistoryDate, formatHistoryTime } from '../../../lib/helpers';
import OrderStatusSelect from './OrderStatusSelect';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: 24,
      fontWeight: 600,
      marginBottom: 8,
    },
    textField: {
      backgroundColor: appColors.bluishWhite,
    },
    label: {
      color: appColors.mediumGray,
      fontSize: 15,
    },
    image: {
      width: 200,
      height: 200,
      objectFit: 'contain',
    },
  }),
);

function OrderInformation({
  returnRefundItem,
  onStatusUpdate,
}: {
  returnRefundItem: ReturnRefundItem;
  onStatusUpdate: (newStatus: string) => void;
}) {
  const classes = useStyles();

  const {
    requestDate,
    history,
    items: productItems,
    customerName,
    customerNumber,
    status,
  } = returnRefundItem;

  const { refundAmount, reason, description } = history[history.length - 1];

  return (
    <>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Box>
          <Typography className={classes.title}>Order Information</Typography>
          <Typography className={classes.label}>
            Placed on {formatHistoryDate(requestDate)} at{' '}
            {formatHistoryTime(requestDate)}
          </Typography>
        </Box>
        <OrderStatusSelect onChange={onStatusUpdate} currentStatus={status} />
      </Box>
      <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gridGap={24}>
        <Box gridColumn="1 / 3">
          <Typography className={classes.label}>Customer Name</Typography>
          <TextField
            className={classes.textField}
            variant="outlined"
            fullWidth
            size="small"
            disabled
            defaultValue={customerName}
          />
        </Box>
        <Box>
          <Typography className={classes.label}>Mobile Number</Typography>
          <TextField
            className={classes.textField}
            variant="outlined"
            fullWidth
            size="small"
            disabled
            defaultValue={customerNumber}
          />
        </Box>
        <Box>
          <Typography className={classes.label}>Refund Amount</Typography>
          <TextField
            className={classes.textField}
            variant="outlined"
            fullWidth
            size="small"
            disabled
            defaultValue={refundAmount}
          />
        </Box>
        <Box>
          <Typography className={classes.label}>Reason</Typography>
          <TextField
            className={classes.textField}
            variant="outlined"
            fullWidth
            size="small"
            disabled
            defaultValue={reason}
            multiline
            rows={8}
          />
        </Box>
        <Box>
          <Typography className={classes.label}>Description</Typography>
          <TextField
            className={classes.textField}
            variant="outlined"
            fullWidth
            size="small"
            disabled
            defaultValue={description}
            multiline
            rows={8}
          />
        </Box>
      </Box>
      <Box mt={3}>
        <Typography className={classes.label}>Images</Typography>
        <Box display="flex" style={{ gap: 12 }}>
          {productItems.map((product) => (
            <Box
              key={product.productId}
              bgcolor={appColors.bluishWhite}
              p={2}
              width={200}>
              <img
                className={classes.image}
                loading="lazy"
                src={product.image}
                alt={product.name}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
}

export default OrderInformation;
