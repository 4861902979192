import React from 'react';

import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { appColors } from '../../../lib/constants';
import { extractStatusText, NEGATIVE_STATUSES } from './constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: appColors.mediumGray,
      textTransform: 'capitalize',
      fontSize: 15,
    },
    option: {
      textTransform: 'capitalize',
    },
    select: {
      height: 42,

      '& .MuiSelect-root': {
        padding: '4px 16px',
        width: 130,
      },

      '& svg': {
        color: appColors.primary,
      },

      '&.Mui-focused fieldset': {
        borderColor: appColors.primary,
      },
    },
  }),
);

const mapToOption = (arr: string[]) =>
  arr.map((a) => {
    const label = a.includes('APPROVED')
      ? extractStatusText(a).replace(/Approved/i, 'Approve')
      : a.includes('REJECTED')
      ? extractStatusText(a).replace(/Rejected/i, 'Reject')
      : extractStatusText(a);

    return { value: a, label };
  });

const getSelectOptions = (currentStatus: string) => {
  if (currentStatus === 'REFUND')
    return mapToOption(['REFUND_APPROVED', 'REFUND_REJECTED']);

  if (['REFUND_APPROVED', 'REFUND_REJECTED'].includes(currentStatus))
    return mapToOption(['REFUND_ISSUED', 'REFUND_CUSTOMER_REJECTED']);

  if (currentStatus === 'RETURN')
    return mapToOption(['RETURN_VENDOR_APPROVED', 'RETURN_VENDOR_REJECTED']);

  if (
    ['RETURN_VENDOR_APPROVED', 'RETURN_VENDOR_REJECTED'].includes(currentStatus)
  )
    return mapToOption(['RETURN_READY_TO_SHIP', 'RETURN_REJECTED']);

  if (['RETURN_READY_TO_SHIP', 'RETURN_REJECTED'].includes(currentStatus))
    return mapToOption(['RETURN_SHIPPED', 'RETURN_FAILED_TO_SHIP']);
};

function OrderStatusSelect({
  currentStatus,
  onChange,
}: {
  currentStatus: string;
  onChange: (newStatus: string) => void;
}) {
  const classes = useStyles();

  const options = getSelectOptions(currentStatus);

  const isDisabled = !options || NEGATIVE_STATUSES.includes(currentStatus);

  return (
    <Select
      className={classes.select}
      displayEmpty
      variant="outlined"
      value=""
      IconComponent={ExpandMoreIcon}
      disabled={isDisabled}
      renderValue={(value) => {
        if (!value)
          return (
            <Typography className={classes.label}>
              {extractStatusText(currentStatus)}
            </Typography>
          );

        return value as string;
      }}
      onChange={(e) => onChange(e.target.value as string)}>
      {options?.map((option) => (
        <MenuItem
          key={option.value}
          className={classes.option}
          value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
}

export default OrderStatusSelect;
