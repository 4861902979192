import { Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { styled } from '@material-ui/core/styles';

export const ReviewContainer = withStyles(
    () => ({
        root: {
            backgroundColor: '#fff',
            display: 'flex',
            flexDirection: 'column',
            boxSizing: 'border-box',
            height: '100%',
            paddingLeft: '332px',
            paddingRight: '32px',
            minWidth: "1265px",
        }
    })
)(Box);

export const ReviewEmptyMessage = withStyles(
    () => ({
        root: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50%'
        }
    })
)(Box);

export const ReviewProductContainer = withStyles(
    () => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
        }
    })
)(Box);

export const ReviewProductListContainer = withStyles(
    () => ({
        root: {
            padding: '15px 0',
            minHeight: '100%',
            rowGap: '1em',
            overflow: 'auto',
            height: 'calc(100vh - 300px)'
        }
    })
)(Box);

export const ReviewCompanyHeader = withStyles(
    () => ({
        root: {
            position: 'sticky',
            top: '0px',
            paddingTop: '15px',
            backgroundColor: '#fff',
            display: 'flex',
            justifyContent: 'space-between',
            zIndex: 1,
        }
    })
)(Box);

export const ProductRow = withStyles(
    () => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: 'fit-content',
            width: '100%',
            padding: '10px 0',
            textDecoration: 'none',
            '& a': {
                color: 'black',
                '&:active': {
                    color: 'inherit'
                }
            }
        }
    })
)(Link);

export const ProductCell = withStyles(
    () => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            width: '0px',
            flex: '1 1 0px',
            // '&:not(:nth-last-child)': {
            //     flexBasis: '30%',
            // },
            // '&:nth-last-child': {
            //     flexBasis: '10%'
            // }
        }
    })
)(Box);

export const HeaderText = withStyles(
    () => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
        }
    })
)(Box);

export const ProductTopContainer = withStyles(
    () => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        }
    })
)(Box);

export const ProductStatsContainer = withStyles(
    () => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        }
    })
)(Box);

export const ProductStats = withStyles(
    () => ({
        root: {
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'start',
            flexDirection: 'column',

            '& .title': {
                fontSize: '1.1rem',
                fontWeight: 600,
            },
            '& .subtitle': {
                fontSize: '0.8rem',
            },
            '& .rating': {
                display: 'flex',
                alignItems: 'baseline',

                '&.mt': {
                    marginTop: '20px',
                },
                '& .number': {
                    display: 'inline',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    paddingRight: 3
                },
                '& .out-of': {
                    display: 'inline',
                    fontSize: '0.7rem'
                }
            },
            '& .stars': {
                fontSize: '0.7rem',
            }
        }
    })
)(Box);

export const ProductStarsContainer = withStyles(
    () => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            rowGap: 3
        }
    })
)(Box);

export const ProductReviewContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    rowGap: 3,
    overflowY: 'auto',
    height: '100%'
}));

export const HeaderContainer = withStyles(
    () => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingBottom: '1em',

            '& .title': {
                fontSize: '1.3rem',
                fontWeight: 600
            }
        }
    })
)(Box);

export const ReviewsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    columnGap: '1em',
    rowGap: '1em',
    flexWrap: 'wrap',
    height: 'calc(100vh - 350px)',
    overflowY: 'auto',
    boxSizing: 'border-box'
}));

export const ReviewsCard = withStyles(
    () => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            flex: '0 0 28%',
            border: '1px solid #ededed',
            borderRadius: '3px',
            padding: '1em',
            rowGap: '5px',
            cursor: 'pointer'
        }
    })
)(Box);

export const ReviewsCardHeader = withStyles(
    () => ({
        root: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        }
    })
)(Box);
