import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../store';
import { NewsState, News as NewsType, DynamicBasicNewsType, BasicNewsMedia } from '../../../store/news/types';
import { setNewsState, loadNews, uploadNewsMedia, saveNews, deleteNews } from '../../../store/news/actions';
import { setSystemState, resetSystemDialog } from '../../../store/system/actions';
import { MediaInputType } from '../../../store/system/types';
import BasicLayout from '../Common/BasicLayout';
import NewsList from './fragments/NewsList';
import NewsView from './fragments/NewsView';
import BasicMediaPreview from '../Common/BasicMediaPreview';
import Grid from '@material-ui/core/Grid';
import find from 'lodash/find';
import debounce from 'lodash/debounce';
import { v4 } from 'uuid'
import moment from 'moment';
import { toastWarning } from '../../../modules/Toast';
import { SymphonyField, SymphonyHeaderButton, SymphonyTab, SymphonyTabs, SymphonyTabsContainer } from '../../symphony/SymphonyCommonComponents';
import AddIcon from '@material-ui/icons/Add';
import { InputAdornment } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import { SYMPHONY_PRIMARY_COLOR } from '../../symphony/Colors';
import SymphonyContentLoading from '../../symphony/SymphonyContentLoading';
import { NewsListContent, NewsListLoading } from './fragments/NewsComponents';

interface NewsProps {
    setNewsState: typeof setNewsState;
    loadNews: typeof loadNews;
    uploadNewsMedia: typeof uploadNewsMedia;
    saveNews: typeof saveNews;
    deleteNews: typeof deleteNews;
    setSystemState: typeof setSystemState;
    resetSystemDialog: typeof resetSystemDialog;
    news: NewsState
}

class News extends React.Component<NewsProps> {
    search = debounce((input: string) => {
        this.props.loadNews({ search: input, status: this.props.news.activeTab });
    }, 300, { leading: false })

    componentDidMount = () => {
        this.props.setSystemState({
            headerText: {
                main: 'News',
                sub: ''
            },
            headerEndButton: () => (
                <div>
                    <SymphonyHeaderButton
                        id="add-news-btn"
                        startIcon={<AddIcon />}
                        onClick={this._onAddNewsClick.bind(this)}
                    >
                        Add New
                    </SymphonyHeaderButton>
                </div>
            ),
            shallRedirect: false,
            redirectTo: ''
        });
        this.props.loadNews({ status: this.props.news.activeTab });
    }

    componentWillUnmount = () => this.props.setSystemState({ header: undefined, headerEndButton: undefined });

    _onNewsTextInput = (field: string, e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.setNewsState({ [field]: e.target.value });
    }

    _onNewsInput = (field: string, value: DynamicBasicNewsType) => {
        this.props.setNewsState({ [field]: value });
    }

    _onNewsClick = (id: string) => {
        const news = find(this.props.news.news, { id })
        this.props.setNewsState({ activeNewsId: id, activeNews: news, isEditing: true });
    }

    _onCancelClick = () => { }

    _onActiveNewsInput = (field: string, value: DynamicBasicNewsType) => {
        if (field === 'media') {
            this._onMediaInput(value as React.ChangeEvent<HTMLInputElement>)
        }
        else {
            console.log(`${field}: ${value}`)
            const newNews = { ...this.props.news.activeNews, [field]: value } as NewsType;
            console.log(newNews)
            this.props.setNewsState({ activeNews: newNews });
        }
    }

    _onNewsSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.search(e.target.value)
    }

    _onMediaInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            this.props.uploadNewsMedia(e.target.files[0])
        }
    }

    _onAddNewsClick = () => {
        this.props.setNewsState({
            activeNews: {
                id: '',
                title: '',
                subtitle: '',
                category: '',
                description: '',
                isActive: true,
                startDate: moment().format("DD/MM/YYYY"),
                endDate: moment().format("DD/MM/YYYY"),
                pdf: [] as Array<BasicNewsMedia>,
                images: [] as Array<BasicNewsMedia>,
                videos: [] as Array<BasicNewsMedia>,
                brochures: [] as Array<BasicNewsMedia>
            } as NewsType,
            activeNewsId: v4(),
            isEditing: true
        });
        // this.props.setSystemState({
        //     shallRedirect: true,
        //     redirectTo: '/sales/news/new'
        // });
    }

    _onNewsSave = () => {
        if (this._validateNews()) {
            const { activeNews } = this.props.news;
            if (activeNews) {
                let mediaUploading = false;
                for (const image of activeNews!.images) {
                    if (image.loading) mediaUploading = true;
                }
                for (const video of activeNews!.videos) {
                    if (video.loading) mediaUploading = true;
                }
                for (const pdf of activeNews!.pdf) {
                    if (pdf.loading) mediaUploading = true;
                }

                if (!mediaUploading) {
                    this.props.setSystemState({
                        systemDialogOpen: true,
                        systemDialogMaxWidth: 'xs',
                        systemDialogTitle: 'Confirm Save',
                        systemDialogContent: 'Please note that any changes are permanent. To continue, please click the save button.',
                        systemDialogSimple: true,
                        systemDialogConfirm: false,
                        systemConfirmOnly: false,
                        systemDialogConfirmAction: () => {
                            this.props.saveNews()
                            this.props.resetSystemDialog();
                            this._setHeader();
                        }
                    });
                }
                else {
                    toastWarning("Cannot save when a media is still uploading")
                }
            }
        }
    }

    _onNewsDelete = (newsId: string) => {
        this.props.setSystemState({
            systemDialogOpen: true,
            systemDialogMaxWidth: 'xs',
            systemDialogTitle: 'Confirm Delete',
            systemDialogContent: 'This will delete the news. Please click the delete button to proceed',
            systemDialogSimple: true,
            systemDialogConfirm: true,
            systemOverrideTitle: 'Delete',
            systemDialogConfirmAction: () => {
                // do delete
                this.props.deleteNews(newsId);
                this.props.resetSystemDialog();
                this._setHeader()
            }
        });
    }

    _validateNews = (): boolean => {
        const { activeNews } = this.props.news;
        if (activeNews) {
            if (!activeNews.title) { toastWarning("Missing news title"); return false; }
            if (!activeNews.subtitle) { toastWarning("Missing news subtitle"); return false; }
            if (!activeNews.description) { toastWarning("Missing news description"); return false; }

            const existing = find(this.props.news.news, { title: activeNews.title });
            if (existing && activeNews.id !== existing.id) {
                toastWarning("News already exist");
                return false;
            }
        }
        return true;
    }

    _onMediaPreviewClick = (media: MediaInputType) => {
        this.props.setNewsState({
            mediaToPreview: media as BasicNewsMedia,
            mediaPreviewVisible: true
        })
    }

    _onChangeTab = (tab: string) => {
        this.props.setNewsState({ activeTab: tab })
        this.props.loadNews({ status: tab });
    }

    _setHeader = () => {
        this.props.setSystemState({
            header: 'News',
            headerEndButton: () => (
                <div>
                    <SymphonyHeaderButton
                        id="add-news-btn"
                        startIcon={<AddIcon />}
                        onClick={this._onAddNewsClick.bind(this)}
                    >
                        Add New
                    </SymphonyHeaderButton>
                </div>
            ),
            shallRedirect: false,
            redirectTo: ''
        });
    }


    render() {
        const { activeNewsId, news, activeNews, activeNewsLoading, newsListLoading } = this.props.news;
        return (
            <BasicLayout>
                {
                    !activeNewsId ? <SymphonyTabsContainer>
                        <SymphonyTabs
                            value={this.props.news.activeTab}
                            TabIndicatorProps={{ style: { height: 4, backgroundColor: SYMPHONY_PRIMARY_COLOR } }}
                        >
                            <SymphonyTab
                                label="Active"
                                value="active"
                                onClick={() => this._onChangeTab('active')}
                            />
                            <SymphonyTab
                                label="Inactive"
                                value="inactive"
                                onClick={() => this._onChangeTab('inactive')}
                            />
                            <SymphonyTab
                                label="Scheduled"
                                value="scheduled"
                                onClick={() => this._onChangeTab('scheduled')}
                            />
                        </SymphonyTabs>
                        <SymphonyField
                            id="customer-search-fld"
                            style={{ marginBottom: 8, width: 300 }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">
                                    <Search htmlColor={SYMPHONY_PRIMARY_COLOR} />
                                </InputAdornment>
                            }}
                            onChange={this._onNewsSearch.bind(this)}
                            placeholder="Search"
                        />
                    </SymphonyTabsContainer> : <></>
                }

                <NewsListContent>
                    {this.props.news.newsListLoading ? <SymphonyContentLoading /> :
                        <>
                            {news.length > 0 ?
                                <>
                                    <Grid container={true}>
                                        {
                                            !activeNewsId ? <Grid item={true} xs={12}>
                                                <NewsList
                                                    news={news}
                                                    activeNewsId={activeNewsId}
                                                    loading={newsListLoading}
                                                    onNewsSearch={this._onNewsSearch}
                                                    onNewsClick={this._onNewsClick.bind(this)}
                                                    onAddNewsClick={this._onAddNewsClick.bind(this)}
                                                    onDeleteNewsClick={this._onNewsDelete.bind(this)}
                                                />
                                            </Grid> : <Grid item={true} xs={12}>
                                                <NewsView
                                                    news={activeNews}
                                                    loading={activeNewsLoading}
                                                    onNewsInput={this._onActiveNewsInput.bind(this)}
                                                    newsSaving={this.props.news.activeNewsLoading}
                                                    onCancelClick={() => {
                                                        this._onNewsInput('activeNewsId', '');
                                                        this._onNewsInput('activeNews', undefined);
                                                        this._setHeader();
                                                        this.props.setNewsState({ isEditing: false })
                                                    }}
                                                    onSaveClick={this._onNewsSave.bind(this)}
                                                    onMediaClick={this._onMediaPreviewClick.bind(this)}
                                                    onDeleteNewsClick={this._onNewsDelete.bind(this)}
                                                />
                                            </Grid>
                                        }
                                    </Grid>
                                </>
                                : activeNews ? <Grid container={true}>
                                    {
                                        <Grid item={true} xs={12}>
                                            <NewsView
                                                news={activeNews}
                                                loading={activeNewsLoading}
                                                onNewsInput={this._onActiveNewsInput.bind(this)}
                                                newsSaving={this.props.news.activeNewsLoading}
                                                onCancelClick={() => {
                                                    this._onNewsInput('activeNewsId', '');
                                                    this._onNewsInput('activeNews', undefined);
                                                    this._setHeader();
                                                    this.props.setNewsState({ isEditing: false })
                                                }}
                                                onSaveClick={this._onNewsSave.bind(this)}
                                                onMediaClick={this._onMediaPreviewClick.bind(this)}
                                                onDeleteNewsClick={this._onNewsDelete.bind(this)}
                                            />
                                        </Grid>
                                    }
                                </Grid> :
                                    <NewsListLoading>No News Found</NewsListLoading>
                            }
                        </>
                    }
                </NewsListContent>

                <BasicMediaPreview
                    visible={this.props.news.mediaPreviewVisible}
                    media={this.props.news.mediaToPreview}
                    handleClose={() => this.props.setNewsState({ mediaPreviewVisible: false })}
                />
            </BasicLayout>
        )
    }
}

const mapStateToProps = (state: AppState) => ({
    news: state.news
});

export default connect(mapStateToProps, {
    setNewsState,
    loadNews,
    uploadNewsMedia,
    saveNews,
    deleteNews,
    setSystemState,
    resetSystemDialog
})(News);