export const appColors = {
  mediumGray: '#969696',
  dark: '#222',
  primary: '#B57937 ',
  bluishWhite: '#F4F6F9',
  indigo: '#5989ED',
  error: '#DE350B',
  delete: '#FF4D4D',
  success: '#1D875A',
};
