import React from 'react';

import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { appColors } from '../../lib/constants';

const buttonStyles: React.CSSProperties = {
  fontSize: 12,
  textTransform: 'capitalize',
  fontWeight: 600,
  color: appColors.mediumGray,
  width: 120,
  height: 40,
  padding: '8px 12px',
  justifyContent: 'space-between',
};

function LeadsFilter() {
  return (
    <Button
      style={buttonStyles}
      variant="outlined"
      disableElevation
      endIcon={<ExpandMoreIcon />}>
      Filter
    </Button>
  );
}

export default LeadsFilter;
