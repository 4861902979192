import React, { useEffect } from 'react';
import {
    HeaderButton,
    BasicFieldBox,
    BasicDeleteButton
} from '../../Common/BasicCommonComponents';
import { News, DynamicBasicNewsType, BasicNewsMedia } from '../../../../store/news/types';
import { BasicProductMedia } from '../../../../store/basicproduct/types';
import { BasicPromotionMedia } from '../../../../store/promotion/types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import BasicInput from '../../Common/BasicInput';
import find from 'lodash/find'
import moment from 'moment';
import { connect, useSelector } from 'react-redux';
import { AppState } from '../../../../store';
import { DecoratedPopoverButton, SymphonyHeaderButton } from '../../../symphony/SymphonyCommonComponents';
import { setNewsState } from '../../../../store/news/actions';
import { setSystemState } from '../../../../store/system/actions';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { SYMPHONY_PRIMARY_COLOR } from '../../../symphony/Colors'
import SymphonyInput from '../../../symphony/SymphonyInput';
import { AddInputContainer, PromoBannerDateContainer } from '../../../market/promobanner/fragments/PromoBannerCommonComponents'
import { Grid, Icon, Typography } from '@material-ui/core';
import PromoBannerDatePicker from '../../../market/promobanner/fragments/PromoBannerDatePicker';
import MarketAuxMenu from '../../../market/common/MarketAuxMenu';
import BasicTimePicker from '../../Common/BasicTimePicker';

interface NewsViewProps {
    news?: News
    loading: boolean;
    onNewsInput: (field: string, val: DynamicBasicNewsType) => void;
    newsSaving: boolean;
    onSaveClick: () => void;
    onCancelClick: () => void;
    onDeleteNewsClick: (newsId: string) => void;
    onMediaClick: (media: BasicNewsMedia | BasicProductMedia | BasicPromotionMedia) => void;
    setSystemState: typeof setSystemState;
}

const NewsView = (props: NewsViewProps) => {
    const { news, setSystemState, onDeleteNewsClick } = props;
    const { isEditing } = useSelector((state: AppState) => state.news);
    const statusList = [{ label: 'Active', value: 'Active' }, { label: 'Inactive', value: 'Inactive' }];
    const statusOption = find(statusList, { value: news?.isActive ? 'Active' : 'Inactive' });
    const fieldValue = statusOption ? statusOption.value : '';

    useEffect(() => {
        if (isEditing) {
            setSystemState({
                header: <Box display="flex">
                    <HeaderButton
                    style={{minWidth: '15px', marginRight: '3px', padding: 0}}
                        onClick={props.onCancelClick}
                    >
                        <ArrowBackIcon style={{ color: SYMPHONY_PRIMARY_COLOR }} />
                    </HeaderButton>
                    <Box fontSize="36px" style={{ color: 'black' }}>
                        {
                            news && news.title ? news.title : 'Create News'
                        }
                    </Box>
                </Box>,
                headerEndButton: () => (
                    <div>
                        <SymphonyHeaderButton
                            id="add-news-btn"
                            onClick={props.onSaveClick}
                        >
                            Save
                        </SymphonyHeaderButton>
                    </div>
                ),
                shallRedirect: false,
                redirectTo: ''
            });
        }
    }, [])

    return (
        <>
            {news &&
                <>
                    <Grid container>
                        <Grid item xs={3}>
                            <BasicFieldBox style={{ paddingTop: '30px', overflowY: 'hidden' }}>
                                <AddInputContainer style={{ width: '100%', paddingTop: '15px' }}>
                                    <SymphonyInput
                                        id="news-dropdown"
                                        type="select"
                                        label="Status"
                                        value={fieldValue}
                                        selectOptions={statusList}
                                        selectOnchange={(e: React.ChangeEvent<{ value: unknown }>) =>
                                            props.onNewsInput('isActive', e.target.value === 'Active' ? true : false)}
                                    />
                                </AddInputContainer>
                                <PromoBannerDateContainer style={{ width: '100%' }}>
                                    <PromoBannerDatePicker
                                        startDate={!news.startDate ? moment(moment.now()).format('MM/DD/YYYY') as string : news.startDate}
                                        endDate="01/01/2030"
                                        onChangeDate={(property: 'startDate' | 'endDate', value: string) => {
                                            console.log(`${property}:${value}`)
                                            props.onNewsInput(property, value)
                                        }}
                                        disableStartDate={false}
                                        disableEndDate={true}
                                        KeyboardButtonProps={{ style: { display: 'visible' } }}
                                    />
                                </PromoBannerDateContainer>
                                {/* <BasicTimePicker label='Publish Time'
                                    value={news.startTime ? news.startTime : moment().format("HH:mm") as string}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        props.onNewsInput('startTime', e.target.value)
                                    }}
                                /> */}
                            </BasicFieldBox>
                        </Grid>
                        <Grid item xs={9}>
                            <BasicFieldBox>
                                {props.newsSaving ?
                                    <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                                        <CircularProgress />
                                    </Box>
                                    :
                                    <>
                                        <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                                            <div>
                                                <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>
                                                    News Information
                                                </Typography>
                                                { props.news!.id.indexOf('NEWS') > -1 && <Typography style={{ fontSize: 12, color: '#959595' }}>
                                                    Last edited on {`${moment(news.dateUpdated).format("DD.MM.YYYY")} at ${moment(news.dateUpdated).format("hh:mm A")}`}
                                                </Typography> }
                                            </div> 
                                            {props.news!.id.indexOf('NEWS') > -1 &&
                                                <MarketAuxMenu>
                                                    <DecoratedPopoverButton
                                                        id="news-delete-btn"
                                                        style={{ color: '#FF4D4D', paddingLeft: '5px', paddingRight: '5px' }}
                                                        endIcon={<Icon className="fa fa-trash-alt" />}
                                                        onClick={() => onDeleteNewsClick(news.id)}
                                                    >
                                                        Delete
                                                    </DecoratedPopoverButton>
                                                </MarketAuxMenu>
                                            }
                                        </Box>
                                        <SymphonyInput
                                            type="text"
                                            value={news.title}
                                            label="Title"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                                props.onNewsInput('title', e.target.value)
                                            }}
                                            maxLength={100}
                                        />
                                        <SymphonyInput
                                            type="text"
                                            value={news.subtitle as string}
                                            label="Subtitle"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                                props.onNewsInput('subtitle', e.target.value)
                                            }}
                                        />
                                        <SymphonyInput
                                            id="description-input"
                                            type="multiline"
                                            label="Description"
                                            richText={true}
                                            value={news.description}
                                            onRichTextInput={(html) => {
                                                props.onNewsInput('description', html)
                                            }}
                                        />

                                        <BasicInput
                                            id="media-1"
                                            label="Images"
                                            type="mediainput"
                                            mediaLabel="Click to add Image"
                                            mediaType="IMAGE"
                                            disableMediaLabel={true}
                                            value={news.images}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                                props.onNewsInput('media', (e as React.ChangeEvent<HTMLInputElement>))
                                            }}
                                            mediaClick={props.onMediaClick}
                                        />
                                    </>
                                }

                            </BasicFieldBox>
                        </Grid>
                    </Grid>
                </>
            }
        </>
    )
}

export default connect(null, {
    setNewsState,
    setSystemState,
})(NewsView);