import React from 'react';
import Box from '@material-ui/core/Box';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartData,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import {
    getTotalSalesReport,
    selectTotalSalesTate,
    setTotalSalesFilter
} from '../../../store/marketreport/totalsales/reducer';
import useAtBreakpoint from '../../symphony/hooks/useAtBreakpoint';
import ChartContainer from './ChartContainer';
import ReportFilterGroup from './ReportFilterGroup';
import { Typography } from '@material-ui/core';
import ArrowCircleUpIcon from '@material-ui/icons/ArrowUpward';
import ArrowCircleDownIcon from '@material-ui/icons/ArrowDownward';
import { getTopFiveReport, selectTopFiveData } from '../../../store/marketreport/topfive/reducer';
import { map } from 'lodash';
import moment from 'moment';
import { AppState } from '../../../store';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


const TotalSalesReport = () => {
    const isLargeScreen = useAtBreakpoint('xl');
    const { filter, error, stateStatus } = useSelector(selectTotalSalesTate)
    const salesData = useSelector(selectTotalSalesTate)
    const country = useSelector((state: AppState) => state.login.user.countryCode)
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(getTotalSalesReport(filter));
    }, [dispatch, filter]);


    const totalSalesValue = salesData.data.totalSales;
    const differencePercentage = salesData.data.differencePercentage;
    const differenceOfSale = salesData.data.difference;
    const monthlySales = salesData.data.monthlySales;

    const CurrencySign = () => country === 'PH' ? <span>₱ {totalSalesValue.toFixed(2)}</span> : <span>S$ {totalSalesValue.toFixed(2)}</span>

    const Growth = () => {
        if (differenceOfSale > 0) {
            return (
                <>
                    <ArrowCircleUpIcon style={{ color: '#49DCB1' }} fontSize='small' />
                    <Typography style={{ color: '#49DCB1' }}> {differenceOfSale} ({differencePercentage}%)</Typography>
                </>
            )
        } else if (differenceOfSale === 0) {
            return <Typography style={{ color: 'red' }}> {differenceOfSale} ({differencePercentage}%)</Typography>
        } else {
            return <>
                <ArrowCircleDownIcon style={{ color: 'red' }} fontSize='small' />
                <Typography style={{ color: 'red' }}> {differenceOfSale} ({differencePercentage}%)</Typography>
            </>
        }

    }
    const SaleBox = () => {
        return <Box display='flex' mb={1} justifyContent="flex-start">
            <Box>
                {/* <Typography style={{ color: '#B57937' }}>${' '}{totalSalesValue.toFixed(2)}</Typography> */}
                <Typography style={{ color: '#B57937' }}><CurrencySign /></Typography>
            </Box>
            <Box display='flex' paddingLeft={2}>
                <Growth />
            </Box>
        </Box>
    }

    const options = {
        responsive: true,
        scales: {
            y: {
                stepSize: 3
            }
        },
        plugins: {
            legend: {
                display: false
            }
        },
        elements: {
            line: {
                borderColor: '#B57937',
                borderWidth: 1.5
            },
            point: {
                borderColor: '#B57937',
                backgroundColor: '#B57937'
            }
        }
    }

    const dataToDisplay: ChartData = {
        labels: map(monthlySales, (m) => moment(m.month, 'MM').format('MMM')),
        datasets: [
            {
                data: map(monthlySales, (m) => m.total),
                hoverOffset: 2,
            }
        ],
    }

    const handleFilterChange = (key: keyof typeof filter, value: string) => {
        dispatch(
            setTotalSalesFilter({
                ...filter,
                [key]: value
            })
        );
    };



    return (

       <Box style={{height:'100%',minHeight:'100%'}}>
         <ChartContainer
            title='Total Sales'
            loading={stateStatus === 'loading'}
            error={error}
            noData={salesData.data.monthlySales.length === 0}
            controls={
                <ReportFilterGroup
                    filter={filter}
                    onFilterChange={handleFilterChange}
                />
            }>
            <SaleBox />
            <Line
                type="line"
                data={dataToDisplay}
                options={options}
                height={50}
            />
        </ChartContainer>
       </Box>

    )
};

export default TotalSalesReport;
