import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../store/';
import { BasicProductState, DynamicBasicProductInput } from '../../../store/basicproduct/types';
import { setBasicProductState, getProducts, importProducts, loadCategories, updateBasicProductMultiple } from '../../../store/basicproduct/actions';
import { setSystemState, resetSystemDialog } from '../../../store/system/actions';
import SymphonyLayout from '../../symphony/SymphonyLayout';
import ProductCardList from './fragments/ProductCardList';
// local components
import {
    ProductsContainer,
    ProductsContentContainer,
    ProductsTabsContainer,
    ProductLoadingContainer,
    AddProductButton
} from './fragments/ProductComponents';
// common components
import {
    SalesField,
    SalesTabs,
    SalesTab
} from '../../Basic/Common/BasicCommonComponents';
import { SYMPHONY_PRIMARY_COLOR } from '../../symphony/Colors';

// material components
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from '@material-ui/icons/Add';
import Search from '@material-ui/icons/Search';
import Zoom from '@material-ui/core/Zoom';
import FileModal from './fragments/ImportModal'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

// util
import debounce from 'lodash/debounce';
import { Box, Icon, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import find from 'lodash/find';
import { toastSuccess, toastWarning } from '../../../modules/Toast';
import { stringValidator } from '../../../utils/validators';
import Spinner from './fragments/spinner';

// import sheetjs
import * as XLSX from "xlsx";

import BrandButton from '../../Common/BrandButton';

interface ProductProps {
    setSystemState: typeof setSystemState;
    setBasicProductState: typeof setBasicProductState;
    resetSystemDialog: typeof resetSystemDialog;
    getProducts: typeof getProducts;
    updateBasicProductMultiple: typeof updateBasicProductMultiple;
    product: BasicProductState;
    importProducts: typeof importProducts;
    loadCategories: typeof loadCategories;
}

const EMPTY_PRODUCT = {
    name: '',
    brand: '',
    material: '',
    type: '',
    description: '',
    h1: '',
    h2: '',
    h3: ''
} as unknown as DynamicBasicProductInput;

class Product extends React.Component<ProductProps> {
    searchRef = React.createRef<HTMLInputElement>();
    fileRef = React.createRef<HTMLInputElement>();

    state = {
        openFileModal: false,
        spreadsheet: []
    };

    _search = debounce((searchString: string) => {
        this.props.getProducts('list', searchString, this.props.product.activeTab === 'Active');
    }, 300, { leading: false });

    componentDidMount = () => {
        // set button
        this.props.setSystemState({
            headerEndButton: () => (
                <Box display="flex" justifyContent="center">
                    <input type='file' id="file"
                        hidden
                        accept=".xls,.xlsx,.csv,.csvx"
                        ref={this.fileRef}
                        onChange={this.readUploadFile}
                    />

                    <BrandButton
                        endIcon={<Icon className="fas fa-file-import" />}
                        onClick={this.triggerFile.bind(this)}
                        brand='secondary'
                    >
                        Import
                    </BrandButton>

                    <BrandButton
                        id="add-product-btn"
                        startIcon={<AddIcon />}
                        onClick={this._onAddClick.bind(this)}
                    >
                        Add New
                    </BrandButton>
                </Box>
            ),
            shallRedirect: false,
            redirectTo: ''
        });
        this.props.loadCategories();
        this.props.getProducts('list', '', this.props.product.activeTab === 'Active');
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.product.selectedBasicProduct !== this.props.product.selectedBasicProduct) {
            this.props.setSystemState({
                headerEndButton: () => (
                    <Box display="flex" justifyContent="center">
                        <input type='file' id="file"
                            hidden
                            accept=".xls,.xlsx,.csv,.csvx"
                            ref={this.fileRef}
                            onChange={this.readUploadFile}
                        />

                        {
                            !!this.props.product.selectedBasicProduct!.length && <>
                                {
                                    this.props.product.activeTab === 'Active' ? <>
                                        <BrandButton brand='outlined' onClick={() => this._handleMultiSelection('inactive')}>Inactive</BrandButton>
                                        <BrandButton brand='outlined' onClick={() => this._handleMultiSelection('delete')}>Delete</BrandButton>
                                    </> : <>
                                        <BrandButton brand='outlined' onClick={() => this._handleMultiSelection('active')}>Active</BrandButton>
                                        <BrandButton brand='outlined' onClick={() => this._handleMultiSelection('delete')}>Delete</BrandButton>
                                    </>
                                }
                            </>
                        }

                        <BrandButton
                            endIcon={<Icon className="fas fa-file-import" />}
                            onClick={this.triggerFile.bind(this)}
                            brand='secondary'
                        >
                            Import
                        </BrandButton>

                        <BrandButton
                            id="add-product-btn"
                            startIcon={<AddIcon />}
                            onClick={this._onAddClick.bind(this)}
                        >
                            Add New
                        </BrandButton>
                    </Box>
                ),
                shallRedirect: false,
                redirectTo: ''
            });
        }

        if (prevProps.product.importing !== this.props.product.importing) {
            toast.update('upload', { type: toast.TYPE.SUCCESS, autoClose: 2000, render: () => (
                <div style={{
                    display: 'flex',
                    alignItems: 'center', gap: 7, lineHeight: '10px'
                }}>
                    <Zoom in>
                        <CheckCircleIcon />
                    </Zoom>
                    <Typography>
                        Upload Complete
                    </Typography>
                </div>
            ) })
        }
    }

    componentWillUnmount = () => this.props.setSystemState({ header: undefined, headerEndButton: undefined });

    uploading = () => (
        <div style={{ display: 'flex', alignItems: 'center', gap: 15 }}>
            <Spinner /> Importing Products
        </div>
    );

    triggerFile = () => {
        this.fileRef.current?.click()
    }

    readUploadFile = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const error: Array<string> = [];
                let existError: string = ''

                const data = e.target ? e.target.result : []
                const workbook = XLSX.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const spreadsheet = XLSX.utils.sheet_to_json(worksheet) as Array<object>;
                // console.log(this.props.product.fields)
                // console.log(this.props.product.variantFields)
                spreadsheet.every(sheet => {
                    const existing = find(this.props.product.products, (p) => stringValidator(p.name) === stringValidator(sheet['name']));
                    if (existing && existing.id !== sheet['id']) { existError = 'Product sheet contains an existing product' }
                    // common info validation
                    // console.log(!sheet['name'].trim().length)
                    // if (!sheet['name'].toString().trim()) { error.push('Name') }
                    // if (!sheet['skuNumber'].toString().trim()) { error.push('SKU') }
                    // if (!sheet['brand'].trim()) { error.push('Brand') }
                    // if (!sheet['material'].trim()) { error.push('Material') }
                    // if (!sheet['type'].trim()) { error.push('Type') }
                    // if (!sheet['description']) { error.push('Description') }
                    // if (!sheet['h1']) { error.push('H1') }


                    this.props.product.fields.forEach(field => {
                        if (field.isRequired) {
                            if (['Input Text', 'Input Text Area'].includes(field.type)) {
                                if (typeof sheet[field.name] === 'undefined') return error.push(field.name)
                                if (!sheet[field.name].trim().length) return error.push(field.name)
                            } else if (['Input Number'].includes(field.type)) {
                                if (!sheet[field.name]) return error.push(field.name)
                                if (typeof sheet[field.name] !== 'number') return error.push(field.name)
                            }
                            else {
                                if (!sheet[field.name]) return error.push(field.name)
                            }
                        }
                    })
                    this.props.product.variantFields.forEach(field => {
                        // console.log(`${field.name}:${field.isRequired}:${field.type}`)
                        if (field.isRequired) {
                            if (['Input Text', 'Input Text Area'].includes(field.type)) {
                                if (typeof sheet[field.name] === 'undefined') return error.push(field.name)
                                if (!sheet[field.name].trim().length) return error.push(field.name)
                            } else if (['Input Number'].includes(field.type)) {
                                if (!sheet[field.name]) return error.push(field.name)
                                if (typeof sheet[field.name] !== 'number') return error.push(field.name)
                            } else if (field.type === 'Radio Button' && field.title == 'Status') {
                                if (!['true', 'false', true, false].includes(sheet[field.name])) return error.push(field.name)
                            } else if (field.type !== 'Multimedia') {
                                if (!sheet[field.name]) return error.push(field.name)
                            }
                        }
                    })
                    if (existError.length > 0) return false
                    if (error.length > 0) return false
                })
                if (existError) return toastWarning(existError)
                if (error.length > 0) return toastWarning(`Missing Required field(s): ${error.join(', ')}`);
                this.setState({ openFileModal: true, spreadsheet: spreadsheet.filter(p => p['name'] !== "") })
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
        e.target.value = null
    }

    filePathset = (e) => {
        e.stopPropagation();
        e.preventDefault();
        var file = e.target.files[0];

        this.setState({ file });

        this.readFile(file)
        e.target.value = null
    }

    readFile(f: File) {
        const reader = new FileReader();
        reader.onload = (evt: any) => {
            // evt = on_file_select event
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            const error: Array<string> = [];
            let existError: string = ''
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const spreadsheet = this.convertToJson(XLSX.utils.sheet_to_json(ws));
            /* Update state */
            // this.setState({ openFileModal: true, spreadsheet: this.convertToJson(XLSX.utils.sheet_to_csv(ws)) })

            spreadsheet.every(sheet => {
                const existing = find(this.props.product.products, (p) => stringValidator(p.name) === stringValidator(sheet['name']));
                if (existing && existing.id !== sheet['id']) { existError = 'Product Name already exist' }
                // common info validation
                if (!sheet['name']) { error.push('Name') }
                if (!sheet['brand']) { error.push('Brand') }
                if (!sheet['material']) { error.push('Material') }
                if (!sheet['type']) { error.push('Type') }
                if (!sheet['description']) { error.push('Description') }
                if (!sheet['h1']) { error.push('H1') }
                if (existError.length > 0) return false
                if (error.length > 0) return false
            })

            if (existError) return toastWarning(existError)
            if (error.length > 0) return toastWarning(`Missing Required field(s): ${error.join(', ')}`);
            this.setState({ openFileModal: true, spreadsheet: spreadsheet.filter(p => p.name !== "") })
        };
        reader.readAsBinaryString(f);
    }

    convertToJson(csv) {
        let lines = csv.split("\n");
        console.log(lines)
        let result: Array<any> = [];

        let headers = lines[0].split(",");

        for (var i = 1; i < lines.length; i++) {
            let obj: any = {};
            let currentline = lines[i].split(",");

            for (let j = 0; j < headers.length; j++) {
                obj[headers[j]] = currentline[j];
            }

            result.push(obj);
        }

        //return result; //JavaScript object
        return result; //JSON
    }

    _triggerDialog = (title: string, content: string, action: () => void, overrideTitle?: string) => {
        this.props.setSystemState({
            systemDialogOpen: true,
            systemDialogMaxWidth: 'xs',
            systemDialogTitle: title,
            systemOverrideTitle: overrideTitle,
            systemDialogContent: content,
            systemDialogSimple: true,
            systemDialogConfirm: true,
            systemDialogConfirmAction: action
        })
    }

    _handleMultiSelection = (action: string) => {
        if (action === 'active' || action === 'inactive') {
            this._triggerDialog(
                'Confirm Save',
                'Please note that any changes are permanent. To continue, please click the save button.',
                () => {
                    this.props.updateBasicProductMultiple(action)
                    this.props.resetSystemDialog();
                },
            );
        } else {
            this._triggerDialog(
                'Confirm Delete',
                'Deleting these products are permanent. Please click confirm to continue',
                () => {
                    this.props.updateBasicProductMultiple(action)
                    this.props.resetSystemDialog();
                },
                'Confirm'
            );
        }

        this.props.setBasicProductState({ uncheckAll: false, selectedBasicProduct: [] });
    }

    _onTabChange = (tab: 'Active' | 'Inactive') => {
        const { current } = this.searchRef;
        this.props.setBasicProductState({ activeTab: tab as string, uncheckAll: false, selectedBasicProduct: [] });
        this.props.getProducts('list', '', tab === 'Active');

        // reset search value when changing tabs
        if (current) {
            current.value = '';
        }
    }

    _onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        this._search(e.target.value)
    }

    _onAddClick = () => {
        this.props.setBasicProductState({
            activeProductId: 'new',
            activeProduct: EMPTY_PRODUCT,
            productVariants: []
        });

        this.props.setSystemState({
            shallRedirect: true,
            redirectTo: '/sales/product/new'
        });
    }

    onImportClick = async () => {
        this.props.importProducts(this.state.spreadsheet)
        setTimeout(() => {
            this.setState({
                openFileModal: false,
                spreadsheet: []
            });
            this.pendingImport()
        }, 1000);
        // this.props.getProducts('list', '', this.props.product.activeTab === 'Active');
    }

    pendingImport = () => {
        toast.info(() => (
            <div style={{
                display: 'flex',
                alignItems: 'center', gap: 7, lineHeight: '10px'
            }}>
                <Spinner />
                <Typography>
                    Processing please wait...
                </Typography>
            </div>
        ), {
            autoClose: false,
            toastId: 'upload'
        })
    }

    render() {
        const { activeTab, products, productListLoading } = this.props.product;
        return (
            <SymphonyLayout>
                <ProductsContainer>
                    <FileModal open={this.state.openFileModal}
                        data={this.state.spreadsheet}
                        loading={this.props.product.activeProductVariantLoading}
                        onImport={this.onImportClick}
                        onCancel={() => this.setState({ openFileModal: false, spreadsheet: [] })} />
                    <ProductsTabsContainer>
                        <SalesTabs
                            value={activeTab}
                            TabIndicatorProps={{ style: { height: 4, backgroundColor: SYMPHONY_PRIMARY_COLOR } }}
                        >
                            <SalesTab
                                id="product-active-tab"
                                label="Active"
                                value="Active"
                                onClick={this._onTabChange.bind(this, 'Active')}
                            />
                            <SalesTab
                                id="product-inactive-tab"
                                label="Inactive"
                                value="Inactive"
                                onClick={this._onTabChange.bind(this, 'Inactive')}
                            />
                        </SalesTabs>
                        <SalesField
                            id="product-search-fld"
                            style={{ marginBottom: 8, width: 300 }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">
                                    <Search htmlColor={SYMPHONY_PRIMARY_COLOR} />
                                </InputAdornment>
                            }}
                            inputProps={{ ref: this.searchRef }}
                            onChange={this._onSearch.bind(this)}
                            placeholder="Search"
                        />
                    </ProductsTabsContainer>
                    <ProductsContentContainer>
                        {!productListLoading ?
                            <>
                                {products.length > 0 ?
                                    <ProductCardList
                                        products={products}
                                    />
                                    :
                                    <ProductLoadingContainer>No Product Found</ProductLoadingContainer>
                                }
                            </>
                            :
                            <ProductLoadingContainer>
                                <CircularProgress style={{ color: SYMPHONY_PRIMARY_COLOR }} />
                            </ProductLoadingContainer>
                        }
                    </ProductsContentContainer>
                </ProductsContainer>
            </SymphonyLayout>
        )
    }
}

const mapStateToProps = (state: AppState) => ({
    product: state.basicproduct
});

export default connect(mapStateToProps, {
    setBasicProductState,
    setSystemState,
    getProducts,
    importProducts,
    updateBasicProductMultiple,
    resetSystemDialog,
    loadCategories
})(Product);