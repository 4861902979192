import React, { useCallback, useEffect, useState } from 'react';


import { Vendor } from '../../../../store/usermanagement/types';
import { loadVendorList } from "../../../../store/usermanagement/actions";
import { useDispatch, useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { styled } from '@material-ui/styles';
import { AppState } from '../../../../store';

const SelectContainer = styled(AsyncSelect)(({ theme }) => ({
    fontSize: 12,
    fontWeight: 500,
    zIndex: 2,
    '.select__control': {
        border: 'none !important'
    }
}));

interface IVendor extends Vendor {
    label: string;
    value: string;
  }

interface IVendorProduct {
    onSelectedChange: (product: Partial<IVendor> | null) => void;
    value: Array<Vendor>;
}

const PromotionProduct = ({ onSelectedChange, value }: IVendorProduct) => {
    let { userListLoading, vendors } = useSelector((state: AppState) => state.usermanagement)
    const [selectedOptions, setSelectedOptions] = useState<IVendor | null>(null)
    const [filteredVendor, setVendor] = useState<Array<Partial<{ label: string; value: string }>>>(vendors.map(vendor => { return { label: vendor.companyName, value: vendor.id } }))

    useEffect(() => {
        onSelectedChange(selectedOptions)
    }, [selectedOptions])

    const filterProducts = (inputValue: string) => {
        return filteredVendor.filter((i) =>
            i.label?.toLowerCase().includes(inputValue.toLowerCase())
        );
    };
    // debounce search
    const loadOptions = (
        inputValue: string,
        callback: (options: Array<Partial<{ label: string; value: string }>>) => void
    ) => {
        setTimeout(() => {
            callback(filterProducts(inputValue));
        }, 1000);
    };

    const handleInputChange = (newValue: string) => newValue

      const handleSelection = useCallback((options: any) => {
        setSelectedOptions(options)
      }, [selectedOptions])

    return (
        <div style={{ marginBottom: '25px', marginTop: '25px' }}>
            <div style={{ fontSize: 12, color: '#959595', marginBottom: '7px' }}>Select Vendor </div>
            <SelectContainer
                cacheOptions
                defaultOptions
                placeholder="Select Vendor"
                defaultValue={value}
                loadOptions={loadOptions}
                isLoading={userListLoading}
                onInputChange={handleInputChange}
                onChange={handleSelection}
            />
        </div>
    )
}


export default PromotionProduct;