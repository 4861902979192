import { AnyAction } from 'redux';
import { GenericMedia } from '../system/types';


export interface ProductRatingStateInput {
    [name: string]: string | Array<string> | ProductReview | ProductRating | Array<ProductReview> | Array<ProductRating> | undefined
}

export interface ProductReview {
    customerName: string;
    productName: string;
    comment: string;
    orderNumber: string
    date: string
}

export interface ProductRating {
    id: string;
    name: string;
    averageRating: number; 
    displayId: string;
    media: Array<GenericMedia>;
}

export interface ReviewState {
    productReview: Array<ProductReview>
    productRating: Array<ProductRating>
    loading: boolean;
    selectedVendor: string;
};

export const REVIEW_SET_PRODUCTREVIEW = 'review_set_productreview';
export const RATING_SET_PRODUCTRATING = 'rating_set_productrating';

export type ReviewAction = AnyAction;