import React, { ChangeEvent, useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';

import {
  SymphonyInputLabelGridContainer,
  SymphonyTextField,
} from '../SymphonyCommonComponents';

interface LocationDetailsFieldProps {
  field: any;
  initialValue: any;
  onPopulateMapSearch: (value: string) => void;
  onLoctionDetailsChange: (value: any) => void;
}

type LocationInput = {
  province: string;
  city: string;
  sublocal: string;
  postalCode: string; // must be number only
};

const integerRegex: RegExp = /^(\s*|[1-9][0-9]*)$/;

function LocationDetailsField(props: LocationDetailsFieldProps) {
  const { field, onPopulateMapSearch, onLoctionDetailsChange, initialValue } =
    props;

  const [locationDetails, setLocationDetails] = useState<LocationInput>(() =>
    initialValue
      ? (initialValue as LocationInput)
      : {
          province: '',
          city: '',
          sublocal: '',
          postalCode: '', // must be number only
        },
  );

  const { province, city, sublocal, postalCode } = locationDetails;

  useEffect(() => {
    setLocationDetails({
      province: initialValue?.province || '',
      city: initialValue?.city || '',
      sublocal: initialValue?.sublocal || '',
      postalCode: initialValue?.postalCode || '',
    });
  }, [initialValue]);

  useEffect(() => {
    onPopulateMapSearch([province, city, sublocal, postalCode].join(' '));

    // update the actual entity
    onLoctionDetailsChange(locationDetails);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city, postalCode, province, sublocal]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setLocationDetails((curr) => ({
      ...curr,
      [e.target.name]: e.target.value,
    }));
  };

  const handlePostalCodeChange = (value: string) => {
    if (!integerRegex.test(value)) return;

    setLocationDetails((curr) => ({
      ...curr,
      postalCode: value,
    }));
  };

  return (
    <Box mb={4} display="flex" flexDirection="column" style={{ gap: 8 }}>
      <SymphonyInputLabelGridContainer item={true} xs={12}>
        {field.provinceLabel} <span style={{ color: 'red' }}>*</span>
      </SymphonyInputLabelGridContainer>
      <SymphonyTextField
        type="text"
        name="province"
        onChange={handleChange}
        value={locationDetails.province}
        fullWidth
      />
      <SymphonyInputLabelGridContainer item={true} xs={12}>
        {field.cityLabel} <span style={{ color: 'red' }}>*</span>
      </SymphonyInputLabelGridContainer>
      <SymphonyTextField
        type="text"
        name="city"
        onChange={handleChange}
        value={locationDetails.city}
        fullWidth
      />
      <SymphonyInputLabelGridContainer item={true} xs={12}>
        {field.sublocalLabel} <span style={{ color: 'red' }}>*</span>
      </SymphonyInputLabelGridContainer>
      <SymphonyTextField
        type="text"
        name="sublocal"
        onChange={handleChange}
        value={locationDetails.sublocal}
        fullWidth
      />
      <SymphonyInputLabelGridContainer item={true} xs={12}>
        {field.postalLabel} <span style={{ color: 'red' }}>*</span>
      </SymphonyInputLabelGridContainer>
      <SymphonyTextField
        type="text"
        name="postalCode"
        onChange={(e) => handlePostalCodeChange(e.target.value)}
        value={locationDetails.postalCode}
        fullWidth
      />
    </Box>
  );
}

export default LocationDetailsField;
