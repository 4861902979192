import React, { useEffect, useState } from 'react';
import {
    HeaderButton,
    ViewLoadingBox,
} from '../../Common/BasicCommonComponents';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { BasicPromotionMedia, DynamicBasicPromotionType, Promotion } from '../../../../store/promotion/types';
import { setPromotionState } from '../../../../store/promotion/actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import BasicInput from '../../Common/BasicInput';
import { MediaInputType } from '../../../../store/system/types';
import { Tier1Type, Tier2Type } from '../../../../store/product/types';
import moment from 'moment';
import find from 'lodash/find';
import { AppState } from '../../../../store';
import { connect, useSelector } from 'react-redux';
import { setSystemState } from '../../../../store/system/actions';
import { SYMPHONY_PRIMARY_COLOR } from '../../../symphony/Colors';
import { DecoratedPopoverButton, SymphonyHeaderButton, SymphonyViewCommonInfoContainer, SymphonyViewTab, SymphonyViewTabs, SymphonyViewTabsContainer } from '../../../symphony/SymphonyCommonComponents';
import useScrollableTabs from '../../../symphony/hooks/useScrollableTabs';
import { SalesSectionHeaderContainer, SalesSectionHeaderSubTitleContainer, SalesSectionHeaderTitleContainer } from '../../../sales/common/SalesCommonComponents';
import SymphonyInput from '../../../symphony/SymphonyInput';
import { Icon } from '@material-ui/core';
import MarketAuxMenu from '../../../market/common/MarketAuxMenu';
import BasicRadio from '../../Common/BasicRadio';
import PromotionProduct from './PromotionProduct';

interface PromotionViewProps {
    promotion?: Promotion
    loading: boolean;
    promotionSaving: boolean;
    onCancelClick: () => void;
    onPromotionInput: (field: string, val: DynamicBasicPromotionType) => void;
    onSaveClick: () => void;
    onDeletePromotionClick: (promotionId: string) => void;
    onMediaClick: (media: MediaInputType) => void;
    tierOneCategories: Array<Tier1Type>;
    tierTwoCategories: Array<Tier2Type>;
    tierThreeCategories: Array<string>;
    selectedTierOne: string;
    selectedTierTwo: string;
    selectedTierThree: string;
    fetchTierTwoCategories: (h1: string) => void;
    fetchTierThreeCategories: (h1: string, h2: string) => void;
    promotionInputState: (input: { [name: string]: DynamicBasicPromotionType }) => void;
    setSystemState: typeof setSystemState;
    setPromotionState: typeof setPromotionState;
}

const PromotionView = (props: PromotionViewProps) => {
    const { promotion, setSystemState } = props;
    const { activePromotionId } = useSelector((state: AppState) => state.promotion)
    const [bannerType, setBannerType] = useState<'Active' | 'On Going' | 'Coming Soon' | 'Inactive'>('Active');

    const sectionList = ['Promotion Information', 'Media'];
    const refs = [...sectionList.map(() => React.createRef<HTMLElement>()), React.createRef<HTMLElement>()];
    const [tab, setTab] = React.useState<string>(sectionList[0]);
    const [onTabClick, onScroll] = useScrollableTabs(refs, (target: string) => {
        if (target && sectionList.includes(target)) {
            setTab(target as string);
        }
    });
    const statusList = [{ label: 'Active', value: 'Active' }, { label: 'Inactive', value: 'Inactive' }];
    const statusOption = find(statusList, { value: promotion && promotion.isActive ? 'Active' : 'Inactive' });
    const fieldValue = statusOption ? statusOption.value : '';

    useEffect(() => {
        if (activePromotionId && promotion) {
            setSystemState({
                header: <Box display="flex">
                    <HeaderButton
                        style={{ minWidth: '15px', marginRight: '12px', padding: 0 }}
                        onClick={props.onCancelClick}
                    >
                        <ArrowBackIcon style={{ color: SYMPHONY_PRIMARY_COLOR }} />
                    </HeaderButton>
                    <Box fontSize="36px" style={{ color: 'black' }}>
                        {
                            promotion && promotion.title ? promotion.title : 'Create Promotion'
                        }
                    </Box>
                </Box>,
                headerEndButton: () => (
                    <div>
                        <SymphonyHeaderButton
                            id="add-news-btn"
                            onClick={props.onSaveClick}
                        >
                            Save
                        </SymphonyHeaderButton>
                    </div>
                ),
                shallRedirect: false,
                redirectTo: ''
            });
            props.setPromotionState({
                activePromotion: promotion
            })
        }
    }, [activePromotionId])

    useEffect(() => {
        if (promotion) {
            let endDate = moment.utc(moment(promotion && promotion.endDate, "DD/MM/YYYY").format())
            let startDate = moment.utc(moment(promotion && promotion.startDate, "DD/MM/YYYY").format())
            let currentDate = moment().utc();
            let differenceForEndDate = currentDate.diff(endDate, 'days');
            let differenceForStartDate = currentDate.diff(startDate, 'days');

            if (differenceForStartDate >= 0 && differenceForEndDate <= 0 && promotion.isActive) {
                setBannerType('On Going')
            } else if (differenceForStartDate < 0 && promotion.isActive) {
                setBannerType('Coming Soon')
            } else {
                setBannerType('Inactive')
            }
        }
    }, [promotion])

    return (
        <Box>
            {promotion &&
                <>
                    {props.loading ?
                        <ViewLoadingBox>
                            <CircularProgress />
                        </ViewLoadingBox>
                        :
                        <Box style={{ paddingLeft: '32px', paddingRight: '15px', paddingTop: '20px', display: 'flex' }}>
                            <SymphonyViewTabsContainer style={{ width: 170 }}>
                                <SymphonyViewTabs
                                    orientation="vertical"
                                    value={tab}
                                    TabIndicatorProps={{ style: { width: 4, backgroundColor: SYMPHONY_PRIMARY_COLOR } }}
                                >
                                    <SymphonyViewTab
                                        label="Promotion Information"
                                        value="Promotion Information"
                                        onClick={onTabClick}
                                    />
                                    <SymphonyViewTab
                                        label="Media"
                                        value="Media"
                                        onClick={onTabClick}
                                    />
                                </SymphonyViewTabs>
                            </SymphonyViewTabsContainer>
                            <SymphonyViewCommonInfoContainer onScroll={onScroll}>
                                <Box key="Promotion Information" style={{ marginBottom: '45px' }}>
                                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <SalesSectionHeaderContainer id="Promotion Information" innerRef={refs[0]}
                                            style={{ marginBottom: '35px' }}>
                                            <SalesSectionHeaderTitleContainer>
                                                Promotion Information
                                                {
                                                    promotion!.id.indexOf('PROMOTION') > 0 && <SalesSectionHeaderSubTitleContainer>
                                                        Last edited on {moment(promotion.dateUpdated as string).format('MMM. DD, YYYY [at] hh:mmA')}
                                                    </SalesSectionHeaderSubTitleContainer>
                                                }
                                            </SalesSectionHeaderTitleContainer>
                                        </SalesSectionHeaderContainer>
                                        {
                                            activePromotionId.indexOf('PROMOTION') > -1 && <MarketAuxMenu>
                                                <DecoratedPopoverButton
                                                    id="promotion-delete-btn"
                                                    style={{ color: '#FF4D4D' }}
                                                    endIcon={<Icon className="fa fa-trash-alt" />}
                                                    onClick={() => props.onDeletePromotionClick(props.promotion!.id)}
                                                >
                                                    Delete
                                                </DecoratedPopoverButton>
                                            </MarketAuxMenu>
                                        }
                                    </Box>
                                    <SymphonyInput
                                        type="text"
                                        label="Title"
                                        value={promotion.title}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                            props.onPromotionInput('title', e.target.value);
                                        }}
                                    />
                                    <SymphonyInput
                                        type="text"
                                        label="Subtitle"
                                        value={promotion.subTitle}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                            props.onPromotionInput('subTitle', e.target.value);
                                        }}
                                    />
                                    <SymphonyInput
                                        id="promotion-description-input"
                                        type="multiline"
                                        label="Promotion Content"
                                        richText={true}
                                        value={promotion.description}
                                        onRichTextInput={(html) => {
                                            props.onPromotionInput('description', html)
                                        }}
                                    />
                                    <PromotionProduct value={promotion.products} onSelectedChange={(products) => {
                                        props.onPromotionInput('products', products)
                                    }} />
                                    {
                                        promotion.isActive && <BasicInput
                                            label="Launch Date"
                                            type="daterange"
                                            value=""
                                            action={props.promotion!.id.indexOf('PROMOTION') > -1 ? 'update' : 'create'}
                                            dateRangeValueFrom={moment(promotion.startDate ? promotion.startDate : new Date(), 'DD/MM/YYYY')}
                                            dateRangeValueTo={moment(promotion.endDate ? promotion.endDate : new Date(), 'DD/MM/YYYY')}
                                            onDateChange={(type: 'startDate' | 'endDate', value) => {
                                                if (type === 'startDate') props.onPromotionInput('startDate', value);
                                                if (type === 'endDate') props.onPromotionInput('endDate', value);
                                            }}
                                        />
                                    }
                                    <BasicRadio label='Status'
                                        defaultValue={promotion.isActive ? "Active" : "Inactive"}
                                        options={[{ label: 'Active', value: 'Active' }, { label: 'Inactive', value: 'Inactive' }]}
                                        onRadioChange={(e, v) => {
                                            if (v) {
                                                props.onPromotionInput('isActive', v === 'Active' ? true : false)
                                            }
                                        }} />
                                    {
                                        promotion.isActive && <>
                                            {
                                                bannerType !== 'Inactive' ? <SalesSectionHeaderSubTitleContainer>
                                                    Currently {bannerType} {moment(promotion.startDate as string, 'DD/MM/YYYY').format('MMM. DD, YYYY [at] hh:mmA')}<br />
                                                    Ends on {moment(promotion.endDate as string, 'DD/MM/YYYY').format('MMM. DD, YYYY [at] hh:mmA')}
                                                </SalesSectionHeaderSubTitleContainer> : <SalesSectionHeaderSubTitleContainer>
                                                    This promotion is currently {bannerType}
                                                </SalesSectionHeaderSubTitleContainer>
                                            }
                                        </>
                                    }
                                </Box>
                                <SalesSectionHeaderContainer id="Media" innerRef={refs[refs.length - 1]}>
                                    <SalesSectionHeaderTitleContainer>
                                        Media
                                    </SalesSectionHeaderTitleContainer>
                                </SalesSectionHeaderContainer>
                                <Box key="Media" style={{ marginTop: '25px' }}>
                                    <BasicInput
                                        label="Images/Banner"
                                        type="mediainput"
                                        mediaLabel="Add Photo"
                                        mediaType="IMAGE"
                                        disableMediaLabel={true}
                                        value={promotion.images}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                            props.onPromotionInput('media', (e as React.ChangeEvent<HTMLInputElement>))
                                        }}
                                        mediaClick={props.onMediaClick}
                                    />
                                    <BasicInput
                                        label="Videos"
                                        type="mediainput"
                                        mediaLabel="Add Video"
                                        mediaType="VIDEO"
                                        disableMediaLabel={true}
                                        value={promotion.videos}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                            props.onPromotionInput('media', (e as React.ChangeEvent<HTMLInputElement>))
                                        }}
                                        mediaClick={props.onMediaClick}
                                    />
                                    <BasicInput
                                        label="PDF/Brochure"
                                        type="mediainput"
                                        mediaLabel="Add Files"
                                        mediaType="PDF"
                                        disableMediaLabel={true}
                                        value={promotion.brochures}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                            props.onPromotionInput('media', (e as React.ChangeEvent<HTMLInputElement>))
                                        }}
                                        mediaClick={props.onMediaClick}
                                    />
                                </Box>
                            </SymphonyViewCommonInfoContainer>

                            {/* {props.promotion!.id.indexOf('PROMOTION') > -1 &&
                                <Box style={{ margin: 48, display: 'flex', justifyContent: 'center', alignItems: 'center', boxSizing: 'border-box' }}>
                                    <BasicDeleteButton onClick={() => { props.onDeletePromotionClick(props.promotion!.id) }}>
                                        Delete
                                    </BasicDeleteButton>
                                </Box>
                            } */}

                        </Box>
                    }
                </>
            }
        </Box>
    )
}

export default connect(null, {
    setSystemState,
    setPromotionState
})(PromotionView);