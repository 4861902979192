import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircleIcon from '@material-ui/icons/FiberManualRecord';

import { dataColors } from './util/chart-utils';
import { formatNumber, toPercentage } from '../../lib/helpers';

const { brown, darkGray, mediumGray, textColor } = dataColors;

interface OrderHistoryTallyProps {
    type: 'Received' | 'Cancelled' | 'Failed Delivery';
    total: number;
    value: number;
}

const colorMap: Record<OrderHistoryTallyProps['type'], string> = {
    Received: brown,
    Cancelled: darkGray,
    'Failed Delivery': mediumGray,
};

const OrderHistoryTally = (props: OrderHistoryTallyProps) => {
    const { type, total, value } = props;

    return (
        <Box display="flex" style={{ gap: 12 }}>
            <CircleIcon
                style={{ color: colorMap[type], fontSize: 16, marginTop: 1 }}
            />
            <Box>
                <Typography style={{ fontSize: 14, color: textColor }}>
                    {type}
                </Typography>
                <Typography style={{ fontSize: 15, fontWeight: 600 }}>
                    {formatNumber(value)} ({toPercentage(value, total)})
                </Typography>
            </Box>
        </Box>
    );
};

export default OrderHistoryTally;
