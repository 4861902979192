import React from 'react';

import ManageOrderReport from './ManageOrderReport';
import OrderHistoryReport from './OrderHistoryReport';
import ReportContainer from './ReportContainer';

const OrderStatusView = () => {
    return (
        <ReportContainer
            style={{
                display: 'grid',
                gridTemplateColumns: '1fr 400px',
                alignItems: 'flex-start',
                columnGap: 24,
            }}>
            <ManageOrderReport />
            <OrderHistoryReport />
        </ReportContainer>
    );
};

export default OrderStatusView;
