import { styled } from '@material-ui/core';

const ReportContainer = styled('div')({
    maxWidth: '100%',
    height: 'calc(100vh - 180px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: 32,
    boxSizing: 'border-box',
});

export default ReportContainer;
