import { ChartOptions } from 'chart.js';

export const dataColors = {
    blue: '#2E79FF',
    red: '#EF767A',
    brown: '#B57937',
    teal: '#49BEAA',
    green: '#49DCB1',
    orange: '#EEB868',
    darkGray: '#5E6A7E',
    mediumGray: '#E1E7EF',
    lightGray: '#EDEDED',
    textColor: '#959595',
};

// manage order bar chart
export const manageOrderChartOptions = (
    data: number[],
): ChartOptions<'bar'> => ({
    responsive: true,

    datasets: {
        bar: {
            barThickness: 12,
            borderRadius(ctx, options) {
                return {
                    topLeft: 6,
                    topRight: 6,
                    bottomLeft: 0,
                    bottomRight: 0,
                };
            },
        },
    },

    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            enabled(ctx, options) {
                return true;
            },
            callbacks: {
                label(tooltipItems) {
                    if (tooltipItems.datasetIndex === 1) return '';
                    const total = (
                        tooltipItems.dataset.data as number[]
                    ).reduce((a, c) => a + c, 0);

                    const percent =
                        ((tooltipItems.raw as number) / total) * 100;

                    return `${tooltipItems.formattedValue} (${percent.toFixed(
                        2,
                    )}%)`;
                },
            },
        },
    },
    scales: {
        y: {
            ticks: {
                font: {
                    size: 14,
                },
                color: dataColors.textColor,
                count: 5,
            },
        },
        x: {
            stacked: true,
            ticks: {
                font: {
                    size: 13.5,
                },
                color: dataColors.textColor,
            },
        },
        'total-x-axis': {
            position: 'top',
            ticks: {
                callback(tickValue, index, ticks) {
                    const total = data.reduce((a, c) => a + c, 0);
                    const percent = (data[index] / total) * 100;
                    return isNaN(percent)
                        ? '0.00%'
                        : `(${percent.toFixed(2)}%)`;
                },
            },
        },
    },
});

// Order history
export const orderHistoryChartOptions: ChartOptions<'doughnut'> = {
    responsive: true,
    aspectRatio: 1,
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            xAlign: 'center',
            yAlign: 'center',
            callbacks: {
                label(tooltipItems) {
                    if (tooltipItems.datasetIndex === 1) return '';
                    const total = (
                        tooltipItems.dataset.data as number[]
                    ).reduce((a, c) => a + c, 0);

                    const percent =
                        ((tooltipItems.raw as number) / total) * 100;

                    return `${tooltipItems.label} (${percent.toFixed(2)}%)`;
                },
            },
        },
    },
    cutout: '70%',
};

// filter options
export const monthOptions = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
];

const yearNow = new Date().getFullYear();

export const yearOptions = Array.from(Array(yearNow - 2018).keys())
    .map((n) => (yearNow - n).toString())
    .reverse();
