import React from 'react';
import { BannerSelectedCategoryType, HierarchyOne } from '../../../../store/promobanner/types';

// Material UI
import IconButton from '@material-ui/core/IconButton';

// Local Components
import {
    PromoBannerModal,
    PromoBannerModalContentContainer,
    PromoBannerModalLabel,
    PromoBannerModalLabelContainer,
    PromoBannerModalCloseIcon,
    PromoBannerClearAllIcon,
    PromoBannerModalCategoryListContainer
} from './PromoBannerCommonComponents';
import SelectedCategory from './PromoBannerSelectedCategory';
import TierOneRow from './TierOneRow';

// Utils
import map from 'lodash/map';
import find from 'lodash/find';
import { Box, Grid } from '@material-ui/core';
import { SymphonyHeaderButton } from '../../../symphony/SymphonyCommonComponents';
import Remove from '@material-ui/icons/Remove';

interface PromoBannerCategoryModalProps {
    open: boolean;
    tierOneCategories: Array<HierarchyOne>;
    selectedCategory: Array<BannerSelectedCategoryType>;
    addToSelectedCategory: (selectedCategory: Array<BannerSelectedCategoryType>) => void;
    onPressCloseModal: () => void;
}

const PromoBannerCategoryModal = (props: PromoBannerCategoryModalProps) => {

    React.useEffect(() => {

    }, [props])

    return (
        <PromoBannerModal
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', outline: 'none' }}
            open={props.open}
        >
            <PromoBannerModalContentContainer>
                <PromoBannerModalLabelContainer>
                    <PromoBannerModalLabel>Select Category</PromoBannerModalLabel>
                    <Box style={{display: 'flex', justifyContent: 'end'}}>
                        <IconButton
                            onClick={() => props.addToSelectedCategory([])}
                        >
                            <PromoBannerClearAllIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => props.onPressCloseModal()}
                        >
                            <PromoBannerModalCloseIcon />
                        </IconButton>
                    </Box>
                </PromoBannerModalLabelContainer>
                <Grid container >
                    <Grid item xs={6} style={{ height: '57vh', overflow: 'auto' }}>
                        <PromoBannerModalCategoryListContainer>
                            {
                                map(props.tierOneCategories, (t1) => {
                                    const isSelected = find(props.selectedCategory, (selectedCat) => {
                                        if (typeof selectedCat.h2 === 'undefined' && typeof selectedCat.h3 === 'undefined' && selectedCat.h1 === t1.name) {
                                            return selectedCat
                                        }
                                    });
                                    return (
                                        <TierOneRow
                                            key={`promobanner-tierone-row-${t1.name}`}
                                            name={t1.name}
                                            thumbnail={t1.h1Thumbnail}
                                            h2={t1.h2}
                                            selectedCategory={props.selectedCategory}
                                            addToSelectedCategory={props.addToSelectedCategory}
                                            customStyle={{ backgroundColor: isSelected ? '#00000029' : '#FFF' }}
                                        />
                                    )
                                })
                            }
                        </PromoBannerModalCategoryListContainer>
                    </Grid>
                    <Grid item xs={6}>
                        <SelectedCategory selectedCategory={props.selectedCategory} />
                    </Grid>
                </Grid>
            </PromoBannerModalContentContainer>
        </PromoBannerModal>
    )
}

export default PromoBannerCategoryModal;