export const SYMPHONY_PRIMARY_COLOR = '#B57936';
export const SYMPHONY_SECONDARY_COLOR_DARK = '#070606';
export const SYMPHONY_SECONDARY_COLOR_LIGHT = '#FFFFFFF';

const colors = {
    SYMPHONY_PRIMARY_COLOR,
    SYMPHONY_SECONDARY_COLOR_DARK,
    SYMPHONY_SECONDARY_COLOR_LIGHT
};

export default colors;