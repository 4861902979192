import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Typography } from '@material-ui/core';
import { ReviewsCardHeader } from './ReviewComponents';
import StarRating from './StarRating';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: 'absolute',
            width: 350,
            backgroundColor: theme.palette.background.paper,
            border: 'none',
            padding: theme.spacing(2, 4, 3),
            outline: 'none'
        },
    }),
);

interface IReview {
    id: string;
    rating: number;
    orderNumber: string;
    orderDate: string;
    customerName: string;
    message: string;
}


interface IModal {
    show: boolean;
    toggle: (c: boolean) => void;
    data: IReview | null;
}

export default function SimpleModal({ show, toggle, data }: IModal) {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);

    const handleOpen = () => {
        toggle(true);
    };

    const handleClose = () => {
        toggle(false);
    };

    return (
        <div>
            <Modal
                open={show}
                onClose={handleClose}
            >
                <div style={modalStyle} className={classes.paper}>
                    <ReviewsCardHeader>
                        <StarRating size='small' rating={4.5} />
                        <Typography style={{ fontSize: '0.8rem', fontWeight: 600 }}>
                            #{
                                data?.orderNumber
                            }
                        </Typography>
                    </ReviewsCardHeader>
                    <Typography color='textSecondary' style={{ fontSize: '0.8rem' }}>
                        {
                            data?.customerName
                        },&nbsp;
                        {
                            data?.orderDate
                        }
                    </Typography>
                    <Typography style={{
                        fontSize: '0.8rem', paddingTop: '15px', maxWidth: '100%',
                    }}>
                        {
                            data?.message
                        }
                    </Typography>
                </div>
            </Modal>
        </div>
    );
}
