import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { RightInputContainer } from '../../promobanner/fragments/PromoBannerCommonComponents';
import SymphonyInput from '../../../symphony/SymphonyInput';
import { SymphonyHeaderAuxButton, SymphonyHeaderButton } from '../../../symphony/SymphonyCommonComponents';
import AddIcon from '@material-ui/icons/Save';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: 'absolute',
            width: 400,
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(2, 4, 3),
            '&:focus-visible': {
                outline: 'none'
            }
        },
    }),
);

interface ModalProps {
    open: boolean;
    setOpen: (open) => void;
    setTracking: (id) => void;
    onOrderUpdate: (id: string, status: string) => void;
    trackingNumber: string;
    data: { id: string, status: any };
}

export default function TrackingNumberModal(props: ModalProps) {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const { open, setOpen, setTracking, trackingNumber, onOrderUpdate, data } = props;

    const handleClose = () => {
        setOpen(false);
        setTracking('')
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <div style={modalStyle} className={classes.paper}>
                    <h2>Enter Tracking Number</h2>
                    <RightInputContainer>
                        <SymphonyInput
                            id="tracking-number"
                            type="number"
                            label="Tracking Number"
                            value={trackingNumber}
                            onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => { setTracking(e.target.value) }}
                        />
                    </RightInputContainer>

                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <SymphonyHeaderAuxButton
                            onClick={handleClose}
                        >
                            Cancel
                        </SymphonyHeaderAuxButton>
                        <SymphonyHeaderButton
                            id="submit-btn"
                            startIcon={<AddIcon />}
                            onClick={() => onOrderUpdate(data.id, data.status)}
                        >
                            Save
                        </SymphonyHeaderButton>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
