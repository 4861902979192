import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, styled } from '@material-ui/core/styles';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { Box, FormControlLabel } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store';
import { setLoginState } from '../../store/login/actions';

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    label: {
        fontSize: 12,
    },
    icon: {
        borderRadius: 3,
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#eee',
        'input:hover ~ &': {
            backgroundColor: '#eee',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#A38E66',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#A38E66',
        },
    },
});

const formControlStyles = makeStyles({
    label: {
        fontSize: 12
    }
})


export default function RememberMe(props: CheckboxProps) {
    const classes = useStyles();
    const labelStyles = formControlStyles();
    const { email, password } = useSelector((state: AppState) => state.login);
    const [user, setUsername] = useState<any>(localStorage.getItem('persist:remember'))
    const dispatch = useDispatch();

    useEffect(() => {
        if (user) {
            dispatch(setLoginState({ rememberMe: true, email: JSON.parse(user).username, password: JSON.parse(user).password }))
        } else {
            dispatch(setLoginState({ rememberMe: false }))
        }
    }, [dispatch, user])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(e.target.checked) {
            if (!!email) localStorage.setItem('persist:remember', JSON.stringify({ username: email.trim(), password: password }))
            setUsername(JSON.stringify({ username: email.trim(), password: password }))
        } else {
            localStorage.removeItem('persist:remember')
            setUsername(null)
        }
    }

    return (
        <Box style={{
            fontSize: 12, alignItems: 'center', display: 'flex',
            width: '100%', padding: 0, marginLeft: 5,
            marginTop: -10, marginBottom: 10
        }}>
            <FormControlLabel
                classes={labelStyles}
                control={<Checkbox
                    className={classes.root}
                    disableRipple
                    color="default"
                    checked={!!user}
                    checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                    icon={<span className={classes.icon} />}
                    {...props}
                    onChange={handleChange}
                />}
                label="Remember Me"
            />
        </Box>
    );
}
