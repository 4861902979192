import React from 'react';
import { useHistory } from 'react-router';

import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { appColors } from './constants';

function BackButton({ backTo }: { backTo: string }) {
    const history = useHistory();
    return (
        <IconButton
            aria-label="go back"
            style={{ marginRight: 8 }}
            onClick={() => history.replace(backTo)}>
            <ArrowBackIcon style={{ color: appColors.primary }} />
        </IconButton>
    );
}

export default BackButton;
