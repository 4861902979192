import axios, { AxiosError } from 'axios';
import moment from 'moment';
import { AppState, AppThunk } from '../../index';
import {
    TRProductAction,
    TRProductProductReportState,
    Status,
    LOAD_TR_PRODUCTS_REPORT,
    SET_TR_PRODUCTS_ERROR,
    SET_TR_PRODUCTS_FILTER,
    SET_TR_PRODUCTS_STATUS
} from './types'

const API_URL = process.env.REACT_APP_API_URL;

const manageTRProductsInitState:TRProductProductReportState = {
    stateStatus:'idle',
    error:null,
    data:[],
    filter:{
        month: moment().format('MMM'),
        year: moment().format('YYYY'), 
    }
}

export function manageTRPReducer(
    state = manageTRProductsInitState,
    action: TRProductAction
):TRProductProductReportState{
    switch (action.type) {
        case SET_TR_PRODUCTS_STATUS:
            return {
                ...state,
                stateStatus:action.payload
            };
        case LOAD_TR_PRODUCTS_REPORT:
            return {
                ...state,
                data:action.payload,
                error:null,
                stateStatus:'idle'
            }
        case SET_TR_PRODUCTS_FILTER:
            return {
                ...state,
                filter: action.payload
            };
        case SET_TR_PRODUCTS_ERROR:
            return {
                ...state,
                error: action.payload,
                stateStatus: 'idle'
            };   
    
        default:
           return state;
    };
};

//Sync Action

export const setTRProductStatus = (status: Status): TRProductAction => {
    return {
        type: SET_TR_PRODUCTS_STATUS,
        payload: status
    }
}

export const setTRProductError = (error: string | null): TRProductAction => {
    return {
        type: SET_TR_PRODUCTS_ERROR,
        payload: error
    }
}

export const loadTRProduct = ( reportData : TRProductProductReportState['data']): TRProductAction => {
    return {
        type: LOAD_TR_PRODUCTS_REPORT,
        payload:reportData
    }
}

export const setTRProductFilter = ( reportFilter: TRProductProductReportState['filter']): TRProductAction => {
    return {
        type: SET_TR_PRODUCTS_FILTER,
        payload: reportFilter
    };
};

// Async Actions

export const getTopRatedReport = (
    filter: TRProductProductReportState['filter'],
    callback?: () => void
): AppThunk =>
async (dispatch, getState) => {
    try {
        dispatch(setTRProductStatus('loading'));
        const {login} = getState();
                
        const { id: vendorId, countryCode } = login.user;

        const month = moment().month(filter.month).format('MM');
        const year = filter.year;

        const response = await axios.get<TRProductProductReportState['data']>(
            `${API_URL}/v1/reports/market/top-ratings?vendorId=${vendorId}&countryCode=${countryCode}&month=${month}&year=${year}`,
        );

        dispatch(loadTRProduct(response.data));
        dispatch(setTRProductStatus('success'))
    } catch (error) {
        dispatch(setTRProductError('error'));
        let err = 'An unknown error has occured';

        if (error instanceof Error) {
            err = error.message;
        }

        if ((error as any)?.response?.data) {
            err = (error as AxiosError).response?.data?.error?.message;
        }
        dispatch(setTRProductError(err))
    };
};

export const selectTRFilter = (state:AppState) => state.topRatedProductsReport.filter;

export const selectTRPState = (state:AppState) => state.topRatedProductsReport;

export const selectTRPData = (state:AppState) => state.topRatedProductsReport.data.sort((a,b) => a.average_rating - b.average_rating).slice(0,5);