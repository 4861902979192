import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number } & { label: string }) {
  return (
    <Box display="flex" alignItems="center" style={{ 
        flex: '1 1 0px' }}>
      <Box width={45} marginRight={3}>
        <Typography style={{ color: '#959595', fontSize: '0.8rem' }}>{props.label}</Typography>
      </Box>
      <Box width="300px">
        <LinearProgress variant="determinate" {...props} color='primary'
            style={{ borderRadius: '15px', height: '7px', backgroundColor: '#ededed' }} />
      </Box>
    </Box>
  );
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

interface IProgress {
    value: number;
    label: string;
}

export default function LinearWithValueLabel({ value, label }: IProgress) {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(value);

  return (
    <div className={classes.root}>
      <LinearProgressWithLabel value={progress} label={label} color='secondary'/>
    </div>
  );
}
