import React from 'react';
import { makeStyles, Theme, createStyles, styled } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Box, Typography } from '@material-ui/core';
import SymphonyInput from '../symphony/SymphonyInput';
import BrandButton from './BrandButton';
import { changePassword, setLoginState } from '../../store/login/actions'
import { useDispatch } from 'react-redux';
import { LoginState } from '../../store/login/types';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: 'absolute',
            width: 350,
            backgroundColor: theme.palette.background.paper,
            border: 'none',
            padding: theme.spacing(2, 4, 3),
            outline: 'none'
        },
    }),
);

const FooterAction = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'end',
    columnGap: 10,
    paddingTop: 10
}));


interface IModal {
    show: boolean;
    toggle: (c: boolean) => void;
    data: LoginState;
}

export default function SimpleModal({ show, toggle, data }: IModal) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [modalStyle] = React.useState(getModalStyle);
    const passRegex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

    const handleOpen = () => {
        toggle(true);
    };

    const handleClose = () => {
        toggle(false);
    };

    const handleInput = (field: string, payload: string) => {
        dispatch(setLoginState({ [field]: payload.toString() }));
        if (field === 'newPassword') {
            dispatch(setLoginState({ newPasswordStrengthValid: passRegex.test(payload) }));
        }
    }

    const handleSave = () => {
        dispatch(changePassword(
            data.user.id,
            data.currentPassword,
            data.newPassword,
            () => {
                handleClose();
            }
        ))
    }

    return (
        <div>
            <Modal
                open={show}
                onClose={handleClose}
            >
                <div style={modalStyle} className={classes.paper}>
                    <Typography variant='h6' style={{ marginBottom: '15px' }}>Change Password</Typography>
                    <SymphonyInput
                        type="password"
                        label="Current Password"
                        value={data.currentPassword}
                        onChange={(e) => handleInput('currentPassword', e.target.value)}
                    />
                    <SymphonyInput
                        type="password"
                        label="New Password"
                        value={data.newPassword}
                        onChange={(e) => handleInput('newPassword', e.target.value)}
                    />
                    <SymphonyInput
                        type="password"
                        label="Retype New Password"
                        value={data.confirmPassword}
                        onChange={(e) => handleInput('confirmPassword', e.target.value)}
                    />
                    <Typography hidden={(data.newPassword == data.confirmPassword)} variant='caption' style={{ marginBottom: '15px', color: 'red', fontStyle: 'italic' }}>New password inputs do not match</Typography>

                    <FooterAction>
                        <BrandButton brand='outlined' onClick={() => toggle(false)}>Cancel</BrandButton>
                        <BrandButton disabled={!(data.newPassword == data.confirmPassword)} style={{ margin: 0 }} onClick={handleSave}>Save</BrandButton>
                    </FooterAction>
                </div>
            </Modal>
        </div>
    );
}
