import { styled } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import { appColors } from '../constants';

const AMITableCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-head': {
    backgroundColor: appColors.bluishWhite,
    color: appColors.dark,
    fontSize: 13.5,
    fontWeight: 600,
    backfaceVisibility: 'hidden',
    whiteSpace: 'nowrap',
    borderBottom: 'none',
    padding: '20px 16px',
  },
}));

export const AMIDataCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-body': {
    color: appColors.dark,
    fontSize: 13.5,
    whiteSpace: 'nowrap',
    borderBottom: `1px solid ${appColors.bluishWhite}`,
    padding: '20px 16px',
  },
}));

export default AMITableCell;
