import axios, { AxiosError } from 'axios';
import moment from 'moment';
import { AppState, AppThunk } from '../../index';
import {
    TotalFiveProductAction,
    TopFiveProductReportState,
    Status,
    LOAD_TOP_FIVE_PRODUCTS_REPORT,
    SET_TOP_FIVE_PRODUCTS_ERROR,
    SET_TOP_FIVE_PRODUCTS_FILTER,
    SET_TOP_FIVE_PRODUCTS_STATUS
} from './types';

const API_URL = process.env.REACT_APP_API_URL;

const manageTopFiveProductsInitState: TopFiveProductReportState ={
    stateStatus: 'idle',
    error: null,
    data:{
        products:[],
        total:0
    },
    filter: {
        month: moment().format('MMM'),
        year: moment().format('YYYY'),
    }
};

export function manageTopFiveProductsReducer(
    state =  manageTopFiveProductsInitState,
    action:  TotalFiveProductAction
):TopFiveProductReportState{
    switch(action.type){

        case SET_TOP_FIVE_PRODUCTS_STATUS:
            return {
                ...state,
                stateStatus:action.payload
            };
        case LOAD_TOP_FIVE_PRODUCTS_REPORT:
            return {
                ...state,
                data:action.payload,
                error:null,
                stateStatus:'idle'
            };
        case SET_TOP_FIVE_PRODUCTS_FILTER:
            return {
                ...state,
                filter: action.payload
            };
        case SET_TOP_FIVE_PRODUCTS_ERROR:
            return {
                ...state,
                error: action.payload,
                stateStatus: 'idle'
            };
        default:
            return state;
    };
 };

 // Sync Action
export const setTopFiveStatus = (status: Status): TotalFiveProductAction => {
    return {
        type: SET_TOP_FIVE_PRODUCTS_STATUS,
        payload: status
    }
}

export const setTopFiveError = (error: string | null): TotalFiveProductAction => {
    return {
        type: SET_TOP_FIVE_PRODUCTS_ERROR,
        payload: error
    }
}

export const loadTopFive = ( reportData : TopFiveProductReportState['data']): TotalFiveProductAction => {
    return {
        type: LOAD_TOP_FIVE_PRODUCTS_REPORT,
        payload:reportData
    }
}

export const setTopFiveFilter = ( reportFilter: TopFiveProductReportState['filter']): TotalFiveProductAction => {
    return {
        type: SET_TOP_FIVE_PRODUCTS_FILTER,
        payload: reportFilter
    };
};

// Async Actions
export const getTopFiveReport = ( 
    filter: TopFiveProductReportState['filter'],
    callback?: () => void
): AppThunk =>
async (dispatch, getState) => {
    try {
        dispatch(setTopFiveStatus('loading'));

        const {login} = getState();

        const { id: vendorId, countryCode } = login.user;

        const month = moment().month(filter.month).format('MM');
        const year = filter.year;

        const response = await axios.get<TopFiveProductReportState['data']>(
            `${API_URL}/v1/reports/market/top-five?vendorId=${vendorId}&countryCode=${countryCode}&month=${month}&year=${year}`,
        );
       
        dispatch(loadTopFive(response.data))

        dispatch(setTopFiveStatus('success'));

        if(callback) callback();
    } catch (error) {
        dispatch(setTopFiveStatus('error'));

        let err = 'An unknown error has occured';

        if (error instanceof Error) {
            err = error.message;
        }

        if ((error as any)?.response?.data) {
            err = (error as AxiosError).response?.data?.error?.message;
        }

        dispatch(setTopFiveError(err))
    }
};

export const selectTopFiveFilter = (state: AppState) => state.topFiveProductsReport.filter;

export const selectTopFiveState = ( state: AppState) => state.topFiveProductsReport;

export const selectTopFiveData = (state: AppState) => state.topFiveProductsReport.data;