import * as React from 'react';
import _ from 'lodash';
import { Button, Menu, MenuItem } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../store';
import { RATING_SET_PRODUCTRATING } from '../../../../store/review/types';
import { setProductRatingState } from '../../../../store/review/actions';

interface IReview {
    id: string;
    rating: number;
    orderNumber: string;
    orderDate: string;
    customerName: string;
    message: string;
    images: Array<{ path: string; }>
}

interface ISortData {
    data?: Array<IReview>
    sorted?: (sortedData: Array<IReview>) => void;
    module: string;
}

export default function SortDropdown({ data, sorted, module }: ISortData) {
    const { productRating } = useSelector((state: AppState) => state.review)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [action, setAction] = React.useState('name');
    const open = Boolean(anchorEl);
    const dispatch = useDispatch();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSort = (sortBy: string) => {
        if (data) {
            if (sorted) {
                if (sortBy == 'atoz' && sortBy !== action) {
                    sorted(_.orderBy(data, [customer => customer.customerName.toLowerCase()], ['asc']))
                }
                if (sortBy == 'ztoa' && sortBy !== action) {
                    sorted(_.orderBy(data, [customer => customer.customerName.toLowerCase()], ['desc']))
                }
                if (sortBy == 'high-rating' && sortBy !== action) {
                    sorted(_.orderBy(data, ['rating']).reverse())
                }
                if (sortBy == 'low-rating' && sortBy !== action) {
                    sorted(_.orderBy(data, ['rating']))
                }
                if (sortBy == 'name' && sortBy !== action) {
                    sorted(_.orderBy(data, [(data => data.customerName.toLowerCase())]))
                }
                if (sortBy == 'order' && sortBy !== action) {
                    sorted(_.orderBy(data, [(data => data.id.toLowerCase())], ['asc']))
                }
                if (sortBy == 'date' && sortBy !== action) {
                    sorted(_.orderBy(data, [(data => data.orderDate.toLowerCase())]))
                }
            }
        } else {
            if (sortBy == 'atoz' && sortBy !== action) {
                dispatch(setProductRatingState({ productRating: _.orderBy(productRating, [product => product.name.toLowerCase()], ['asc']) }))
            }
            if (sortBy == 'ztoa' && sortBy !== action) {
                dispatch(setProductRatingState({ productRating: _.orderBy(productRating, [product => product.name.toLowerCase()], ['desc']) }))
            }
            if (sortBy == 'high-rating' && sortBy !== action) {
                dispatch(setProductRatingState({ productRating: _.orderBy(productRating, ['averageRating']) }))
            }
            if (sortBy == 'low-rating' && sortBy !== action) {
                dispatch(setProductRatingState({ productRating: _.orderBy(productRating, ['averageRating']).reverse() }))
            }
        }
        setAction(sortBy)
        setAnchorEl(null);
    }

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                size="small"
                style={{ background: 'transparent', color: 'black', marginLeft: '5px', textTransform: 'none' }}
                onClick={handleClick}
            >
                Sort <KeyboardArrowDownIcon fontSize='small' />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => handleSort('high-rating')}>High Rating</MenuItem>
                <MenuItem onClick={() => handleSort('low-rating')}>Low Rating</MenuItem>
                {
                    module && module !== 'Reviews' ? <>
                        <MenuItem onClick={() => handleSort('atoz')}>A to Z</MenuItem>
                        <MenuItem onClick={() => handleSort('ztoa')}>Z to A</MenuItem>

                    </> : <>
                        <MenuItem onClick={() => handleSort('name')}>Name</MenuItem>
                        <MenuItem onClick={() => handleSort('order')}>Order</MenuItem>
                        <MenuItem onClick={() => handleSort('date')}>Date</MenuItem>
                    </>
                }
            </Menu>
        </div>
    );
}
