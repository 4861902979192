import React, { useCallback, useEffect, useState } from 'react';


import { getProducts } from '../../../../store/basicproduct/actions';
import { BasicProduct } from '../../../../store/basicproduct/types';
import { useDispatch, useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { styled } from '@material-ui/styles';
import { AppState } from '../../../../store';

const SelectContainer = styled(AsyncSelect)(({ theme }) => ({
    fontSize: 12,
    fontWeight: 500,
    '.select__control': {
        border: 'none !important'
    }
}));

interface Product extends BasicProduct {
    label: string;
    value: string;
  }

interface IPromotionProduct {
    onSelectedChange: (product: Array<BasicProduct>) => void;
    value: Array<BasicProduct>;
}

const PromotionProduct = ({ onSelectedChange, value }: IPromotionProduct) => {
    let { products, productsLoading } = useSelector((state: AppState) => state.basicproduct)
    const [selectedOptions, setSelectedOptions] = useState<Array<BasicProduct>>([])
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getProducts('list', '', true));
    }, [dispatch])

    useEffect(() => {
        onSelectedChange(selectedOptions)
    }, [selectedOptions])

    const filterProducts = (inputValue: string) => {
        return products.filter((i) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };
    // debounce search
    const loadOptions = (
        inputValue: string,
        callback: (options: Product[]) => void
    ) => {
        setTimeout(() => {
            callback(filterProducts(inputValue));
        }, 1000);
    };

    const handleInputChange = (newValue: string) => newValue

      const handleSelection = useCallback((options: any) => {
        setSelectedOptions(options)
      }, [selectedOptions])

    return (
        <div style={{ marginBottom: '25px' }}>
            <div style={{ fontSize: 12, color: '#959595', marginBottom: '7px' }}>Promotion Product</div>
            <SelectContainer
                defaultValue={value}
                cacheOptions
                loadOptions={loadOptions}
                defaultOptions
                isLoading={productsLoading}
                isMulti
                onInputChange={handleInputChange}
                onChange={handleSelection}
            />
        </div>
    )
}


export default PromotionProduct;