import React, { useState, createRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';

import { ToastContainer } from 'react-toastify';
import moment from 'moment';

import { AppModuleContainer } from '../../lib/AppModuleContainer';
import { toProperCase, useClearHeader, validateEmail } from '../../lib/helpers';
import { appColors } from '../../lib/constants';
import BackButton from '../../lib/BackButton';

import {
  useCreateLead,
  useGetLeadsById,
  useLeadsModuleFields,
  useUpdateLead,
  useDeleteLead,
} from './leads-hooks';
import useScrollableTabs from '../../symphony/hooks/useScrollableTabs';
import {
  SymphonyViewTabsContainer,
  SymphonyViewTabs,
  SymphonyViewTab,
  SymphonyViewContentContainer,
  SymphonyViewCommonInfoContainer,
  SymphonySectionHeaderContainer,
  SymphonySectionHeaderTitleContainer,
  SymphonySectionHeaderSubTitleContainer,
  DecoratedPopoverButton,
} from '../../symphony/SymphonyCommonComponents';
import SymphonyModuleFieldRenderer from '../../symphony/SymphonyModuleFieldRenderer';
import { CustomerLeadInputs } from './types';
import { toastWarning } from '../../../modules/Toast';
import { setSystemState } from '../../../store/system/actions';
import SalesAuxMenu from '../common/SalesAuxMenu';

const buttonStyles: React.CSSProperties = {
  backgroundColor: appColors.primary,
  textTransform: 'capitalize',
  color: 'white',
  fontWeight: 600,
  padding: 11,
  minWidth: 120,
  height: 48,
  fontSize: 14,
  marginLeft: 'auto',
};

const LeadsFormHead = ({
  loading,
  onSave,
  isEdit,
}: {
  isEdit?: boolean;
  loading: boolean;
  onSave: () => void;
}) => {
  return (
    <Box
      bgcolor={appColors.bluishWhite}
      display="flex"
      alignItems="center"
      pl={2}
      pr={4}
      py={2}>
      <BackButton backTo="/sales/leads" />
      <Typography style={{ fontSize: 36, fontWeight: 'bold' }}>
        {isEdit ? 'Edit Leads' : 'New Leads'}
      </Typography>
      <Button
        onClick={onSave}
        disabled={loading}
        disableElevation
        size="large"
        style={buttonStyles}>
        Save
      </Button>
    </Box>
  );
};

const LeadsForm = ({ isEdit }: { isEdit?: boolean }) => {
  // I need this to overwrite the header implementation!
  useClearHeader();

  const dispatch = useDispatch();

  const leadsFields = useLeadsModuleFields();

  const leadsToEdit = useGetLeadsById({ enabled: isEdit ? true : false });

  const sections = leadsFields.data?.sections ?? [];

  const refs = sections.map(() => createRef<HTMLElement>());

  const [tab, setTab] = useState('Lead Information');

  const [onTabClick, onScroll] = useScrollableTabs(refs, (target: string) => {
    if (target && sections.includes(target)) {
      setTab(target as string);
    }
  });

  const [customerLead, setCustomerLead] = useState<CustomerLeadInputs>({
    businessName: '',
    contactPersonName: '',
    email: '',
    contactNumber: '',
    avatar: undefined,
    leadScore: 0,
  });

  useEffect(() => {
    if (leadsToEdit.data && isEdit) {
      setCustomerLead({ ...leadsToEdit.data });
    }
  }, [isEdit, leadsToEdit.data]);

  const createLeadMutation = useCreateLead();
  const updateLeadMutation = useUpdateLead();
  const deleteLeadMutation = useDeleteLead();

  const busy =
    leadsFields.isLoading ||
    leadsToEdit.isLoading ||
    createLeadMutation.isLoading ||
    updateLeadMutation.isLoading ||
    deleteLeadMutation.isLoading;

  const validateLeads = () => {
    let isValid = true;

    // collect all required fields
    const requiredFields = leadsFields.data?.assignedFields.filter(
      (f) => f.isRequired && f.name !== 'email',
    );

    for (let field of requiredFields as any[]) {
      if (!Boolean(customerLead[field.name])) {
        toastWarning(`${toProperCase(field.name)} is required`);
        isValid = false;
        return;
      }
    }

    if (customerLead.email) {
      if (!validateEmail(customerLead.email)) {
        toastWarning('Provide a valid email');
        isValid = false;
        return;
      }
    }

    if (!customerLead.email && !customerLead.contactNumber) {
      toastWarning('Email or contact number is required');
      return;
    }

    return isValid;
  };

  const openConfirmDialog = () => {
    dispatch(
      setSystemState({
        systemDialogOpen: true,
        systemDialogMaxWidth: 'xs',
        systemDialogTitle: 'Confirm Save',
        systemOverrideTitle: 'Save',
        systemDialogContent:
          'Please note that any changes are permanent. To continue, please click the save button.',
        systemDialogSimple: true,
        systemDialogConfirm: true,
        systemDialogConfirmAction: saveCustomerLead,
      }),
    );
  };

  const openDeleteConfirmDialog = () => {
    dispatch(
      setSystemState({
        systemDialogOpen: true,
        systemDialogMaxWidth: 'xs',
        systemDialogTitle: 'Confirm Delete',
        systemOverrideTitle: 'Confirm',
        systemDialogContent:
          'Deleting this lead is permanent. Please click confirm to continue',
        systemDialogSimple: true,
        systemDialogConfirm: true,
        systemDialogConfirmAction: handleDeleteLead,
      }),
    );
  };

  const handleSave = async () => {
    const isFormValid = validateLeads();

    if (isFormValid) {
      openConfirmDialog();
    }
  };

  const saveCustomerLead = async () => {
    if (!leadsFields.data) return;

    if (isEdit) {
      await updateLeadMutation.mutateAsync({
        fields: leadsFields.data.assignedFields,
        customerLead,
      });
    } else {
      await createLeadMutation.mutateAsync({
        fields: leadsFields.data.assignedFields,
        customerLead,
      });
    }
  };

  const handleDeleteLead = async () => {
    if (!isEdit || !leadsToEdit.data) return;

    await deleteLeadMutation.mutateAsync();
  };

  if (busy) {
    return (
      <AppModuleContainer>
        <LeadsFormHead isEdit={isEdit} loading={busy} onSave={handleSave} />
        <Box
          p={4}
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={400}>
          <CircularProgress style={{ color: appColors.primary }} />
        </Box>
        <ToastContainer
          toastStyle={{ borderRadius: 3, padding: '10px 15px' }}
        />
      </AppModuleContainer>
    );
  }

  if (!leadsFields.data) return <p>No fields data...</p>; // TODO

  return (
    <AppModuleContainer>
      <LeadsFormHead isEdit={isEdit} loading={busy} onSave={handleSave} />
      <SymphonyViewContentContainer style={{ padding: '20px 16px 0 16px' }}>
        <SymphonyViewTabsContainer>
          <SymphonyViewTabs
            id="customer-common-information-tabs"
            orientation="vertical"
            value={tab}
            TabIndicatorProps={{
              style: { width: 4, backgroundColor: appColors.primary },
            }}>
            {sections.map((s) => (
              <SymphonyViewTab
                key={s}
                label={s}
                value={s}
                onClick={onTabClick}
                id={`${s}-tab`}
              />
            ))}
          </SymphonyViewTabs>
        </SymphonyViewTabsContainer>
        <SymphonyViewCommonInfoContainer onScroll={onScroll}>
          {sections.map((s, i) => (
            <Box key={`${s}-section`}>
              <SymphonySectionHeaderContainer
                key={s}
                style={{ justifyContent: 'space-between' }}
                innerRef={refs[i]}>
                <SymphonySectionHeaderTitleContainer>
                  {s}
                  {i === 0 && isEdit && (
                    <SymphonySectionHeaderSubTitleContainer>
                      Last edited on{' '}
                      {moment(leadsToEdit.data?.dateUpdated as string).format(
                        'DD.MM.YYYY [at] hh:mmA',
                      )}
                    </SymphonySectionHeaderSubTitleContainer>
                  )}
                </SymphonySectionHeaderTitleContainer>
                {i === 0 && isEdit && (
                  <SalesAuxMenu>
                    <DecoratedPopoverButton
                      id="customer-delete-btn"
                      style={{ color: appColors.delete }}
                      endIcon={<Icon className="fa fa-trash-alt" />}
                      onClick={openDeleteConfirmDialog}>
                      Delete
                    </DecoratedPopoverButton>
                  </SalesAuxMenu>
                )}
              </SymphonySectionHeaderContainer>
              <SymphonyModuleFieldRenderer
                fields={leadsFields.data?.assignedFields}
                section={s}
                onEntityInput={(field, value) => {
                  setCustomerLead((old) => ({
                    ...old,
                    [field]: value,
                  }));
                }}
                entity={customerLead as any}
              />
            </Box>
          ))}
        </SymphonyViewCommonInfoContainer>
      </SymphonyViewContentContainer>
      <ToastContainer toastStyle={{ borderRadius: 3, padding: '10px 15px' }} />
    </AppModuleContainer>
  );
};

export default LeadsForm;
