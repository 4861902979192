import React from 'react';
import {
    BlackLoading
} from '../../category/fragments/CategoryComponents';

import {
    ModalContainer, ModalContentContainer, ModalContent,
    ModalActionButtons, ActionButton, CancelButton,
    ModalContentControls, ModalHeader
} from '../../common/ModalComponents'

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

interface DeleteModalProps {
    open: boolean;
    data: Array<object>;
    onImport: () => void;
    onCancel: () => void;
    loading: boolean;
}

interface Column {
    id: 'name' | 'skuNumber';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
}

const columns: Column[] = [
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'skuNumber', label: 'SKU', minWidth: 100 }
];

interface Data {
    name: string;
    skuNumber: string;
}

function createData(name: string, skuNumber: string): Data {
    return { name, skuNumber };
}

const useStyles = makeStyles({
    root: {
        width: 500,
        marginLeft: -15
    },
    container: {
        maxHeight: 240,
        '&.MuiTableCell-stickyHeader': {
            background: 'black'
        }
    },
});


export default (props: DeleteModalProps) => {
    const { open, data, onImport, onCancel } = props;
    const classes = useStyles();

    return (
        <ModalContainer
            open={open}
        >
            <ModalContentContainer>
                <ModalContent>
                    <ModalContentControls>
                        <ModalHeader>
                            <div>
                                <Typography style={{ fontSize: 19, fontWeight: 500, margin: 0 }}>
                                    Products
                                </Typography>

                                <Typography style={{ fontSize: 14, color: 'gray', marginBottom: 15, marginTop: -5 }}>
                                    List of products to be imported
                                </Typography>
                            </div>
                            <CancelButton onClick={onCancel} style={{ minWidth: 30, height: 25, padding: 0 }}>
                                <CloseIcon />
                            </CancelButton>
                        </ModalHeader>


                        <Paper className={classes.root} elevation={0}>
                            <TableContainer className={classes.container}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableBody>
                                        {data.map((row, i) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                                                    {columns.map((column) => {
                                                        const value = row[column.id];
                                                        return (
                                                            <TableCell key={column.id} align={column.align}>
                                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                <Typography style={{ fontSize: 12, color: 'gray', textTransform: 'capitalize' }}>
                                                                    {column.label}
                                                                </Typography>
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Paper>
                    </ModalContentControls>

                    <ModalActionButtons>
                        {
                            props.loading ? <></> : <CancelButton
                                onClick={onCancel}
                            >
                                Cancel
                            </CancelButton>
                        }

                        <ActionButton
                            onClick={onImport}
                        >
                            {props.loading ? <>
                                <BlackLoading size={15} style={{ marginRight: 10, color: 'white' }} /> Processing...
                            </> : <>Import Products</>}
                        </ActionButton>
                    </ModalActionButtons>
                </ModalContent>
            </ModalContentContainer>
        </ModalContainer>
    )
}