import React from 'react';

import Box from '@material-ui/core/Box';
import ReportFilterSelect from './ReportFilterSelect';

interface ReportFilterGroupProps {
    filter: {
        startMonth: string;
        endMonth: string;
        year: string;
    };
    onFilterChange: (
        key: 'startMonth' | 'endMonth' | 'year',
        value: string,
    ) => void;
}

const ReportFilterGroup = ({
    filter,
    onFilterChange,
}: ReportFilterGroupProps) => {
    return (
        <Box display="flex" alignItems="center">
            <Box mr={3} display="flex" alignItems="center">
                <ReportFilterSelect
                    label="Month"
                    value={filter.startMonth}
                    type="month"
                    currentEnd={filter.endMonth}
                    onChange={(value) => {
                        onFilterChange('startMonth', value);
                    }}
                />
                <ReportFilterSelect
                    label="&nbsp;&mdash;"
                    value={filter.endMonth}
                    type="month"
                    currentStart={filter.startMonth}
                    onChange={(value) => {
                        onFilterChange('endMonth', value);
                    }}
                />
            </Box>
            <ReportFilterSelect
                label="Year"
                value={filter.year}
                type="year"
                onChange={(value) => {
                    onFilterChange('year', value);
                }}
            />
        </Box>
    );
};

export default ReportFilterGroup;
