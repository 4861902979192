import { styled } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Box, Button } from '@material-ui/core';
import { SYMPHONY_PRIMARY_COLOR } from '../../symphony/Colors';

export const ModalContainer = styled(Modal)(({ theme }) => ({

}));

export const ModalContentContainer = styled(Box)(({ theme }) => ({
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 'none',
    borderRadius: 5,
}))

export const ModalContent = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 500, maxHeight: 500, minHeight: 350,
    backgroundColor: '#FFF',
    borderRadius: 7,
    boxShadow: '0 3px 7px rgba(0, 0, 0, 0.3)'
}))

export const ModalContentControls = styled(Box)(({ theme }) => ({
    padding: '20px 15px'
}))

export const ModalHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'base'
}))

export const ModalActionButtons = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    rowGap: 10,
    columnGap: 10,
    background: '#F4F4F7',
    padding: '15px 12px',
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
}))

export const ActionButton = styled(Button)(({ theme }) => ({
    color: '#FFF',
    padding: '5px 25px',
    textTransform: 'capitalize',
    fontWeight: 500,
    background: SYMPHONY_PRIMARY_COLOR,
    border: `1px solid ${SYMPHONY_PRIMARY_COLOR}`,
    '&:hover': {
        color: SYMPHONY_PRIMARY_COLOR,
        border: `1px solid ${SYMPHONY_PRIMARY_COLOR}`
    }
}))

export const CancelButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#F4F4F7',
    color: '#000',
    padding: '5px 25px',
    textTransform: 'capitalize',
    fontWeight: 500,
}))