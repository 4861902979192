import React, { useEffect } from "react";
import { styled } from '@material-ui/core/styles';
import { Avatar, Box } from "@material-ui/core";
import BrandButton from "./BrandButton";
import SymphonyInput from "../symphony/SymphonyInput";
import { LoginState } from "../../store/login/types";
import { connect } from "react-redux";
import { AppState } from "../../store";
import { resetAccountState, resetLoginState } from "../../store/login/actions";
import { resetSystemDialog, setSystemState } from "../../store/system/actions";
import { SystemState } from "../../store/system/types";
import { setUserManagementState, updateProfile } from "../../store/usermanagement/actions";
import { ActiveUser, UserManagementState } from "../../store/usermanagement/types";
import AccountSettingsModal from "./AccountSettingsModal";
import { ToastContainer } from "react-toastify";
import { useHistory } from 'react-router-dom';

const AccountContainer = styled(Box)(({ theme }) => ({
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    height: '100%',
    paddingLeft: '332px',
    paddingRight: '32px',
    minWidth: "1265px",
}));

const PhotoContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    padding: '20px 0'
}));

const PhotoActions = styled(Box)(({ theme }) => ({
    display: 'flex',
    rowGap: '15px',
    alignItems: 'center'
}));

const FormContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
    paddingTop: '15px'
}));

const Column = styled(Box)(({ theme }) => ({
    display: 'flex',
    columnGap: '15px',
    width: '100%'
}));

const SelectContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    '& .MuiInputBase-root': {
        width: '100%',
        '&:before': {
            borderBottom: 'none'
        },
        '&:after': {
            borderBottom: 'none'
        },
        '&:hover:before': {
            borderBottom: 'none'
        }
    }
}));

const FooterAction = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'end',
    padding: 0
}));

interface IAccount {
    setSystemState: typeof setSystemState;
    setUserManagementState: typeof setUserManagementState;
    resetAccountState: typeof resetAccountState;
    updateProfile: typeof updateProfile;
    resetSystemDialog: typeof resetSystemDialog;
    user: UserManagementState;
    login: LoginState;
    system: SystemState;
}

const AccountSettings = ({ setSystemState, setUserManagementState, resetAccountState, updateProfile, resetSystemDialog, login, user }: IAccount) => {
    const fileRef = React.createRef<HTMLInputElement>();
    const [blobUrl, setBlob] = React.useState<string>('')
    const [imageFile, setImageFile] = React.useState<File | null>(null)
    const [status, setStatus] = React.useState<string>('')
    const [show, setShow] = React.useState<boolean>(false)
    const colors = [{ label: 'Active', color: '' }]
    const options = [{ label: 'Active', value: 'active' }, { label: 'On Vacation', value: 'on-vacation' },
    { label: 'Holiday', value: 'holiday' }, { label: 'Closed', value: 'closed' }];
    const defaultImage = "https://suitesmedia.eu-central-1.linodeobjects.com/staging/marketplace/thumbnail/c6d0c329-ef88-4bc0-a26e-ae23f5381ee2_download.png"
    const history = useHistory();

    useEffect(() => {
        setSystemState({
            headerEndButton: () => (
                <Column style={{ justifyContent: 'end', columnGap: 0 }}>
                    <BrandButton brand="outlined" onClick={handleCancel}>
                        Cancel
                    </BrandButton>
                    <BrandButton brand="primary" onClick={handleSave}>
                        Save Changes
                    </BrandButton>
                </Column>
            ),
            shallRedirect: false,
            redirectTo: ''
        })
        setUserManagementState({
            currentUser: {
                ...user.currentUser,
                firstName: login.user.firstName,
                lastName: login.user.lastName,
                email: login.user.email,
                mobileNumber: login.user.mobileNumber,
                activity: login.user.activity || 'active',
                image: login.user.image || defaultImage
            } as ActiveUser
        })
        setBlob(login.user.image || defaultImage)
        setStatus(login.user.activity || 'active')
        // reset system state when going out of Account Settings Module
        return () => {
            setSystemState({
                headerEndButton: () => null
            })
        }
    }, [])

    const triggerFile = () => {
        fileRef.current?.click()
    }

    const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            // creates a blob URL to display image locally
            setBlob(URL.createObjectURL(e.target.files[0]))
            setImageFile(e.target.files[0])
            setUserManagementState({
                currentUser: {
                    ...user.currentUser,
                    image: e.target.files[0]
                } as ActiveUser
            })
        }
    }

    const handleRemovePhoto = () => {
        login.user.image = defaultImage;
        setBlob('')
        setImageFile(null)
        setUserManagementState({
            currentUser: {
                ...user.currentUser,
                image: login.user.image
            } as ActiveUser
        })
    }

    const getStrProp = (o: unknown, prop: string): string | unknown => {
        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const p = (o as any)[prop]
            if (typeof p === 'string') {
                setStatus(p)
                setUserManagementState({
                    currentUser: {
                        ...user.currentUser,
                        activity: p
                    } as ActiveUser
                })
            }
        } catch {
            // ignore
        }
        return undefined
    }

    const handleInputChange = (property: string, e: string) => {
        setUserManagementState({
            currentUser: {
                ...user.currentUser,
                [property]: e
            } as ActiveUser
        })
    }

    const handleChangePassword = () => {
        setShow(true)
        resetAccountState()
    }
    
    const handleSave = () => {
        setSystemState({
            systemDialogOpen: true,
            systemDialogMaxWidth: 'xs',
            systemDialogTitle: 'Confirm Save',
            systemDialogContent: 'Please note that any changes are permanent. To continue, please click the save button.',
            systemDialogSimple: true,
            systemDialogConfirm: true,
            systemDialogConfirmAction: () => { 
                updateProfile()
                resetSystemDialog();
            }
        });
    }

    const handleCancel = () => {
        // login.user.image = defaultImage;
        // setBlob('')
        // setImageFile(null)
        // setStatus(login.user.activity)
        // setUserManagementState({
        //     currentUser: {
        //         firstName: login.user.firstName,
        //         lastName: login.user.lastName,
        //         email: login.user.email,
        //         mobileNumber: login.user.mobileNumber,
        //         activity: login.user.activity,
        //         image: login.user.image
        //     } as ActiveUser
        // })
        history.push('/home')
    }

    return (
        <AccountContainer>
                <AccountSettingsModal show={show} toggle={setShow} data={login} />
                <PhotoContainer>
                    <Avatar alt="John Doe" src={blobUrl ? blobUrl : defaultImage}
                        style={{ width: 100, height: 100, marginRight: 25 }} />
                    <PhotoActions>
                        <BrandButton onClick={triggerFile}>Upload Photo</BrandButton>
                        <BrandButton brand="outlined" onClick={handleRemovePhoto}>Remove Photo</BrandButton>
                        <input type='file' id="file"
                            hidden
                            accept="image/png, image/gif, image/jpeg, image/webp"
                            ref={fileRef}
                            onChange={handleUpload}
                            key={imageFile?.name}
                        />
                    </PhotoActions>
                </PhotoContainer>
                <FormContainer>
                    <Column>
                        <SymphonyInput
                            type="text"
                            label="First Name"
                            value={user.currentUser?.firstName || ''}
                            onChange={(e) => handleInputChange('firstName', e.target.value)}
                        />
                        <SymphonyInput
                            type="text"
                            label="Last Name"
                            value={user.currentUser?.lastName || ''}
                            onChange={(e) => handleInputChange('lastName', e.target.value)}
                        />
                    </Column>
                    <Column>
                        <SymphonyInput
                            type="text"
                            label="Email"
                            value={user.currentUser?.email || ''}
                            disabled
                            onChange={(e) => handleInputChange('email', e.target.value)}
                        />
                        <SymphonyInput
                            type="number"
                            label="Phone Number"
                            value={user.currentUser?.mobileNumber || ''}
                            onChange={(e) => handleInputChange('mobileNumber', e.target.value)}
                        />
                    </Column>
                    <SelectContainer>
                        <SymphonyInput
                            type="select"
                            label="Account Status"
                            value={status}
                            selectOptions={options}
                            selectOnchange={(e: React.ChangeEvent<{ value: unknown }>) => getStrProp(e.target, 'value')}
                        />
                    </SelectContainer>
                    <FooterAction>
                        <BrandButton style={{ margin: 0 }} onClick={() => handleChangePassword()}>Change Password</BrandButton>
                    </FooterAction>
                </FormContainer>
            <ToastContainer />
        </AccountContainer>
    )
}


const mapStateToProps = (state: AppState) => ({
    login: state.login,
    system: state.system,
    user: state.usermanagement
});

export default connect(mapStateToProps, {
    setSystemState,
    resetLoginState,
    resetSystemDialog,
    setUserManagementState,
    resetAccountState,
    updateProfile
})(AccountSettings);