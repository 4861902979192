export type Status = 'idle' | 'loading' | 'success' | 'error';

// export interface MarketReportItem<T> {
//     id: string;
//     status: T;
// }

export interface MarketReportItem {
    products:Item[]
}

export interface Item {
    average_rating: number,
        productId: string,
        product_name: string,
        reviews:number[]
};

export interface TRPState<S> {
    stateStatus: Status;
    error?: string | null;
    data: Item[];
    filter: {
        month:string;
        year: string;
    };
}

export type TRProductProductReportState = TRPState<Status>

//Manage Top 5 Products

export const LOAD_TR_PRODUCTS_REPORT = 'top-rated/data/load';
export const SET_TR_PRODUCTS_STATUS = 'top-rated/status/set';
export const SET_TR_PRODUCTS_FILTER = 'top-rated/filter/load';
export const SET_TR_PRODUCTS_ERROR = 'top-rated/error/set';

interface LoadTRProductAction {
    type: typeof LOAD_TR_PRODUCTS_REPORT;
    payload: TRProductProductReportState['data'];
};

interface SetTRProductStatusAction {
    type: typeof SET_TR_PRODUCTS_STATUS;
    payload: TRProductProductReportState['stateStatus'];
};

interface SetTRProductFilterAction {
    type: typeof SET_TR_PRODUCTS_FILTER;
    payload: TRProductProductReportState['filter'];
};

interface SetTRProductErrorAction {
    type: typeof SET_TR_PRODUCTS_ERROR;
    payload: TRProductProductReportState['error'];
};

export type TRProductAction = 
    | LoadTRProductAction
    | SetTRProductStatusAction
    | SetTRProductFilterAction
    | SetTRProductErrorAction;

