export type ManageOrderStatus =
    | 'UNPAID'
    | 'PAID'
    | 'READY_TO_SHIP'
    | 'PENDING'
    | 'SHIPPED'
    | 'DELIVERED';

export type OrderHistoryStatus = 'RECEIVED' | 'CANCELLED' | 'FAILED_DELIVERY';

export type Status = 'idle' | 'loading' | 'success' | 'error';

export interface MarketReportItem<T> {
    id: string;
    status: T;
}

export interface MarketReportState<S> {
    stateStatus: Status;
    error?: string | null;
    data: MarketReportItem<S>[];
    filter: {
        startMonth: string;
        endMonth: string;
        year: string;
    };
}

export type ManageOrderReportState = MarketReportState<ManageOrderStatus>;
export type OrderHistoryReportState = MarketReportState<OrderHistoryStatus>;

// Action creator

// Manage Order
export const LOAD_MANAGE_ORDER_REPORT = 'manage-order/data/load';
export const SET_MANAGE_ORDER_STATUS = 'manage-order/status/set';
export const SET_MANAGE_ORDER_FILTER = 'manage-order/filter/set';
export const SET_MANAGE_ORDER_ERROR = 'manage-order/error/set';

interface LoadManageOrderAction {
    type: typeof LOAD_MANAGE_ORDER_REPORT;
    payload: ManageOrderReportState['data'];
}

interface SetManageOrderStatusAction {
    type: typeof SET_MANAGE_ORDER_STATUS;
    payload: ManageOrderReportState['stateStatus'];
}

interface SetManageOrderFilterAction {
    type: typeof SET_MANAGE_ORDER_FILTER;
    payload: ManageOrderReportState['filter'];
}

interface SetManageOrderErrorAction {
    type: typeof SET_MANAGE_ORDER_ERROR;
    payload: ManageOrderReportState['error'];
}

export type ManageOrderAction =
    | LoadManageOrderAction
    | SetManageOrderStatusAction
    | SetManageOrderFilterAction
    | SetManageOrderErrorAction;

// Order History
export const LOAD_ORDER_HISTORY_REPORT = 'order-history/data/load';
export const SET_ORDER_HISTORY_STATUS = 'order-history/status/set';
export const SET_ORDER_HISTORY_FILTER = 'order-history/filter/set';
export const SET_ORDER_HISTORY_ERROR = 'order-history/error/set';

interface LoadOrderHistoryAction {
    type: typeof LOAD_ORDER_HISTORY_REPORT;
    payload: OrderHistoryReportState['data'];
}

interface SetOrderHistoryStatusAction {
    type: typeof SET_ORDER_HISTORY_STATUS;
    payload: OrderHistoryReportState['stateStatus'];
}

interface SetOrderHistoryFilterAction {
    type: typeof SET_ORDER_HISTORY_FILTER;
    payload: OrderHistoryReportState['filter'];
}

interface SetOrderHistoryErrorAction {
    type: typeof SET_ORDER_HISTORY_ERROR;
    payload: OrderHistoryReportState['error'];
}

export type OrderHistoryAction =
    | LoadOrderHistoryAction
    | SetOrderHistoryStatusAction
    | SetOrderHistoryFilterAction
    | SetOrderHistoryErrorAction;
