import React, { useEffect } from 'react';

import Box from '@material-ui/core/Box';

import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    ChartData,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import ChartContainer from './ChartContainer';
import { dataColors, orderHistoryChartOptions } from './util/chart-utils';
import OrderHistoryTotal from './OrderHistoryTotal';
import OrderHistoryTally from './OrderHistoryTally';
import useAtBreakpoint from '../../symphony/hooks/useAtBreakpoint';
import { useDispatch, useSelector } from 'react-redux';
import {
    getOrderHistoryReport,
    selectOrderHistoryReportData,
    selectOrderHistoryState,
    setOrderHistoryFilter,
} from '../../../store/marketreport/orderHistoryReducer';
import ReportFilterGroup from './ReportFilterGroup';

ChartJS.register(ArcElement, Tooltip, Legend);

const { darkGray, mediumGray, brown } = dataColors;

const OrderHistoryReport = () => {
    const isLargeScreen = useAtBreakpoint('xl');
    const { filter, error, stateStatus } = useSelector(selectOrderHistoryState);
    const reportData = useSelector(selectOrderHistoryReportData);
    const dispatch = useDispatch();

    const { total, received, cancelled, failed } = reportData;

    useEffect(() => {
        dispatch(getOrderHistoryReport(filter));
    }, [dispatch, filter]);

    const dataToDisplay: ChartData = {
        labels: ['Received', 'Canceled', 'Failed Delivery'],
        datasets: [
            {
                label: 'Order History',
                data: reportData.chartData,
                backgroundColor: [brown, darkGray, mediumGray],
            },
        ],
    };

    const handleFilterChange = (key: keyof typeof filter, value: string) => {
        dispatch(
            setOrderHistoryFilter({
                ...filter,
                [key]: value,
            }),
        );
    };

    return (
        <ChartContainer
            title="Order History"
            loading={stateStatus === 'loading'}
            error={error}
            noData={total === 0}
            controls={
                <ReportFilterGroup
                    filter={filter}
                    onFilterChange={handleFilterChange}
                />
            }>
            <Box
                width={isLargeScreen ? '80%' : '70%'}
                margin="0 auto"
                position="relative"
                style={{
                    aspectRatio: '1 / 1',
                }}>
                <Doughnut
                    type="doughnut"
                    data={dataToDisplay}
                    options={orderHistoryChartOptions}
                />
                <OrderHistoryTotal total={total} />
            </Box>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mt={6}>
                <OrderHistoryTally
                    type="Received"
                    value={received}
                    total={total}
                />
                <OrderHistoryTally
                    type="Cancelled"
                    value={cancelled}
                    total={total}
                />
                <OrderHistoryTally
                    type="Failed Delivery"
                    value={failed}
                    total={total}
                />
            </Box>
        </ChartContainer>
    );
};

export default OrderHistoryReport;
