import React, { useMemo, useState } from 'react';
import { matchSorter } from 'match-sorter';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';

import useTableUtils from '../../../lib/table/useTableUtils';
import AMITable from '../../../lib/table/AMITable';
import AMITableHeading from '../../../lib/table/AMITableHeading';
import AMITableBody from '../../../lib/table/AMITableBody';
import { returnColumns } from './return-columns';
import SearchField from '../../../lib/SearchField';
import { useReturnOrdersQuery } from './hooks';
import { formatDate } from '../../../lib/helpers';

function ReturnView() {
  const {
    page,
    paginate,
    sort,
    sortColumn,
    sortHandler,
    handleChangePage,
    handleChangeRowsPerPage,
    rowsPerPage,
  } = useTableUtils({ initialSortBy: 'requestDate' });

  const [search, setSearch] = useState('');

  const returnOrders = useReturnOrdersQuery();

  const columns = useMemo(() => returnColumns, []);

  const data = returnOrders.data ? returnOrders.data : [];

  const filteredData = matchSorter(data, search, {
    keys: [
      'id',
      'paymentMethod',
      'status',
      'solution',
      (item) => formatDate(item.dateCreated),
    ],
  });

  const sortedData = sort(filteredData, sortColumn.path, sortColumn.order);
  const paginatedData = paginate(sortedData, page, rowsPerPage);

  return (
    <>
      <Box position="absolute" top={125} right={24}>
        <SearchField keyword={search} onKeywordChange={setSearch} />
      </Box>
      <Paper elevation={0}>
        <AMITable sticky height="calc(100vh - 280px)">
          <AMITableHeading
            columns={columns}
            sortColumn={sortColumn}
            onSort={sortHandler}
          />
          <AMITableBody
            loading={returnOrders.isLoading}
            data={paginatedData}
            columns={columns}
            emptyText="No data to display"
          />
        </AMITable>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={sortedData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}

export default ReturnView;
