import React from 'react';
import { MarketProduct, MarketProductSku } from '../../../../store/marketproduct/types';
// local
import {
    ProductVariantsContainer,
    ProductVariantsCardContainer,
    ProductVariantsCard,
    ProductVariantsImageContainer,
    ProductVariantAddContainer,
    ProductVariantDescriptionContainer,
    ProductVariantAuxContainer,
    ProductAuxIconButton
} from './ProductComponents';
import VariantAux from './VariantAux';

// global
import {
    DecoratedPopoverButton
} from '../../../symphony/SymphonyCommonComponents';

// material
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import StarIcon from '@material-ui/icons/Star';
import StarOutlineIcon from '@material-ui/icons/StarBorder';

import map from 'lodash/map';
import { SYMPHONY_PRIMARY_COLOR } from '../../../symphony/Colors';
import { LoginState } from '../../../../store/login/types';
import { Grid, Typography } from '@material-ui/core';

interface VariantCardsProps {
    productVariants: Array<MarketProductSku>;
    activeProduct?: MarketProduct;
    featuredSku: string;
    onVariantClick: (id: string) => void;
    onAddVariantClick: () => void;
    onDeleteVariantClick: (id: string) => void;
    login: LoginState;
}

const VariantCards = ({ productVariants, featuredSku, onVariantClick, onAddVariantClick, onDeleteVariantClick, login }: VariantCardsProps) => {
    const onCardClick = React.useCallback(onVariantClick, []);
    const { access } = login.user;

    return (
        <ProductVariantsContainer>
            <Grid container style={{ cursor: 'pointer', columnGap: '10px', rowGap: '10px' }}>
                {map(productVariants, (variant) => {
                    const { id, skuNumber } = variant;
                    let primImage = ''
                    for (const m of variant.media) {
                        if (m.type.indexOf('image') > -1) {
                            primImage = m.path;
                            break;
                        }
                    }

                    return (
                        <Grid item xs={4} style={{ height: 195, borderRadius: 5, 
                            border: 'solid 1px #e5e5e5', padding: 0, overflow: 'hidden' }}>
                            <Grid container>
                                <Grid xs={4} justify="center" alignItems="center" style={{ background: '#f2f2f2' }} id={`product-variant-${id}`}
                                    onClick={() => onCardClick(id as string)}>
                                    <img src={primImage} alt="" width={'100%'} height={195}
                                        style={{ objectFit: 'cover' }} />
                                </Grid>
                                <Grid xs={8}>
                                    <Grid container justify='flex-end' direction='row' style={{ padding: '8px' }}>
                                        <ProductAuxIconButton>
                                            {variant.id === featuredSku ?
                                                <StarIcon htmlColor={SYMPHONY_PRIMARY_COLOR} />
                                                :
                                                <StarOutlineIcon htmlColor={SYMPHONY_PRIMARY_COLOR} />
                                            }
                                        </ProductAuxIconButton>
                                        {access.includes('DELETE::PRODUCT') &&
                                            <>
                                                <VariantAux>
                                                    <DecoratedPopoverButton
                                                        id={`product-variant-delete-${id}`}
                                                        style={{ color: '#FF4D4D' }}
                                                        endIcon={<Icon className="fa fa-trash-alt" />}
                                                        onClick={() => onDeleteVariantClick(id as string)}
                                                    >
                                                        Delete
                                                    </DecoratedPopoverButton>
                                                </VariantAux>
                                            </>
                                        }
                                    </Grid>
                                    <Grid container direction='column' style={{padding: '5px 10px'}}
                                     onClick={() => onCardClick(id as string)}>
                                        <Typography style={{ fontSize: '16px', marginBottom: '4px', overflowWrap: 'break-word', 
                                            maxWidth: '25ch', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{skuNumber}</Typography>
                                        <Box fontSize="12px" marginBottom="2px" color="#969696">{variant.size || ''}</Box>
                                        <Box fontSize="12px" marginBottom="2px" color="#969696">{variant.color || ''}</Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                })}
                {access.includes('CREATE::PRODUCT') &&
                    <Grid item xs={4} id="product-variant-add-btn"
                        style={{ border: '1px dashed #E5E5E5', borderRadius: 5, height: 195 }}
                        onClick={onAddVariantClick}>
                        <ProductVariantAddContainer>
                            <AddCircleIcon htmlColor={SYMPHONY_PRIMARY_COLOR} />
                            New SKU
                        </ProductVariantAddContainer>
                    </Grid>
                }
            </Grid>
        </ProductVariantsContainer>
    )
}

export default VariantCards;