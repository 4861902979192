import React from 'react';
import { Role } from '../../../../store/usermanagement/types';
import { 
    RoleListGrid,
    RoleListContainer,
    InnerCategoryListContainer,
    RoleHeaderItem,
    RoleHeaderGridContainer,
} from './AccessibilityComponents';
import RoleAuxMenu from './RoleAuxMenu';

import { 
    DecoratedPopoverButton 
} from '../../SymphonyCommonComponents';
import { SYMPHONY_PRIMARY_COLOR } from '../../Colors';

// material
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

// util
import map from 'lodash/map';

// Assets
import EditIcon from '../../../../assets/images/icons/symphony-edit-gold.png';
import { SortOrder } from '../../../../store/system/types';
import { LoginState } from '../../../../store/login/types';

interface RoleCardListProps {
    roles: Array<Role>;
    onEditClick: (id: string, name: string, description: string) => void;
    toggleDeleteModal: (open: boolean, id: string) => void;
    activeSort: string;
    activeSortOrder: SortOrder;
    onSortClick: (sort: string, order: SortOrder) => void;
    login: LoginState;
}

const RoleCardList = ({ roles, onEditClick, toggleDeleteModal, activeSort, activeSortOrder, onSortClick, login }: RoleCardListProps) => {
    const RenderSortHeader = ({ headerTitle, headerValue }: { headerTitle: string, headerValue: string }) => {
        return (
            <RoleHeaderItem onClick={() => onSortClick(headerValue, !activeSortOrder ||  headerValue !== activeSort ? 'ASC' : activeSortOrder === 'ASC'? 'DESC' : '')  }>
                {headerTitle}
                {!!headerValue &&
                    <>
                        {activeSort ?
                            <>
                                {(activeSort === headerValue) &&
                                    <>
                                        {activeSortOrder === 'ASC' ? 
                                            <KeyboardArrowDownIcon />
                                        :
                                            <KeyboardArrowUpIcon />
                                        }
                                    </>
                                }
                            </>
                        :
                            <KeyboardArrowDownIcon />
                        }
                    </>
                }
            </RoleHeaderItem>
        )
    }

    const { access } = login.user;

    return (
        <RoleListContainer style={{ paddingTop: 30 }}>
            <RoleHeaderGridContainer container={true} style={{ backgroundColor: '#F4F6F9' }}>
                <RoleListGrid item={true} xs={3} style={{ paddingLeft: 70 }}>
                    <RenderSortHeader
                        headerTitle="Roles"
                        headerValue="name"
                    />
                </RoleListGrid>
                <RoleListGrid item={true} xs={8}>
                    <RenderSortHeader
                        headerTitle="Role Description"
                        headerValue="description"
                    />
                </RoleListGrid>
            </RoleHeaderGridContainer>
            <Divider/>
            <InnerCategoryListContainer>
                {map(roles, ({ name, description, id }) => {
                    return (
                        <Box key={id}>
                            <Grid container={true}>
                                <RoleListGrid item={true} xs={3} style={{ paddingLeft: 70 }}>
                                    <Box display="inline">{name}</Box>
                                </RoleListGrid>
                                <RoleListGrid item={true} xs={8}>
                                    <Box display="inline" color="#A2A2A2">{description}</Box>
                                </RoleListGrid>
                                <RoleListGrid item={true} xs={1}>
                                    {(!['Admin'].includes(name)) &&
                                    <>
                                        {(access.includes('UPDATE::ROLE') || access.includes('DELETE::ROLE')) &&
                                        <>
                                            <RoleAuxMenu roleId={id}>
                                                {access.includes('UPDATE::ROLE') &&
                                                    <>
                                                        <DecoratedPopoverButton
                                                            id={`role-edit-btn-${id}`}
                                                            style={{ color: SYMPHONY_PRIMARY_COLOR }}
                                                            endIcon={<img src={EditIcon} style={{ width: 14, height: 14 }} alt="" />}
                                                            onClick={() => onEditClick(id, name, description)}
                                                        >
                                                            Edit
                                                        </DecoratedPopoverButton>
                                                    </>
                                                }
                                                {access.includes('DELETE::ROLE') &&
                                                    <>
                                                        <DecoratedPopoverButton
                                                            id={`role-delete-btn-${id}`}
                                                            style={{ color: '#FF4D4D' }}
                                                            endIcon={<Icon className="fa fa-trash-alt" />}
                                                            onClick={() => toggleDeleteModal(true, id)}
                                                        >
                                                            Delete
                                                        </DecoratedPopoverButton>
                                                    </>
                                                }
                                            </RoleAuxMenu>
                                        </>
                                        }
                                    </>
                                    }
                                </RoleListGrid>
                            </Grid>
                            <Divider/>
                        </Box>
                    )
                })}
            </InnerCategoryListContainer>
        </RoleListContainer>
    )
}

export default RoleCardList;