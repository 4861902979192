import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/CheckCircle';
import FailedIcon from '@material-ui/icons/Cancel';

import { styled } from '@material-ui/core/styles';
import { appColors } from '../../../lib/constants';
import {
  useRefundOrdersQuery,
  useReturnOrdersQuery,
  useUpdateOrderStatus,
} from './hooks';
import { NEGATIVE_STATUSES } from './constants';

const StyledButton = styled(Button)({
  textTransform: 'capitalize',

  '&.MuiButton-contained': {
    backgroundColor: appColors.primary,
    color: 'white',
  },

  '&.MuiButton-outlined': {
    borderColor: appColors.primary,
    color: appColors.primary,
  },
});

type ReturnRefundActionsProps = {
  id: string;
  isReturn: boolean;
  currentStatus: string;
};

function ReturnRefundActions({
  id,
  isReturn,
  currentStatus,
}: ReturnRefundActionsProps) {
  const returnOrders = useReturnOrdersQuery();
  const refundOrders = useRefundOrdersQuery();

  const orderUpdateMutation = useUpdateOrderStatus();

  const APPROVED_STATUS = isReturn
    ? 'RETURN_VENDOR_APPROVED'
    : 'REFUND_APPROVED';

  const REJECTED_STATUS = isReturn
    ? 'RETURN_VENDOR_REJECTED'
    : 'REFUND_REJECTED';

  const isApprovedOrRejectedAlready = !['PENDING_RETURN', 'PENDING_REFUND'].includes(
    currentStatus,
  );

  const isAFailedStatus = NEGATIVE_STATUSES.includes(currentStatus);

  const handleUpdateStatus = async (newStatus: string) => {
    await orderUpdateMutation.mutateAsync(
      { orderId: id, status: newStatus },
      {
        onSuccess: async (data, variables, context) => {
          await returnOrders.refetch();
          await refundOrders.refetch();
        },
      },
    );
  };

  const busy =
    returnOrders.isLoading ||
    refundOrders.isLoading ||
    orderUpdateMutation.isLoading;

  if (isApprovedOrRejectedAlready) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ gap: 12 }}>
        {isAFailedStatus ? (
          <>
            <FailedIcon style={{ color: appColors.error }} />
            <Typography>Rejected</Typography>
          </>
        ) : (
          <>
            <CheckIcon style={{ color: appColors.success }} />
            <Typography>Approved</Typography>
          </>
        )}
      </Box>
    );
  }
  return (
    <Box
      display="flex"
      justifyContent="center"
      style={{
        gap: 12,
        pointerEvents: busy ? 'none' : 'inherit',
        opacity: busy ? 0.5 : 1,
      }}>
      <StyledButton
        disableElevation
        variant="contained"
        onClick={() => handleUpdateStatus(APPROVED_STATUS)}>
        Approve
      </StyledButton>
      <StyledButton
        disableElevation
        variant="outlined"
        onClick={() => handleUpdateStatus(REJECTED_STATUS)}>
        Reject
      </StyledButton>
    </Box>
  );
}

export default ReturnRefundActions;
