import React from 'react';
import { useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import ExportIcon from '@material-ui/icons/GetApp';
import ExpandIcon from '@material-ui/icons/ExpandMore';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { appColors } from '../../../lib/constants';
import {
  selectMarketOrderHistoryTab,
  selectMarketOrders,
} from '../../../../store/ordermanagement/reducers';
import { exportToExcel } from './order-utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      textTransform: 'capitalize',
      backgroundColor: appColors.primary,
      color: 'white',

      '&:hover': {
        backgroundColor: appColors.primary,
        opacity: 0.9,
      },

      '&:disabled': {
        backgroundColor: theme.palette.secondary.main,
        color: appColors.mediumGray,
      },
    },
  }),
);

export function ManageOrderExport() {
  const classes = useStyles();
  const orders = useMarketOrders();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openExportMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExport = (extension: 'xlsx' | 'csv') => {
    exportToExcel(orders, 'Orders', 'Orders', extension);

    setAnchorEl(null);
  };

  return (
    <>
      <Button
        disabled={orders.length === 0}
        disableElevation
        size="large"
        variant="contained"
        aria-controls="order-export-menu"
        aria-haspopup="true"
        startIcon={<ExportIcon />}
        endIcon={<ExpandIcon />}
        className={classes.button}
        onClick={openExportMenu}>
        Export
      </Button>
      <Menu
        id="order-export-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem onClick={() => handleExport('xlsx')}>As Excel file</MenuItem>
        <MenuItem onClick={() => handleExport('csv')}>As CSV file</MenuItem>
      </Menu>
    </>
  );
}

export function OrderHistoryExport() {
  const classes = useStyles();
  const orders = useMarketOrders();
  const orderHistoryTab = useOrderHistoryTab();

  const fileName = `Order History (${orderHistoryTab})`;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openExportMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExport = (extension: 'xlsx' | 'csv') => {
    exportToExcel(orders, fileName, fileName, extension);

    setAnchorEl(null);
  };

  return (
    <>
      <Button
        disabled={orders.length === 0}
        disableElevation
        size="large"
        variant="contained"
        aria-controls="order-export-menu"
        aria-haspopup="true"
        startIcon={<ExportIcon />}
        endIcon={<ExpandIcon />}
        className={classes.button}
        onClick={openExportMenu}>
        Export
      </Button>
      <Menu
        id="order-export-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem onClick={() => handleExport('xlsx')}>As Excel file</MenuItem>
        <MenuItem onClick={() => handleExport('csv')}>As CSV file</MenuItem>
      </Menu>
    </>
  );
}

function useMarketOrders() {
  return useSelector(selectMarketOrders);
}

function useOrderHistoryTab() {
  return useSelector(selectMarketOrderHistoryTab);
}
