import { formatHistoryDate, formatHistoryTime } from '../../../lib/helpers';
import { History } from './types';
import { capitalize } from 'lodash';

export const RETURN_JOURNEY = [
  'RETURN',
  'RETURN_VENDOR_APPROVED',
  'RETURN_READY_TO_SHIP',
  'RETURN_SHIPPED',
  'RETURN_DELIVERED',
  'RETURN_REFUND_ISSUED',
];

export const REFUND_JOURNEY = ['REFUND', 'REFUND_APPROVED', 'REFUND_ISSUED'];

export const NEGATIVE_STATUSES = [
  'RETURN_VENDOR_REJECTED',
  'RETURN_REJECTED',
  'RETURN_FAILED_TO_SHIP',
  'REFUND_REJECTED',
  'REFUND_CUSTOMER_REJECTED',
];

export const DATE_PLACEHOLDER = 'MM/DD/Year';
export const TIME_PLACEHOLDER = '00:00 AM/PM';

export const extractStatusText = (status: string) => {
  if (['RETURN', 'REFUND'].includes(status)) return 'Requested';

  if (['RETURN_REJECTED'].includes(status)) return 'Return Rejected';

  if (status === 'RETURN_VENDOR_REJECTED') return 'Rejected';

  if (status === 'RETURN_VENDOR_APPROVED') return 'Approved';

  return capitalize(status.split('_').slice(1).join(' '));
};

export const getJourneyData = (historyData: History[], isReturn: boolean) => {
  // get the start of history for return/refund
  const startIndex = historyData.findIndex((h) =>
    ['REFUND', 'RETURN'].includes(h.status),
  );

  const historyEntries = historyData.slice(startIndex);

  const journeyData = isReturn ? RETURN_JOURNEY : REFUND_JOURNEY;

  const journeyToDisplay = journeyData.map((d, idx) => {
    if (historyEntries[idx]) {
      let tempStatus = historyEntries[idx]?.status;
      let status = d;
      let isFailed = false;

      if (
        d === 'RETURN_VENDOR_APPROVED' &&
        tempStatus === 'RETURN_VENDOR_REJECTED'
      ) {
        status = 'RETURN_VENDOR_REJECTED';
        isFailed = true;
      }

      if (d === 'RETURN_READY_TO_SHIP' && tempStatus === 'RETURN_REJECTED') {
        status = 'RETURN_REJECTED';
        isFailed = true;
      }

      if (d === 'RETURN_SHIPPED' && tempStatus === 'RETURN_FAILED_TO_SHIP') {
        status = 'RETURN_FAILED_TO_SHIP';
        isFailed = true;
      }

      const { date, description, update } = historyEntries[idx];

      return {
        date: formatHistoryDate(date),
        time: formatHistoryTime(date),
        status: extractStatusText(status),
        isFilled: true,
        isFailed,
        description,
        update,
      };
    }

    return {
      date: DATE_PLACEHOLDER,
      time: TIME_PLACEHOLDER,
      status: extractStatusText(d),
      isFilled: false,
      isFailed: false,
      description: '',
      update: '',
    };
  });

  return journeyToDisplay;
};
