import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../store';
import { MarketProductState, DynamicMarketProductInput, MarketProductFilters } from '../../../store/marketproduct/types';
import { setMarketProductState, getProducts, importProducts, updateMarketProductMultiple } from '../../../store/marketproduct/actions';
import { setSystemState, resetSystemDialog } from '../../../store/system/actions';
import SymphonyLayout from '../../symphony/SymphonyLayout';
import ProductCardList from './fragments/ProductCardList';
// local components
import {
    ProductsContainer,
    ProductsContentContainer,
    ProductsTabsContainer,
    ProductLoadingContainer,
    AddProductButton
} from './fragments/ProductComponents';
import FileModal from './fragments/ImportModal'
// common components
import {
    SalesField,
    SalesTabs,
    SalesTab
} from '../../Basic/Common/BasicCommonComponents';

// material components
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from '@material-ui/icons/Add';
import Search from '@material-ui/icons/Search';

// util
import debounce from 'lodash/debounce';
import { SYMPHONY_PRIMARY_COLOR } from '../../symphony/Colors';
import { SortOrder } from '../../../store/system/types';
import { LoginState } from '../../../store/login/types';
import { SymphonyHeaderAuxButton, SymphonyHeaderAuxButton2 } from '../../symphony/SymphonyCommonComponents';
import { Box, Icon } from '@material-ui/core';

// import sheetjs
import * as XLSX from "xlsx";
import find from 'lodash/find';
import description from 'material-ui/svg-icons/action/description';
import { type } from 'os';
import { toastWarning } from '../../../modules/Toast';
import { stringValidator } from '../../../utils/validators';
import BrandButton from '../../Common/BrandButton';


interface ProductProps {
    setSystemState: typeof setSystemState;
    setMarketProductState: typeof setMarketProductState;
    getProducts: typeof getProducts;
    importProducts: typeof importProducts;
    product: MarketProductState;
    resetSystemDialog: typeof resetSystemDialog;
    login: LoginState;
    file: File;
    updateMarketProductMultiple: typeof updateMarketProductMultiple;
}

const EMPTY_PRODUCT = {
    name: '',
    brand: '',
    material: '',
    type: '',
    description: '',
    h1: '',
    h2: '',
    h3: ''
} as unknown as DynamicMarketProductInput;

const sampleData = [
    {
        name: "Demo Project1",
        type: "Demo",
        media: "",
        brand: "SAMPLE BRAND",
        ingredient: "Demo",
        material: "Demo",
        unit: "Demo",
        description: "Demo",
        skuNumber: "DEMO::SALES",
        price: 150,
        stock: 99,
        minQuantity: 0,
        h1: "Accesories",
        h2: "Phone Accessories",
        h3: "Airpods",
        isActive: true,
        color: "Demo",
        size: "Demo",
    }
]

const columns = [
    { title: "Name", field: "name", },
    { title: "Email", field: "email", },
    { title: "Media", field: "media", },
    { title: "Brand", field: "brand", },
    { title: "Ingredient", field: "ingredient", },
    { title: "Material", field: "material", },
    { title: "Unit", field: "unit", },
    { title: "Description", field: "description", },
    { title: "skuNumber", field: "skuNumber", },
    { title: "Price", field: "price", },
    { title: "Stock", field: "stock", },
    { title: "minQuantity", field: "minQuantity", },
    { title: "h1", field: "h1", },
    { title: "h2", field: "h2", },
    { title: "h3", field: "h3", },
    { title: "isActive", field: "isActive", },
    { title: "Color", field: "color", },
    { title: "Size", field: "size", },
]

class Product extends React.Component<ProductProps> {
    searchRef = React.createRef<HTMLInputElement>();
    fileRef = React.createRef<HTMLInputElement>();
    state = {
        openFileModal: false,
        spreadsheet: []
    };

    _search = debounce((searchString: string) => {
        this.props.getProducts(this.props.product.activeFilters, 'list', searchString, this.props.product.activeTab === 'Active');
    }, 300, { leading: false });


    componentDidMount = () => {
        const { access } = this.props.login.user;
        // set button
        this.props.setSystemState({
            headerEndButton: () => (
                <div>
                    {access.includes('CREATE::PRODUCT') &&
                        <Box display="flex" justifyContent="center">
                            <input type='file' id="file"
                                hidden
                                accept=".xls,.xlsx,.csv,.csvx"
                                ref={this.fileRef}
                                onChange={this.readUploadFile}
                            />

                            <BrandButton
                                brand='secondary'
                                onClick={this.downloadExcel.bind(this)}
                            >
                                Get Templates
                            </BrandButton>

                            <BrandButton
                                endIcon={<Icon className="fas fa-file-import" />}
                                onClick={this.triggerFile.bind(this)}
                                brand='secondary'
                            >
                                Import
                            </BrandButton>

                            <BrandButton
                                id="add-product-btn"
                                startIcon={<AddIcon />}
                                onClick={this._onAddClick.bind(this)}
                            >
                                Add New
                            </BrandButton>
                        </Box>
                    }
                </div>
            ),
            shallRedirect: false,
            redirectTo: ''
        });
        this.props.getProducts(this.props.product.activeFilters, 'list', '', this.props.product.activeTab === 'Active');
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.product.selectedMarketProduct !== this.props.product.selectedMarketProduct) {
            this.props.setSystemState({
                headerEndButton: () => (
                    <Box display="flex" justifyContent="center">
                        <input type='file' id="file"
                            hidden
                            accept=".xls,.xlsx,.csv,.csvx"
                            ref={this.fileRef}
                            onChange={this.readUploadFile}
                        />

                        {
                            !!this.props.product.selectedMarketProduct!.length && <>
                                {
                                    this.props.product.activeTab === 'Active' ? <>
                                        <BrandButton brand='outlined' onClick={() => this._handleMultiSelection('inactive')}>Inactive</BrandButton>
                                        <BrandButton brand='outlined' onClick={() => this._handleMultiSelection('delete')}>Delete</BrandButton>
                                    </> : <>
                                        <BrandButton brand='outlined' onClick={() => this._handleMultiSelection('active')}>Active</BrandButton>
                                        <BrandButton brand='outlined' onClick={() => this._handleMultiSelection('delete')}>Delete</BrandButton>
                                    </>
                                }
                            </>
                        }

                        <BrandButton
                            brand='secondary'
                            onClick={this.downloadExcel.bind(this)}
                        >
                            Get Templates
                        </BrandButton>

                        <BrandButton
                            endIcon={<Icon className="fas fa-file-import" />}
                            onClick={this.triggerFile.bind(this)}
                            brand='secondary'
                        >
                            Import
                        </BrandButton>

                        <BrandButton
                            id="add-product-btn"
                            startIcon={<AddIcon />}
                            onClick={this._onAddClick.bind(this)}
                        >
                            Add New
                        </BrandButton>
                    </Box>
                ),
                shallRedirect: false,
                redirectTo: ''
            });
        }
    }

    componentWillUnmount = () => this.props.setSystemState({
        headerEndButton: undefined
    });

    downloadExcel = () => {
        const newData = sampleData.map(row => { return row })
        const workSheet = XLSX.utils.json_to_sheet(newData)
        const workBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workBook, workSheet, "sampleData") //Buffer
        let buf = XLSX.write(workBook, { bookType: "xls", type: "buffer" }) //Binary string
        XLSX.write(workBook, { bookType: "xls", type: "binary" })//Download
        XLSX.writeFile(workBook, "Template-Product.xls")
    }

    triggerFile = () => {
        this.fileRef.current?.click()
    }

    readUploadFile = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        if (e.target.files) {
            console.log('pasok')
            const reader = new FileReader();
            reader.onload = (e) => {
                const error: Array<string> = [];
                let existError: string = ''

                const data = e.target ? e.target.result : []
                const workbook = XLSX.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const spreadsheet = XLSX.utils.sheet_to_json(worksheet) as Array<object>;

                spreadsheet.every(sheet => {
                    const existing = find(this.props.product.products, (p) => stringValidator(p.name) === stringValidator(sheet['name']));
                    if (existing && existing.id !== sheet['id']) { existError = 'Product Name already exist' }
                    // common info validation
                    if (!sheet['name']) { error.push('Name') }
                    if (!sheet['brand']) { error.push('Brand') }
                    if (!sheet['material']) { error.push('Material') }
                    if (!sheet['type']) { error.push('Type') }
                    if (!sheet['description']) { error.push('Description') }
                    if (!sheet['h1']) { error.push('H1') }
                    if (existError.length > 0) return false
                    if (error.length > 0) return false
                })

                if (existError) return toastWarning(existError)
                if (error.length > 0) return toastWarning(`Missing Required field(s): ${error.join(', ')}`);
                this.setState({ openFileModal: true, spreadsheet: spreadsheet.filter(p => p['name'] !== "") })
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
        e.target.value = null
    }

    filePathset = (e) => {
        e.stopPropagation();
        e.preventDefault();
        var file = e.target.files[0];

        this.setState({ file });

        this.readFile(file)
        e.target.value = null
    }

    readFile(f: File) {
        const reader = new FileReader();
        reader.onload = (evt: any) => {
            // evt = on_file_select event
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            const error: Array<string> = [];
            let existError: string = ''
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const spreadsheet = this.convertToJson(XLSX.utils.sheet_to_json(ws));
            /* Update state */
            // this.setState({ openFileModal: true, spreadsheet: this.convertToJson(XLSX.utils.sheet_to_csv(ws)) })

            spreadsheet.every(sheet => {
                const existing = find(this.props.product.products, (p) => stringValidator(p.name) === stringValidator(sheet['name']));
                if (existing && existing.id !== sheet['id']) { existError = 'Product Name already exist' }
                // common info validation
                if (!sheet['name']) { error.push('Name') }
                if (!sheet['brand']) { error.push('Brand') }
                if (!sheet['material']) { error.push('Material') }
                if (!sheet['type']) { error.push('Type') }
                if (!sheet['description']) { error.push('Description') }
                if (!sheet['h1']) { error.push('H1') }
                if (existError.length > 0) return false
                if (error.length > 0) return false
            })

            if (existError) return toastWarning(existError)
            if (error.length > 0) return toastWarning(`Missing Required field(s): ${error.join(', ')}`);
            this.setState({ openFileModal: true, spreadsheet: spreadsheet.filter(p => p.name !== "") })
        };
        reader.readAsBinaryString(f);
    }

    convertToJson(csv) {
        let lines = csv.split("\n");
        let result: Array<any> = [];

        let headers = lines[0].split(",");

        for (var i = 1; i < lines.length; i++) {
            let obj: any = {};
            let currentline = lines[i].split(",");

            for (let j = 0; j < headers.length; j++) {
                obj[headers[j]] = currentline[j];
            }

            result.push(obj);
        }

        //return result; //JavaScript object
        return result; //JSON
    }

    onImportClick = async () => {
        const r = await this.props.importProducts(this.state.spreadsheet)
        this.setState({
            openFileModal: false,
            spreadsheet: []
        });
        // this.props.getProducts(this.props.product.activeFilters, 'list', '', this.props.product.activeTab === 'Active');
    }

    _onTabChange = (tab: 'Active' | 'Inactive') => {
        const { current } = this.searchRef;
        this.props.setMarketProductState({ activeTab: tab as string, uncheckAll: false, selectedMarketProduct: [] });
        this.props.getProducts(this.props.product.activeFilters, 'list', '', tab === 'Active');

        // reset search value when changing tabs
        if (current) {
            current.value = '';
        }
    }

    _onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        this._search(e.target.value)
    }

    _onAddClick = () => {
        this.props.setMarketProductState({
            activeProductId: 'new',
            activeProduct: EMPTY_PRODUCT,
            activeProductVariant: undefined,
            productVariants: []
        });

        this.props.setSystemState({
            shallRedirect: true,
            redirectTo: '/market/product/new'
        });
    }

    _onSortClick = (sort: string, order: SortOrder) => {
        let query: Partial<MarketProductFilters> = { ...this.props.product.activeFilters };
        const activeSort = !order ? '' : sort;
        this.props.setMarketProductState({ activeSort, activeSortOrder: order as string });
        if (activeSort && order) {
            this.props.getProducts({
                ...this.props.product.activeFilters,
                orderBy: sort,
                order: order as string
            },
                'list',
                this.props.product.productSearch,
                this.props.product.activeTab === 'Active'
            );
        }
        else {
            this.props.getProducts(
                query,
                'list',
                this.props.product.productSearch,
                this.props.product.activeTab === 'Active'
            );
        }
    }

    _triggerDialog = (title: string, content: string, action: () => void, overrideTitle?: string) => {
        this.props.setSystemState({
            systemDialogOpen: true,
            systemDialogMaxWidth: 'xs',
            systemDialogTitle: title,
            systemOverrideTitle: overrideTitle,
            systemDialogContent: content,
            systemDialogSimple: true,
            systemDialogConfirm: true,
            systemDialogConfirmAction: action
        })
    }

    _handleMultiSelection = (action: string) => {
        if (action === 'active' || action === 'inactive') {
            this._triggerDialog(
                'Confirm Save',
                'Please note that any changes are permanent. To continue, please click the save button.',
                () => {
                    this.props.updateMarketProductMultiple(action)
                    this.props.resetSystemDialog();
                },
            );
        } else {
            this._triggerDialog(
                'Confirm Delete',
                'Deleting these products are permanent. Please click confirm to continue',
                () => {
                    this.props.updateMarketProductMultiple(action)
                    this.props.resetSystemDialog();
                },
                'Confirm'
            );
        }

        this.props.setMarketProductState({ uncheckAll: false, selectedMarketProduct: [] });
    }

    render() {
        const { activeTab, products, productListLoading, activeSort, activeSortOrder } = this.props.product;
        return (
            <SymphonyLayout>
                <ProductsContainer>
                    <FileModal open={this.state.openFileModal}
                        data={this.state.spreadsheet}
                        loading={this.props.product.activeProductVariantLoading}
                        onImport={this.onImportClick}
                        onCancel={() => this.setState({ openFileModal: false, spreadsheet: [] })} />
                    <ProductsTabsContainer>
                        <SalesTabs
                            value={activeTab}
                            TabIndicatorProps={{ style: { height: 4, backgroundColor: SYMPHONY_PRIMARY_COLOR } }}
                        >
                            <SalesTab
                                id="product-active-tab"
                                label="Active"
                                value="Active"
                                onClick={this._onTabChange.bind(this, 'Active')}
                            />
                            <SalesTab
                                id="product-inactive-tab"
                                label="Inactive"
                                value="Inactive"
                                onClick={this._onTabChange.bind(this, 'Inactive')}
                            />
                        </SalesTabs>
                        <SalesField
                            id="product-search-fld"
                            style={{ marginBottom: 8 }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">
                                    <Search htmlColor={SYMPHONY_PRIMARY_COLOR} />
                                </InputAdornment>
                            }}
                            inputProps={{ ref: this.searchRef }}
                            onChange={this._onSearch.bind(this)}
                            placeholder="Search"
                        />
                    </ProductsTabsContainer>
                    <ProductsContentContainer>
                        {!productListLoading ?
                            <>
                                {products.length > 0 ?
                                    <ProductCardList
                                        products={products}
                                        activeSort={activeSort}
                                        activeSortOrder={activeSortOrder}
                                        onSortClick={this._onSortClick.bind(this)}
                                        loading={productListLoading}
                                    />
                                    :
                                    <ProductLoadingContainer>No Product Found</ProductLoadingContainer>
                                }
                            </>
                            :
                            <ProductLoadingContainer>
                                <CircularProgress style={{ color: SYMPHONY_PRIMARY_COLOR }} />
                            </ProductLoadingContainer>
                        }
                    </ProductsContentContainer>
                </ProductsContainer>
            </SymphonyLayout>
        )
    }
}

const mapStateToProps = (state: AppState) => ({
    product: state.marketproduct,
    login: state.login
});

export default connect(mapStateToProps, {
    setMarketProductState,
    setSystemState,
    resetSystemDialog,
    importProducts,
    updateMarketProductMultiple,
    getProducts
})(Product);