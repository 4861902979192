import React, { ReactNode } from 'react';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { Alert, AlertTitle } from '@material-ui/lab';

import Typography from '@material-ui/core/Typography';
import { styled } from '@material-ui/core';
import { appColors } from '../../lib/constants';
import { dataColors } from './util/chart-utils';
import RenderIf from '../../lib/RenderIf';

const ChartBox = styled('div')({
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0px 20px 50px #4C89F51C',
    padding: 24,
    boxSizing: 'border-box',
    maxWidth: '100%',
    minHeight: '80%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
});

interface ChartContainerProps {
    title: string;
    controls?: ReactNode;
    children: ReactNode;
    loading?: boolean;
    error?: string | null;
    noData?: boolean;
}

const ChartContainer = (props: ChartContainerProps) => {
    const { title, controls, loading, children, error, noData } = props;

    if (loading) {
        return (
            <ChartBox>
                <Backdrop
                    open={loading}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 1,
                        backgroundColor: 'transparent',
                    }}>
                    <CircularProgress style={{ color: appColors.primary }} />
                </Backdrop>
            </ChartBox>
        );
    }

    if (error) {
        return (
            <ChartBox>
                <Alert severity="error">
                    <AlertTitle
                        style={{ fontWeight: 600, marginBottom: '16px' }}>
                        Error
                    </AlertTitle>
                    <Typography style={{ marginBottom: '12px' }}>
                        The following error has occured when getting the&nbsp;
                        {title} report:
                    </Typography>
                    <Typography>{error}</Typography>
                </Alert>
            </ChartBox>
        );
    }

    return (
        <ChartBox>
            <Box>
                <Typography style={{ fontSize: 18, fontWeight: 600 }}>
                    {title}
                </Typography>
                {controls}
            </Box>
            <RenderIf condition={Boolean(noData)}>
                <Box
                    flex={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="center">
                    <Typography
                        style={{
                            fontWeight: 600,
                            fontSize: 20,
                            color: dataColors.textColor,
                        }}>
                        No Report Data
                    </Typography>
                </Box>
            </RenderIf>
            <RenderIf condition={!noData}>
                <Box mt="32px">{children}</Box>
            </RenderIf>
        </ChartBox>
    );
};

export default ChartContainer;
