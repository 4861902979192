import { Divider, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { match, RouteComponentProps, useHistory } from "react-router";
import BackButton from '../../../symphony/SymphonyBackButton';
import { setSystemState } from "../../../../store/system/actions";
import { HeaderContainer, HeaderText, ProductReviewContainer, ProductStarsContainer, ProductStats, ProductStatsContainer, ProductTopContainer, ReviewContainer, ReviewProductListContainer } from "./ReviewComponents";
import SymphonyImage from "../../../symphony/SymphonyImage";
import StarRating from "./StarRating";
import StarProgressBar from "./StarProgressBar";
import Sort from "./Sort";
import Reviews from "./Reviews";


interface MatchParams {
    params: { productId: string; state: { location: string; } };
}

interface IRouteParams extends RouteComponentProps {
    match: match & MatchParams
}
interface IReview {
    id: string;
    rating: number;
    orderNumber: string;
    orderDate: string;
    customerName: string;
    message: string;
    images: Array<{ path: string; }>
}

const Product = (props: IRouteParams) => {
    const { productId } = props.match.params;
    const dispatch = useDispatch();
    const [reviews, setReviews] = useState<Array<IReview>>([
        {
            id: '1',
            rating: 2.5,
            orderNumber: '0000OPSA82PH',
            orderDate: '09/12/2022',
            customerName: 'Antione',
            message: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
            It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
            and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
            images: [
                {
                    path: 'https://store.storeimages.cdn-apple.com/8756/as-images.apple.com/is/mbp-spacegray-select-202206?wid=904&hei=840&fmt=jpeg&qlt=90&.v=1653493200207'
                },
                {
                    path: 'https://store.storeimages.cdn-apple.com/8756/as-images.apple.com/is/mbp-spacegray-select-202206?wid=904&hei=840&fmt=jpeg&qlt=90&.v=1653493200207'
                },
                {
                    path: 'https://store.storeimages.cdn-apple.com/8756/as-images.apple.com/is/mbp-spacegray-select-202206?wid=904&hei=840&fmt=jpeg&qlt=90&.v=1653493200207'
                }
            ]
        },
        {
            id: '2',
            rating: 3.5,
            orderNumber: '0000OPSA82PH',
            orderDate: '09/13/2022',
            customerName: 'Stark Industries',
            message: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
            It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
            and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
            images: [
                {
                    path: 'https://store.storeimages.cdn-apple.com/8756/as-images.apple.com/is/mbp-spacegray-select-202206?wid=904&hei=840&fmt=jpeg&qlt=90&.v=1653493200207'
                },
                {
                    path: 'https://store.storeimages.cdn-apple.com/8756/as-images.apple.com/is/mbp-spacegray-select-202206?wid=904&hei=840&fmt=jpeg&qlt=90&.v=1653493200207'
                },
                {
                    path: 'https://store.storeimages.cdn-apple.com/8756/as-images.apple.com/is/mbp-spacegray-select-202206?wid=904&hei=840&fmt=jpeg&qlt=90&.v=1653493200207'
                }
            ]
        },
        {
            id: '3',
            rating: 4.5,
            orderNumber: '0000OPSA82PH',
            orderDate: '09/14/2022',
            customerName: 'Johnny Walker',
            message: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
            It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
            and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
            images: [
                {
                    path: 'https://store.storeimages.cdn-apple.com/8756/as-images.apple.com/is/mbp-spacegray-select-202206?wid=904&hei=840&fmt=jpeg&qlt=90&.v=1653493200207'
                },
                {
                    path: 'https://store.storeimages.cdn-apple.com/8756/as-images.apple.com/is/mbp-spacegray-select-202206?wid=904&hei=840&fmt=jpeg&qlt=90&.v=1653493200207'
                },
                {
                    path: 'https://store.storeimages.cdn-apple.com/8756/as-images.apple.com/is/mbp-spacegray-select-202206?wid=904&hei=840&fmt=jpeg&qlt=90&.v=1653493200207'
                }
            ]
        },
        {
            id: '4',
            rating: 4.5,
            orderNumber: '0000OPSA82PH',
            orderDate: '09/12/2022',
            customerName: 'John Doe',
            message: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
            It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
            and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
            images: [
                {
                    path: 'https://store.storeimages.cdn-apple.com/8756/as-images.apple.com/is/mbp-spacegray-select-202206?wid=904&hei=840&fmt=jpeg&qlt=90&.v=1653493200207'
                },
                {
                    path: 'https://store.storeimages.cdn-apple.com/8756/as-images.apple.com/is/mbp-spacegray-select-202206?wid=904&hei=840&fmt=jpeg&qlt=90&.v=1653493200207'
                },
                {
                    path: 'https://store.storeimages.cdn-apple.com/8756/as-images.apple.com/is/mbp-spacegray-select-202206?wid=904&hei=840&fmt=jpeg&qlt=90&.v=1653493200207'
                }
            ]
        },
        {
            id: '5',
            rating: 4.5,
            orderNumber: '0000OPSA82PH',
            orderDate: '09/15/2022',
            customerName: 'Elvis Doe',
            message: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
            It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
            and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
            images: [
                {
                    path: 'https://store.storeimages.cdn-apple.com/8756/as-images.apple.com/is/mbp-spacegray-select-202206?wid=904&hei=840&fmt=jpeg&qlt=90&.v=1653493200207'
                },
                {
                    path: 'https://store.storeimages.cdn-apple.com/8756/as-images.apple.com/is/mbp-spacegray-select-202206?wid=904&hei=840&fmt=jpeg&qlt=90&.v=1653493200207'
                },
                {
                    path: 'https://store.storeimages.cdn-apple.com/8756/as-images.apple.com/is/mbp-spacegray-select-202206?wid=904&hei=840&fmt=jpeg&qlt=90&.v=1653493200207'
                }
            ]
        },
        {
            id: '6',
            rating: 4.5,
            orderNumber: '0000OPSA82PH',
            orderDate: '09/16/2022',
            customerName: 'Opsolutions',
            message: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
            It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
            and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
            images: [
                {
                    path: 'https://store.storeimages.cdn-apple.com/8756/as-images.apple.com/is/mbp-spacegray-select-202206?wid=904&hei=840&fmt=jpeg&qlt=90&.v=1653493200207'
                },
                {
                    path: 'https://store.storeimages.cdn-apple.com/8756/as-images.apple.com/is/mbp-spacegray-select-202206?wid=904&hei=840&fmt=jpeg&qlt=90&.v=1653493200207'
                },
                {
                    path: 'https://store.storeimages.cdn-apple.com/8756/as-images.apple.com/is/mbp-spacegray-select-202206?wid=904&hei=840&fmt=jpeg&qlt=90&.v=1653493200207'
                }
            ]
        },
        {
            id: '7',
            rating: 4.5,
            orderNumber: '0000OPSA82PH',
            orderDate: '09/17/2022',
            customerName: 'John Doe',
            message: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
            It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
            and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
            images: [
                {
                    path: 'https://store.storeimages.cdn-apple.com/8756/as-images.apple.com/is/mbp-spacegray-select-202206?wid=904&hei=840&fmt=jpeg&qlt=90&.v=1653493200207'
                },
                {
                    path: 'https://store.storeimages.cdn-apple.com/8756/as-images.apple.com/is/mbp-spacegray-select-202206?wid=904&hei=840&fmt=jpeg&qlt=90&.v=1653493200207'
                },
                {
                    path: 'https://store.storeimages.cdn-apple.com/8756/as-images.apple.com/is/mbp-spacegray-select-202206?wid=904&hei=840&fmt=jpeg&qlt=90&.v=1653493200207'
                }
            ]
        },
    ])

    useEffect(() => {
        dispatch(setSystemState({
            header: <HeaderText display="flex">
                <BackButton to={window.location.pathname.split("/").filter(p => p !== window.location.pathname.split("/").pop()).join('/')} />
                MacBook Pro 2022
            </HeaderText>,
            headerEndButton: null,
            shallRedirect: false,
            redirectTo: ''
        }));

    }, [dispatch])

    return (
        <ReviewContainer>
            <ReviewProductListContainer>
                <ProductTopContainer>
                    <ProductStatsContainer>
                        <SymphonyImage
                            src='https://store.storeimages.cdn-apple.com/8756/as-images.apple.com/is/mbp-spacegray-select-202206?wid=904&hei=840&fmt=jpeg&qlt=90&.v=1653493200207'
                            style={{ width: 120, height: 'auto', maxHeight: 200, marginRight: '20px' }}
                        />
                        <ProductStats>
                            <Typography className="title">MacBook Pro 2022</Typography>
                            <Typography color='textSecondary' className="subtitle">CJ2248-645</Typography>
                            <div className="rating mt">
                                <Typography className="number">4.5</Typography>
                                <Typography className="out-of" color='textSecondary'>out of 5</Typography>
                            </div>
                            <div className="rating" style={{ alignItems: 'center', gap: 10 }}>
                                <StarRating size='small' rating={4.5} />
                                <Typography className="out-of" color='textSecondary'>Based on 23 ratings</Typography>
                            </div>
                        </ProductStats>
                    </ProductStatsContainer>
                    <ProductStarsContainer>
                        <StarProgressBar value={85} label='5 Stars' />
                        <StarProgressBar value={65} label='4 Stars' />
                        <StarProgressBar value={45} label='3 Stars' />
                        <StarProgressBar value={25} label='2 Stars' />
                        <StarProgressBar value={8} label='1 Stars' />
                    </ProductStarsContainer>
                </ProductTopContainer>

                <Divider style={{ margin: '1em 0px' }} />
                {/* Product Reviews */}
                <ProductReviewContainer>
                    <HeaderContainer>
                        <Typography className="title">Reviews</Typography>
                        <Sort data={reviews} module="Reviews" sorted={(e) => setReviews(e)}/>
                    </HeaderContainer>

                    <Reviews reviews={reviews}/>
                </ProductReviewContainer>
            </ReviewProductListContainer>
        </ReviewContainer>
    )
}

export default Product;