import axios from 'axios';
import { ReturnRefundItem } from './types';

const API_URL = process.env.REACT_APP_API_URL;
const endpoint = API_URL + '/order/getRefundReturnByVendorId';
const updateEndpoint = API_URL + '/order/returnRefund';

export const getReturnRefundData = async (
  vendorId: string,
  type: 'RETURN' | 'REFUND' = 'RETURN',
) => {
  const url = `${endpoint}/${vendorId}/${type}`;

  const response = await axios.get<
    Omit<ReturnRefundItem, 'solution' | 'requesDate'>[]
  >(url);

  // transform data
  const data: ReturnRefundItem[] = response.data.map((d) => ({
    ...d,
    solution: d.history[d.history.length - 1].solution,
    requestDate: d.dateCreated,
  }));

  return data;
};

export const updateReturnRefundStatus = async (
  orderId: string,
  status: string,
) => {
  const response = await axios.put(updateEndpoint, { orderId, status });
  return response.data;
};
