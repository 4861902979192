import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { AppModuleContainer } from '../../../lib/AppModuleContainer';
import { useClearHeader } from '../../../lib/helpers';
import BackButton from '../../../lib/BackButton';
import { appColors } from '../../../lib/constants';
import { useLocation, useParams } from 'react-router';
import OrderStatus from './OrderStatus';
import {
  useRefundOrdersQuery,
  useReturnOrdersQuery,
  useUpdateOrderStatus,
} from './hooks';
import OrderUpdates from './OrderUpdates';
import OrderInformation from './OrderInformation';
import { ToastContainer } from 'react-toastify';

function ReturnRefundDetailPage() {
  // I need this to overwrite the header implementation!
  useClearHeader();

  const { id } = useParams<{ id: string }>();

  const { search } = useLocation();

  const queryParams = new URLSearchParams(search);

  const returnOrders = useReturnOrdersQuery();
  const refundOrders = useRefundOrdersQuery();

  const orderUpdateMutation = useUpdateOrderStatus();

  const isReturn = queryParams.get('type') === 'return';

  const data = isReturn ? returnOrders.data : refundOrders.data;

  const item = data?.find((d) => d.id === id);

  const busy =
    returnOrders.isLoading ||
    refundOrders.isLoading ||
    orderUpdateMutation.isLoading;

  const handleUpdateStatus = async (newStatus: string) => {
    await orderUpdateMutation.mutateAsync(
      { orderId: id, status: newStatus },
      {
        onSuccess: async (data, variables, context) => {
          await returnOrders.refetch();
          await refundOrders.refetch();
        },
      },
    );
  };

  if (busy) {
    return (
      <AppModuleContainer>
        <Box
          bgcolor={appColors.bluishWhite}
          display="flex"
          alignItems="center"
          px={2}
          py={2}>
          <BackButton
            backTo={`/market/approval/return-and-refund?tab=${
              isReturn ? 'return' : 'refund'
            }`}
          />
          <Typography style={{ fontSize: 36, fontWeight: 'bold' }}>
            #{id}
          </Typography>
        </Box>
        <Box
          p={4}
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={400}>
          <CircularProgress />
        </Box>
      </AppModuleContainer>
    );
  }

  return (
    <AppModuleContainer>
      <Box
        bgcolor={appColors.bluishWhite}
        display="flex"
        alignItems="center"
        px={2}
        py={2}>
        <BackButton
          backTo={`/market/approval/return-and-refund?tab=${
            isReturn ? 'return' : 'refund'
          }`}
        />
        <Typography style={{ fontSize: 36, fontWeight: 'bold' }}>
          #{id}
        </Typography>
      </Box>
      <Box p={4} maxHeight="calc(100vh - 250px)" overflow="auto">
        {item ? (
          <>
            <OrderStatus
              historyData={item?.history}
              status={item?.status}
              isReturn={isReturn}
            />
            <OrderUpdates historyData={item?.history} isReturn={isReturn} />
            <OrderInformation
              returnRefundItem={item}
              onStatusUpdate={handleUpdateStatus}
            />
          </>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="50vh">
            <Typography
              style={{
                fontSize: 28,
                fontWeight: 'bold',
                color: appColors.mediumGray,
              }}>
              No Data
            </Typography>
          </Box>
        )}
      </Box>
      <ToastContainer toastStyle={{ borderRadius: 3, padding: '10px 15px' }} />
    </AppModuleContainer>
  );
}

export default ReturnRefundDetailPage;
