import axios, { AxiosError } from 'axios';
import moment from 'moment';
import { AppState, AppThunk } from '../../index';
import {
    TotalSalesAction,
    TotalSalesReportState,
    Status,
    LOAD_TOTAL_SALES_REPORT,
    SET_TOTAL_SALES_ERROR,
    SET_TOTAL_SALES_FILTER,
    SET_TOTAL_SALES_STATUS
} from './types';

const API_URL = process.env.REACT_APP_API_URL;

const manageTotalSalesInitState: TotalSalesReportState = {
    stateStatus: 'idle',
    error: null,
    data: {
        totalSales:0,
        difference:0,
        differencePercentage:0,
        monthlySales:[], 
    } ,
    filter: {
        startMonth: moment().format('MMM'),
        endMonth: moment().format('MMM'),
        year: moment().format('YYYY'),
    },
};

export function manageTotalSalesReportReducer(
    state = manageTotalSalesInitState,
    action: TotalSalesAction
): TotalSalesReportState {
    switch (action.type) {
    case SET_TOTAL_SALES_STATUS :
        return {
            ...state,
            stateStatus: action.payload
        };
    case LOAD_TOTAL_SALES_REPORT:
        return {
            ...state,
            data: action.payload,
            error: null,
            stateStatus: 'idle',
        };
    case SET_TOTAL_SALES_FILTER:
        return {
            ...state,
            filter: action.payload,
        };
    case SET_TOTAL_SALES_ERROR:
        return {
            ...state,
            error: action.payload,
            stateStatus: 'idle',
        };
    default:
            return state;
    }
}

//Sync Action
export const setTotalSalesStatus = (status: Status): TotalSalesAction => {
    return {
        type: SET_TOTAL_SALES_STATUS,
        payload: status
    };
};

export const setTotalSalesError = ( error: string | null): TotalSalesAction => {
    return {
        type: SET_TOTAL_SALES_ERROR,
        payload: error
    };
};

export const loadTotalSalesData = ( reportData: TotalSalesReportState['data']): TotalSalesAction => {
    return {
        type: LOAD_TOTAL_SALES_REPORT,
        payload: reportData
    };
};

// Async Actions


export const setTotalSalesFilter = (
    reportFilter: TotalSalesReportState['filter']
): TotalSalesAction => {
    return {
        type: SET_TOTAL_SALES_FILTER,
        payload: reportFilter
    };
};

export const getTotalSalesReport = (
    filter: TotalSalesReportState['filter'],
    callback?:() => void,
): AppThunk => 
async (dispatch, getState) => {
    try {
        dispatch(setTotalSalesStatus('loading'));
        const {login} = getState();
        
        const { id: vendorId, countryCode} =login.user;
        const startMonth = moment().month(filter.startMonth).format('MM');
        const endMonth = moment().month(filter.endMonth).format('MM');
        const year = filter.year;
    
        const response = await axios.get<TotalSalesReportState['data']>(`${API_URL}/v1/reports/market/total-sales?vendorId=${vendorId}&countryCode=${countryCode}&startMonth=${startMonth}&endMonth=${endMonth}&year=${year}`);

        dispatch(loadTotalSalesData(response.data));

        dispatch(setTotalSalesStatus('success'));

        if (callback) {
            callback();
        }
    } catch (error) {
        
        dispatch(setTotalSalesError('error'));

        let err = 'An unknown error has occured';

        if (error instanceof Error) {
            err = error.message;
        }

        if ((error as any)?.response?.data) {
            err = (error as AxiosError).response?.data?.error?.message;
        }

        dispatch(setTotalSalesError(err));
    }
};

// Selectors

export const selectTotalSalesFilter = (state: AppState) => state.totalSalesReport.filter;

export const selectTotalSalesTate = (state:AppState) => state.totalSalesReport;

export const selectTotalSalesReportData = (state:AppState) => state.totalSalesReport.data;

