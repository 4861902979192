import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { TableColumn } from '../../lib/table/table-types';
import { CustomerLead } from './types';
import { ReactComponent as DefaultAvatar } from '../../Basic/Common/BasicDefaultSvg.svg';

const LeadsAvatar = ({
  avatarUrl,
  alt,
}: {
  avatarUrl: string;
  alt: string;
}) => {
  return (
    <>
      {avatarUrl ? (
        <img
          src={avatarUrl}
          alt={alt}
          style={{
            width: 48,
            height: 'auto',
            maxHeight: 48,
            objectFit: 'cover',
            aspectRatio: '1/1',
          }}
        />
      ) : (
        <DefaultAvatar
          style={{ width: 32, height: 32, marginLeft: 4, marginRight: 12 }}
        />
      )}
    </>
  );
};

const LeadsDisplay = ({ leads }: { leads: CustomerLead }) => {
  return (
    <Box display="flex" alignItems="center" style={{ gap: 12 }}>
      <LeadsAvatar
        avatarUrl={leads.avatar?.path ?? ''}
        alt={leads.businessName}
      />
      <Typography style={{ fontSize: '13.5px' }}>
        {leads.businessName}
      </Typography>
    </Box>
  );
};

export const customerLeadsColumns: TableColumn<CustomerLead>[] = [
  {
    id: 'businessName',
    label: 'Business Name',
    property: 'businessName',
    sortable: true,
    content: (leads) => <LeadsDisplay leads={leads} />,
    align: 'left',
  },
  {
    id: 'contactPersonName',
    label: 'Contact Person Name',
    property: 'contactPersonName',
    sortable: true,
  },
  {
    id: 'email',
    label: 'Email',
    property: 'email',
    sortable: true,
  },
  {
    id: 'contactNumber',
    label: 'Contact Number',
    property: 'contactNumber',
    sortable: true,
  },
  {
    id: 'classification',
    label: 'Classification',
    property: 'classification',
    sortable: true,
  },
  {
    id: 'source',
    label: 'Source',
    property: 'source',
    sortable: true,
  },
];
