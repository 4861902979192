import React, { ReactNode } from 'react';

const RenderIf = ({
    children,
    condition,
}: {
    children: ReactNode;
    condition: boolean;
}) => {
    return <>{condition ? children : null}</>;
};

export default RenderIf;
