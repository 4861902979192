import React, { useState, useRef, useEffect } from 'react';
import { Box } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';

interface IRating {
    rating: number;
    size?: 'default' | 'small' | 'inherit' | 'large'
}

const Rating = ({ rating, size }: IRating) => {
    const precision = .5,
    totalStars = 5,
    emptyIcon = StarBorderIcon,
    filledIcon = StarIcon;

    const EmptyIcon = emptyIcon;
    const FilledIcon = filledIcon;

    return (
        <div
            style={{
                display: 'inline-flex',
                position: 'relative',
                cursor: 'pointer',
                textAlign: 'left',
            }}
        >
            {[...new Array(totalStars)].map((arr, index) => {
                const activeState = rating;

                const showEmptyIcon = activeState === -1 || activeState < index + 1;

                const isActiveRating = activeState !== 1;
                const isRatingWithPrecision = activeState % 1 !== 0;
                const isRatingEqualToIndex = Math.ceil(activeState) === index + 1;
                const showRatingWithPrecision =
                    isActiveRating && isRatingWithPrecision && isRatingEqualToIndex;

                return (
                    <Box
                        position={'relative'}
                        style={{
                            cursor: 'pointer'
                        }}
                        key={index}
                    >
                        <Box
                            style={{
                                width: showRatingWithPrecision ? `${(activeState % 1) * 100}%` : '0%',
                                overflow: 'hidden',
                                position: 'absolute'
                            }}
                        >
                            <FilledIcon style={{ color: '#f4e600' }} fontSize={size ? size : 'default'}/>
                        </Box>
                        {/*Note here */}
                        <Box
                            style={{
                                color: '#f4e600',
                                padding: 0
                            }}
                        >
                            {showEmptyIcon ? <EmptyIcon fontSize={size ? size : 'default'}/> : <FilledIcon style={{ color: '#f4e600' }} fontSize={size ? size : 'default'}/>}
                        </Box>
                    </Box>
                );
            })}
        </div>
    );
};

export default Rating;