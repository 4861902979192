import React from 'react';
import {
    ListBox,
    ListContainerBox,
    ScrollBox,
    InlineSearchBox,
    ListHeaderContainer,
    ListToolContainer,
    ListToolButton
} from '../../Common/BasicCommonComponents';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import map from 'lodash/map';
import { Promotion } from '../../../../store/promotion/types';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import PromotionCard from './PromotionCard';
import { PromoBannerListContainer, PromoBannerGridContainer, PromoBannerGrid } from '../../../market/promobanner/fragments/PromoBannerCommonComponents';

interface PromtionListProps {
    promotions: Array<Promotion>;
    loading: boolean;
    activePromotionId: string;
    promotionSearch: string;
    onPromotionClick: (id: string) => void;
    onAddPromotionClick: () => void;
    onPromotionSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onDeletePromotionClick: (promotionId: string) => void;
}

export default (props: PromtionListProps) => {
    return (
        <PromoBannerListContainer style={{ flex: 1, height: 'calc(100% - 10px)' }}>
            <PromoBannerGridContainer container style={{ paddingTop: 20 }}>
                {props.promotions.map((promotion) => (
                    <PromoBannerGrid item xs={4} key={promotion.id}
                        style={{ borderWidth: 1, borderColor: 'white', borderStyle: 'solid', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', height: 240, marginBottom: 20 }}
                        onClick={() => props.onPromotionClick(promotion.id)}>
                        <PromotionCard promotion={promotion} />
                    </PromoBannerGrid>
                ))}
            </PromoBannerGridContainer>
        </PromoBannerListContainer>
    )
}